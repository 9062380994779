import { CommonModule } from '@angular/common';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogModule, } from '@angular/material/legacy-dialog';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { environment } from 'src/environments/environment';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { PerfilesService } from '../perfiles.service';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Profile } from 'src/app/models/profiles.model';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { MachineNoGoverned, NoGoverned } from 'src/app/models/noGoverned.model';
import { NotifierService } from 'angular-notifier';

const FILTER_COLUMNS:{ field:string, label:string,type: FieldType}[] = [
  { field: 'vendingMachineAtento.serialNumber', label:'vendingMachine.serialNumber', type: 'string' },
  { field: 'vendingMachineAtento.manufacID', label:'vendingMachine.manufacID', type: 'string' },
  { field: 'vendingMachineAtento.modelNumber', label:'vendingMachine.modelNumber', type: 'string' }, 
]
@Component({
   standalone:true,
    imports:[CommonModule, 
      TranslateModule, 
      MatLegacyListModule, 
      MatIconModule, 
      MatPaginatorModule,
      MatLegacyDialogModule,
      FilterComponent,
      TableEmptyStateComponent
    ],
  selector: 'app-list-related-no-governed',
  templateUrl: './list-related-no-governed.component.html',
  styleUrls: ['./list-related-no-governed.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class ListRelatedNoGovernedComponent {
  machines:VendingMachineAtento[] = [];
  noGoverneds:NoGoverned[] = [];
  machineNoGoverneds: MachineNoGoverned[] = [];
  availableMachines:VendingMachineAtento[] = [];
  public deletedMachines: MachineNoGoverned[] = []; // Lista para almacenar objetos eliminados

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, column.label, column.type);
    return fielterField;
  });
  filterList: FilterableValue[] = [];
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data: { profile?: number; packageName?: string; version?: string },
    private dialogRef:MatLegacyDialogRef<ListRelatedNoGovernedComponent>,
    private dialog:MatDialog,
    private profileService:PerfilesService,
    public rol:RolHandlerService,
    private translate: TranslateService,
        private notifier: NotifierService,
    
  ){}

  getTranslatedStatus(vm: any): string {
    if (vm.dateInstall) {
      return this.translate.instant('noGoverned.installed'); 
    } else if (vm.dateCreate) {
      return this.translate.instant('noGoverned.send'); 
    } else {
      return this.translate.instant('noGoverned.pending'); 
    }
  }
 
  private async loadProfileMachines(){
    if(!this.data.profile) return;
    
    const filterAndPagBean = this.getFilterAndPagBean();
    const result = await this.profileService.getVendingMachines(this.data.profile, filterAndPagBean);
    if (result?.status === 0){
      this.machines = result.data;
      this.pageLength = this.machines.length;
    }



    // cosntruir objeto con 
    const filterAndPagBeanGoverned = this.getFilterAndPagBeanNoGoverneds(this.data.packageName!,this.data.version!);
    
    const resultGoverned = await this.profileService.getNoGoverned(this.data.profile, filterAndPagBeanGoverned);
    if (resultGoverned?.status === 0){
      this.noGoverneds = resultGoverned?.data;
    }

    this.machineNoGoverneds = this.machines.map((machine) => {
      const relatedNoGoverned = this.noGoverneds.find((noGoverned) => noGoverned.idVM === machine.id);
      
      return {
        idNoGoverned: relatedNoGoverned ? relatedNoGoverned.id : "",
        serialNumber: machine.serialNumber,
        manufacID: machine.manufacID,
        modelNumber: machine.modelNumber,
        dateCreate: relatedNoGoverned?.dateCreate || "",
        dateInstall: relatedNoGoverned?.dateInstall || "",
      } as MachineNoGoverned;
    });
    
    
    console.log(this.machineNoGoverneds);

    
  }

  async ngOnInit(): Promise<void> {
    await this.loadProfileMachines();
  }
  
  getFilterAndPagBean(): FilterAndPagBean {
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    return new FilterAndPagBean(null,null,this.filterList,startPage,endPage,pageIndex);
  }

  getFilterAndPagBeanNoGoverneds(packageName: string, version:string): FilterAndPagBean {
    const filterList = [
      new FilterableValue("packageName", packageName, 'string', 'EQUALS'),
      new FilterableValue("version", version, 'string', 'EQUALS')
    ];
    return new FilterAndPagBean(null,null,filterList,null,null,null);
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.loadProfileMachines();
  }

  public delete(item:MachineNoGoverned){
    const itemIndex = this.machineNoGoverneds.findIndex((machine)=>{ return JSON.stringify(machine) === JSON.stringify(item) });
    if(itemIndex >= 0) {
      const deletedItem = this.machineNoGoverneds[itemIndex];
      this.deletedMachines.push(deletedItem);
      this.machineNoGoverneds.splice(itemIndex, 1);
    }
    this.pageLength = this.machineNoGoverneds.length;
  }

  public pageEvent(){
    this.loadProfileMachines();
  }

  public onClose(){
    this.dialogRef.close();
  }

  public submitChanges(){
    const dialogData = new ConfirmDialogData;
    dialogData.enableCancel = false;
    dialogData.element = Element.profile; 
    dialogData.action = ElementAction.udpate_masculino;
    dialogData.class = DialogClass.info;  
    dialogData.icon = 'save-01';

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
      width: '35%', panelClass: 'custom-modalbox',
      data:dialogData
    });

    confirmSave.afterClosed().subscribe((response:ConfirmDialogActions) => {
      if (response === ConfirmDialogActions.CONFIRM) this.deletedMachinesNoGoverneds();
    });
  }

  public async deletedMachinesNoGoverneds() {
    // Validar las máquinas
    const invalidMachines = this.deletedMachines.filter(machine => 
      !machine.idNoGoverned
    );
  
    if (invalidMachines.length > 0) {
      return; 
    }

    try {
      const responses = this.deletedMachines.map(async (machine) => {
        const response = await this.profileService.deleteNoGoverned(machine.idNoGoverned!);
  
        if (response?.status! < 0) {
          throw new Error();
        }
        return response?.status;
      });
        await Promise.all(responses);
        this.dialogRef.close({ result: 1 });
    } catch (error) {
      this.notifier.notify('warning', this.translate.instant('warning.errorGeneric'));
    }

  }
  
}
