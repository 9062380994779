import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { Aplications } from 'src/app/models/aplications.model';
import { AppsService } from '../aplications.service';
import { BooleanInput } from '@angular/cdk/coercion';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { FormCreateActions } from 'src/app/util/constants';
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER, TYPE_FILTER } from 'src/app/models/FilterAndPagBean';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { downloadFile } from 'src/app/util/util';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { Tag } from '../../tags/tag.model';
import { Factories } from 'src/app/models/factories.model';
import { EditVersionComponent } from '../edit-version/edit-version.component';
import { DatesHandlerService } from 'src/app/services/dates-handler.service';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';

@Component({
  selector: 'app-edit-app',
  templateUrl: './edit-app.component.html',
  styleUrls: ['./edit-app.component.css']
})
export class EditAppComponent implements OnInit {
  firstFormGroup!: FormGroup;
  isLinear: BooleanInput;
  versions: Aplications[] = [];
  filterList: FilterableValue[] = [];
  isAddingVersion = false;
  app: Aplications = new Aplications;

  isOpen: { [key: string]: boolean } = {
    details: true,
    hierarchy:true,
    dates: true,
    versiones: true,
  };
  confirmDialogRef: any;
  readOnly: boolean = false;
  factories?: Factories;
  //Flag to detect when object has changed
  isAppEdited:boolean = false;

  constructor(
    private readonly  formBuilder: FormBuilder,
    private readonly  notifier: NotifierService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { app: Aplications, readOnly: boolean },
    private readonly  appService: AppsService,
    public rol: RolHandlerService,
    public hierarchy:HierarchyHandlerService,
    private readonly  translate: TranslateService,
    private datesHandler:DatesHandlerService,
  ) {
    // Intercept cases of modal closure, and send if the profile has changed.
    this.dialogRef.beforeClosed().subscribe((value)=>{
      if(!value) {
        const dialogResponse = this.isAppEdited ? ConfirmDialogActions.CONFIRM : ConfirmDialogActions.CANCEL;
        this.dialogRef.close(dialogResponse); 
      } 
    });
  }

  ngOnInit(): void {
    this.readOnly = !!this.data.readOnly;
    // Establece un valor inicial a 'app' para evitar errores
    this.app = this.data?.app || new Aplications();
    // Primer formulario para los datos de la aplicación
    this.firstFormGroup = this.formBuilder.group({
      name: [{ value: '', disabled: this.readOnly }, [Validators.required,Validators.maxLength(45)]],
      packageName: [{ value: '', disabled: true }, Validators.required],
      dateCreate: [{ value: '', disabled: true }],
      dateUpdate: [{ value: '', disabled: true }],
      isNotIntegrated: [this.app.isIntegrada],
      isNotGoverned: [this.app.isGobernada] ,
      tags: [{ value: [], disabled: this.readOnly }]
    });
    if(this.app.isGobernada){
      this.firstFormGroup.get('isNotIntegrated')?.disable();
    }

    this.firstFormGroup.get('isNotGoverned')?.valueChanges.subscribe(value => {
      if (value) {
        this.firstFormGroup.get('isNotIntegrated')?.setValue(true);
        this.firstFormGroup.get('isNotIntegrated')?.disable();
      } else {
        this.firstFormGroup.get('isNotIntegrated')?.enable();
      }
    });
    if (!this.data) return;
    this.lanzarLlamada();
  }

  lanzarLlamada(){
    // Verifica que los datos estén disponibles
    const hasPackage = !!this.data.app.pakage;
    if (hasPackage) {
    this.loadFullVersionsData();
    } else {
    this.loadVersionData();
    }
  }

  async loadFullVersionsData() {
    const { pakage, id } = this.data.app;
    const packageFilter = new FilterableValue("pakage", pakage, TYPE_FILTER.String, OPERATION_FILTER.EQUALS);
    const request = new FilterAndPagBean("ASC","version", [packageFilter], 0, 0, 0);
    
    // Llama al servicio para obtener los datos del backend
    const response = await this.appService.getVersions(request, id)
    if (response && response.data?.length) {
      const dataAppVersion: Aplications[] = response.data;
      const lastApp = dataAppVersion.find((app) => app.isLast);
      this.loadFormData(lastApp ? lastApp : dataAppVersion[0]);
      this.versions = [...dataAppVersion];
    }
  }

  async loadVersionData() {
    const { id } = this.data.app;
    const response = await this.appService.get(id);
    if (response && response.data) {
      const loadedApp = response.data;
      this.loadFormData(loadedApp);
      this.versions = [...[loadedApp]];
    }
  }
  
  // Método para cargar los datos en el formulario
  loadFormData(data: Aplications): void {
    this.app = data; // Actualiza 'app' con los datos cargados
    this.firstFormGroup.patchValue({
      name: data.name,
      packageName: data.pakage,
      dateCreate: this.datesHandler.formatDateFromUTCDate(data.dateCreate?.toString(), true),
      dateUpdate: this.datesHandler.formatDateFromUTCDate(data.dateUpdate?.toString(), true),
      tags: data.listEtiquetas ?? [],
    });

    this.firstFormGroup.markAsPristine();
  }

  onSave(): void {
    // Valido que el primer formulario sea válido, 
    if (!this.firstFormGroup.valid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return
    }

    // Crea el objeto FirmwareUpload para enviar al backend
    let objNew: FirmwareUpload = new FirmwareUpload();
    objNew.id = this.app.id;

    // Extraemos los valores de ambos formularios
    const { name, tags } = this.firstFormGroup.value;

    // Creamos el objeto Aplications combinando los datos de ambos formularios
    const updateApp: Aplications = new Aplications();
    updateApp.name = name;
    updateApp.description = this.app.description;
    updateApp.dateCreate = this.app.dateCreate;
    updateApp.pakage = this.app.pakage.trim();
    updateApp.version = this.app.version;
    updateApp.fileName = this.app.fileName;
    updateApp.listEtiquetas = (tags.length) ? tags.map((tag: Tag) => ({ id: tag.id })) : [];
    updateApp.isGobernada = this.firstFormGroup.get('isNotGoverned')?.value;
    updateApp.isIntegrada =  this.firstFormGroup.get('isNotIntegrated')?.value;

    updateApp.manufacID = this.app.manufacID;
    updateApp.modelNumber = this.app.modelNumber;
    
    // Asegúrate de que la lista de aplicaciones contenga la versión correcta y otros datos
    let udpateListApps: Aplications[] = [updateApp];
    objNew.fileList = udpateListApps;
    // valores originales para Operador y Cliente
    objNew.idOperator = this.app.idOperator;
    objNew.idClient = this.app.idClient;
    objNew.idSubClient = this.app.idSubClient;


    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.application; 
    dialogData.action = ElementAction.udpate_femenino;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        // Llama a la función para guardar en el backend
        this.saveToBackend(objNew);
      }
    });
  }

  async saveToBackend(objNew: FirmwareUpload): Promise<void> {
    const response = await this.appService.addUpdate(objNew)
    if(response && response.status === 0){
      this.notifier.notify('success', this.translate.instant('success.appSaved'));
      this.dialogRef.close(FormCreateActions.SAVED);
    }
  }

  onDelete(): void {
    const { id } =  this.app;
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.application; 
    dialogData.action = ElementAction.delete_femenino;
    dialogData.class = DialogClass.error;
    dialogData.icon = 'trash-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.appService.delete(id);
        if (response?.status === 0) {
          this.notifier.notify('success', this.translate.instant('success.appDeleted'));
          this.dialogRef.close(FormCreateActions.SAVED)
        }
      }
    });
  }


  removeVersion(id: number): void {
    if (this.versions.length <= 1) {
      this.notifier.hideNewest();
      this.notifier.notify('warning', this.translate.instant('warning.singleVersionRequired'));
      return;
    }

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.version; 
    dialogData.action = ElementAction.delete_femenino;
    dialogData.class = DialogClass.error;
    dialogData.icon = 'trash-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.appService.deleteVersion(id);
        if (response?.status === 0) { // Verificar el estado de respuesta
          this.lanzarLlamada();
          this.isAppEdited = true;
          this.notifier.notify('success', this.translate.instant('success.versionDeleted'));
        }
      }
    });
  }

  addVersion(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.editVersion();
  }

  editVersion(versionId?:number){
    const editVersionModal = this.dialog.open(EditVersionComponent, {
      width: '600px',
      panelClass: 'custom-modalbox',
      data: { application:this.app, versionId: versionId}
    });

    editVersionModal.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM){
        this.isAppEdited = true; 
        this.lanzarLlamada();
      }
    });
  }
  
  onCancel(): void {
    this.dialogRef.close();
  }
  
  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }

  async downloadVersion(version: Aplications): Promise<void> {
    const fileResponse = await this.appService.getFile(version.id);
    if (fileResponse) {
      downloadFile(version.fileName, fileResponse);
    } else {
      this.notifier.notify('error', this.translate.instant('error.errorOnDownload'));
    }
  }
  isDisabled(controlName: string): boolean {
    return this.firstFormGroup.get(controlName)?.disabled ?? false;
  }
}
