import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../users.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Rol } from 'src/app/models/rol.model';
import { Users } from 'src/app/models/users.model';
import { FormCreateActions, LEVEL_HIERARCHY, RegexpValidators } from 'src/app/util/constants';
import { Operator } from 'src/app/models/operator.model';
import { AuthService } from 'src/app/auth/auth.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Client, SubClient } from 'src/app/models/client.model';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { ClientService } from '../../client/client.service';
import { SubClientService } from '../../sub-client/sub-client.service';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { MatTableDataSource } from '@angular/material/table';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { FilterableSelectComponent } from 'src/app/components/filterableSelect/filterableSelect.component';
import { OperatorService } from '../../operator/operator.service';
import { RolService } from '../../rol/rol.service';

@Component({
  selector: 'app-edit-form-users',
  templateUrl: './form-edit-users.component.html',
  styleUrls: ['./form-edit-users.component.css']
})
export class FormEditUsersComponent implements OnInit {
  userForm!: FormGroup;

  selectedRol: Rol = new Rol();
  listaRol: Rol[] = [];
  selectedOpe: Operator = new Operator();
  listaOpe: Operator[] = [];
  clientsList: Client[] = [];
  subClientsList: SubClient[] = [];
  hierarchyOptions: { value: LEVEL_HIERARCHY, label: string }[] = [
    { value: LEVEL_HIERARCHY.LEVEL_1, label: this.translate.instant('hierarchy.level_1') },
    { value: LEVEL_HIERARCHY.LEVEL_2, label: this.translate.instant('hierarchy.level_2') },
    { value: LEVEL_HIERARCHY.LEVEL_3, label: this.translate.instant('hierarchy.level_3') },
  ]
  
  readOnly: boolean = false;
  dataSource = new MatTableDataSource<Users>();
  isEdit: boolean = false;
  user: any;
  idUser: number | undefined = undefined;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  @ViewChild('rolSelect') rolSelect?:FilterableSelectComponent;
  @ViewChild('hierarchyLevelSelect') hierarchyLevelSelect?:FilterableSelectComponent;
  @ViewChild('operatorSelect') operatorSelect?:FilterableSelectComponent;
  @ViewChild('clientSelect') clientSelect?:FilterableSelectComponent;
  @ViewChild('subClientSelect') subClientSelect?:FilterableSelectComponent;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private services: UsersService,
    private authService: AuthService,
    private operatorService: OperatorService,
    private clientService: ClientService,
    private subClientService: SubClientService,
    private roleService:RolService,
    private userService: UsersService,
    private translate: TranslateService,
    public hierarchy: HierarchyHandlerService,
    public rol: RolHandlerService,
    public dialogRef: MatDialogRef<FormEditUsersComponent>,
    public dialog: MatDialog) {
    this.hierarchyOptions = this.hierarchyOptions.filter(opt => (this.hierarchy.configureUsersByLevel(opt.value)));
  }

  private async loadOperators(){
    // Operadores
    const request = new FilterAndPagBean(null,null,[],null,null, null);
    const response = await this.operatorService.find(request)
    if(response && response.data) this.listaOpe = response.data;
  }


  private async loadClients(idOperator: (number | null) = null): Promise<void> {
    if(!idOperator){
      this.clientsList = [];
      return;
    }
    let filters = [];
    if (idOperator) filters.push(new FilterableValue("idOperator", idOperator, "long", "EQUALS"))
    const request = new FilterAndPagBean(null, null, filters, null, null, null);
    const response = await this.clientService.getListed(request);
    if (response && response.data) this.clientsList = response.data;
  }


  private async loadSubClients(idClient: (number | null) = null): Promise<void> {
    if(!idClient){
      this.subClientsList = [];
      return;
    }
    let filters = [];
    if (idClient) filters.push(new FilterableValue("idClient", idClient, "long", "EQUALS"))
    const request = new FilterAndPagBean(null, null, filters, null, null, null);
    const response = await this.subClientService.getListed(request);
    if (response && response.data) this.subClientsList = response.data;
  }

  private async loadRoles(idOperator?:number){
    if(!idOperator){
      this.listaRol = [];
      return;
    }
    // Roles    
    let filters = [new FilterableValue("idOperator", idOperator, "long", "EQUALS")];
    const request = new FilterAndPagBean(null,null,filters,null,null, null);
    const response = await this.roleService.find(request)
    if(response && response.data) this.listaRol = response.data;
  }

  async ngOnInit(): Promise<void> {
    this.readOnly = !!this.data.readOnly; // Control de edición
    this.userForm = this.formBuilder.group({
      name: ["", Validators.compose([
        Validators.required,
        Validators.maxLength(45),
        Validators.pattern(RegexpValidators.text)
      ])],
      email: ["", Validators.compose([
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(RegexpValidators.email)
      ])],
      username: [{value:"", disabled:true}, Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(255),
        Validators.pattern(RegexpValidators.username)
      ])],
     // identifier: [""],
      idRol: [null, Validators.required],
      hierarchyLevel: [null, Validators.required],
      idOperator: [null,Validators.required],
      idClient: [null,Validators.required],
      idSubClient: [null,Validators.required],
    });

    this.userForm.get('hierarchyLevel')?.valueChanges.subscribe((value: number) => {
      if (value) this.updateSelectsState(value as LEVEL_HIERARCHY);
    });

    this.userForm.get('idOperator')?.valueChanges.subscribe((value: number) => {
      this.userForm.patchValue({ idClient: null });
      this.userForm.patchValue({ idRol: null });
      this.loadClients(value);
      this.loadRoles(value);
    });

    this.userForm.get('idClient')?.valueChanges.subscribe((value: number) => {
      this.userForm.patchValue({ idSubClient: null });
      this.loadSubClients(value);
    });

    // Deshabilitar formulario si es solo lectura
    if (this.readOnly) {
      this.userForm.disable();
    }

    // Ejemplo: activar modo solo lectura si el usuario está eliminado
    if (this.user?.dateDelete) {
      this.readOnly = true;
      this.userForm.disable(); // Deshabilita todos los controles del formulario
    }
    if(this.rol.isAdmin()) await this.loadOperators();
    await this.getUser();
  }


  private async getUser(): Promise<void> {
    if(!this.data.obj) return;
    const { id } = this.data.obj; 
    const response = await this.services.getItem(id);
    if(response?.data){
      this.user = response.data;
      this.updateUserForm(response.data);
    }
  }
  
  private updateUserForm(user: Users) {
    if (!user) return;

    this.isEdit = true;
    this.idUser = user.id ?? undefined;

    const nombre = user.name,
      nick = user.nickName,
      email = user.email,
     // identifier = user.identification,
      rol = user.rol?.id ?? null,
      operator = user.operator?.id ?? null,
      client = user.client?.id ?? null,
      subClient = user.subClient?.id ?? null;


    this.userForm.patchValue({
      name: nombre,
      username: nick,
      email: email,
     // identifier: identifier,
    });

    this.userForm.get('hierarchyLevel')?.setValue(this.hierarchy.getLevel(user));
    this.userForm.get('idOperator')?.setValue(operator);
    this.userForm.get('idClient')?.setValue(client);
    this.userForm.get('idSubClient')?.setValue(subClient);
    this.userForm.get('idRol')?.setValue(rol);
  }

  private updateInputValidations(){
    this.rolSelect?.updateValidity();
    this.hierarchyLevelSelect?.updateValidity();
    this.operatorSelect?.updateValidity();
    this.clientSelect?.updateValidity();
    this.subClientSelect?.updateValidity();
  }

  onFormSubmit(): void {
    if (this.userForm.invalid) {
      this.updateInputValidations();
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
    const data = { ...this.userForm.value };
    const { id, pass, nickName } = this.user;
    let userNew: Users = new Users();

    userNew.id = id;
    userNew.pass = pass;
    userNew.nickName = nickName;
    
    userNew.name = data['name']
    userNew.email = data['email']
    userNew.identification = data['identifier']

    if (!this.validateHierarchySelection(data)) {
      this.notifier.notify('warning', this.translate.instant('messages.hierarchyError'));
      return;
    }

    if (data['idRol']) userNew.rol = { id: data['idRol'] } as Rol;
    if (data['idOperator']) userNew.operator = { id: data['idOperator'] } as Operator;
    if (data['idClient']) userNew.client = { id: data['idClient'] } as Client;
    if (data['idSubClient']) userNew.subClient = { id: data['idSubClient'] } as SubClient;


    let userSession = this.authService.currentUserValue();
    userNew.entorno = userSession.entorno;

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.user; 
    dialogData.action = ElementAction.udpate_masculino;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';
    
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {

        console.log('Form SAVE action');
        let val = await this.services.addUpdate(userNew)

        if (val!.status >= 0) {
          if (userNew.id) {
            this.notifier.notify('success', this.translate.instant('success.userUpdate'))
          } else {
            this.notifier.notify('success', this.translate.instant('success.userCreated'));
          }
          this.dialogRef.close(FormCreateActions.SAVED);
        }

      }
    });

  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }


  /**
   * Valido requerimientos de inputs según Rol elegido 
   */
  private validateHierarchySelection(form: any): boolean {
    const { hierarchyLevel, idOperator, idClient, idSubClient } = form;
    if (Number(hierarchyLevel) === LEVEL_HIERARCHY.LEVEL_1 && !idOperator) return false;
    if (Number(hierarchyLevel) === LEVEL_HIERARCHY.LEVEL_2 && (!idOperator || !idClient)) return false;
    if (Number(hierarchyLevel) === LEVEL_HIERARCHY.LEVEL_3 && (!idOperator || !idClient || !idSubClient)) return false;
    return true;
  }

  /**
   * Actualizo el estado de los selectores según el rol seleccionado 
   */
  private updateSelectsState(hierarchyId: LEVEL_HIERARCHY) {
    switch (Number(hierarchyId)) {
      case (LEVEL_HIERARCHY.LEVEL_1):
        this.userForm.patchValue({idClient:null, idSubClient:null});
        this.userForm.get('idOperator')?.enable({emitEvent:false});
        this.userForm.get('idClient')?.disable({emitEvent:false});
        this.userForm.get('idSubClient')?.disable({emitEvent:false});
        break;
      case (LEVEL_HIERARCHY.LEVEL_2):
        this.userForm.patchValue({idSubClient:null});
        this.userForm.get('idOperator')?.enable({emitEvent:false});
        this.userForm.get('idClient')?.enable({emitEvent:false});
        this.userForm.get('idSubClient')?.disable({emitEvent:false});
        break;
      case (LEVEL_HIERARCHY.LEVEL_3):
        this.userForm.get('idOperator')?.enable({emitEvent:false});
        this.userForm.get('idClient')?.enable({emitEvent:false});
        this.userForm.get('idSubClient')?.enable({emitEvent:false});
        break
      default:
        this.userForm.get('idOperator')?.disable({emitEvent:false});
        this.userForm.get('idClient')?.disable({emitEvent:false});
        this.userForm.get('idSubClient')?.disable({emitEvent:false});
        break;
    }
  }

  
  onResetPassword() {
    // this.data.obj//user
    if (this.data.obj.dateDelete) return;

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.user; 
    dialogData.action = ElementAction.resetPassword;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'refresh-ccw-03';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        let sr = await this.userService.resetUserPass(this.data.obj);
        if (sr!.status >= 0) this.notifier.notify('success', this.translate.instant('notification.resetSuccess')); // "Reset successfully"
        this.dialogRef.close(FormCreateActions.SAVED)
      }
    });
  }


  private async delete(id: number) {
    const result = await this.userService.deleteUser(id);
    if (result && result.status === 0) {
      this.notifier.notify('success', this.translate.instant('success.userDeleted'));
      this.dialogRef.close(FormCreateActions.SAVED)

    }
  }

  
  private async block(id: number) {
    const result = await this.userService.blockUser(id);
    if (result && result.status === 0) {
      this.notifier.notify('success', this.translate.instant('success.userblocked'));
      this.dialogRef.close(FormCreateActions.SAVED)

    }
  }

    
  private async unblock(id: number) {
    const result = await this.userService.unblockUser(id);
    if (result && result.status === 0) {
      this.notifier.notify('success', this.translate.instant('success.userUnblocked'));
      this.dialogRef.close(FormCreateActions.SAVED)

    }
  }

  onDelete() {
    if (this.idUser) {
      const dialogData = new ConfirmDialogData();
      dialogData.element = Element.user; 
      dialogData.action = ElementAction.delete_masculino;
      dialogData.class = DialogClass.error;
      dialogData.icon = 'trash-01';

      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '25%', panelClass: 'custom-modalbox',
        data: dialogData
      });

      confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
        if (result === ConfirmDialogActions.CONFIRM) {
          await this.delete(this.idUser as number);
        }
      });
    }
  }

  onBlock() {
    if (this.idUser) {
      const dialogData = new ConfirmDialogData();
      dialogData.element = Element.user; 
      dialogData.action = ElementAction.block;
      dialogData.class = DialogClass.error;
      dialogData.icon = 'slash-circle-01';

      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '25%', panelClass: 'custom-modalbox',
        data: dialogData
      });

      confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
        if (result === ConfirmDialogActions.CONFIRM) {
          await this.block(this.idUser as number);
        }
      });
    }
  }

  onUnblock() {
    if (this.idUser) {
      const dialogData = new ConfirmDialogData();
      dialogData.element = Element.user; 
      dialogData.action = ElementAction.unblock;
      dialogData.class = DialogClass.info;
      dialogData.icon = 'slash-circle-01';

      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '25%', panelClass: 'custom-modalbox',
        data: dialogData
      });

      confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
        if (result === ConfirmDialogActions.CONFIRM) {
          await this.unblock(this.idUser as number);
        }
      });
    }
  }
}




