import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions } from 'src/app/util/constants';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { Operator } from 'src/app/models/operator.model';
import { OperatorService } from 'src/app/pages/admin/operator/operator.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Users } from 'src/app/models/users.model';

@Component({
  selector: 'app-form-edit-txt-jer',
  templateUrl: './form-edit-txt-jer.component.html',
  styleUrls: ['./form-edit-txt-jer.component.css']
})
export class FormEditTxtJerComponent {

  registerForm!: FormGroup;
  listCommerce: string[] = [];
  readOnly: boolean = false;
  userSession?: Users;

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { level: number, atributo : String, es : String, en: String, readOnly: boolean },
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: OperatorService,
    public dialogRef: MatDialogRef<FormEditTxtJerComponent>,
    public dialog: MatDialog,
    private translate: TranslateService,
    public operator:RolHandlerService,
    private utils: UtilService,
    private authService:AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    // Obtener el rol del usuario actual
    this.readOnly = !!this.data.readOnly;
    this.registerForm = this.formBuilder.group({
      atributo: [null, Validators.required],
      level:[],
      traductor: [],
      ES: [],
      EN: []
    });
    if (this.data) this.cargar()
  }

  async cargar(): Promise<void> {
 
      this.registerForm.patchValue({
        atributo: this.data.atributo,
        level: this.data.level,
        ES: this.data.es,
        EN: this.data.en,
      });
    
   }
  

  onFormSubmit(): void {
    if (this.registerForm.invalid) return;


    const data = this.applyFormats(this.registerForm);

    // Inicializamos el objeto traductor vacío
    let traductorObj: any = {};

    // Verificamos si las claves ES y EN existen en data y las agregamos al objeto traductorObj
    if (data['ES']) {
      traductorObj['ES'] = data['ES'];
    }
    if (data['EN']) {
      traductorObj['EN'] = data['EN'];
    }
    //let objNew: This.usersesion;
    this.userSession = this.authService.currentUserValue();
    let operator = this.userSession.operator;

    if(operator != null && data['level']==2){
      operator.labelLevel2 = JSON.stringify(traductorObj);
    }else if(operator != null && data['level']==3){
      operator.labelLevel3 = JSON.stringify(traductorObj);
    }
    
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.jerarquia; 
    dialogData.action = ElementAction.udpate_masculino;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM && operator != null) this.saveOperator(operator)
    });
  }

  async saveOperator(objNew: Operator) {
    const val = await this.service.addUpdate(objNew);
    if (val!.status >= 0) {
      this.notifier.notify('success', this.translate.instant('success.operatorSaved'));
      this.utils.getRolsAndOperators();
      this.dialogRef.close(FormCreateActions.SAVED);
    }
  }

  formExit(): void {
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body: any = {};
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}
