import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { NotifierService } from 'angular-notifier';
import { Rol } from 'src/app/models/rol.model';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { RolService } from '../rol.service';
import { FUNCTION_IDS } from 'src/app/util/constants';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { Operator } from 'src/app/models/operator.model';
import { PERMISSIONS_DEFINITIONS, PERMISSIONS_RELATED } from '../permissions';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-form-new-rol',
  templateUrl: './form-new-rol.component.html',
  styleUrls: ['./form-new-rol.component.css']
})
export class FormNewRolComponent implements OnInit {
  registerFormStep1!: FormGroup;
  registerFormStep2!: FormGroup;
  @ViewChild(MatStepper) stepper!: MatStepper;

  listaRol: Rol[] = [];
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  rolForm!: FormGroup;
  rol: Rol | null = null;
  currentStep: number = 0; // Controlador de paso actual

  operatorsList:Operator[] = [];

  permissions = PERMISSIONS_DEFINITIONS;

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: RolService,
    private utilServicios: UtilService,
    public dialogRef: MatDialogRef<FormNewRolComponent>,
    public dialog: MatDialog,
    private translate: TranslateService,
    public handleRol:RolHandlerService
  ) { }

  async ngOnInit(): Promise<void> {
    // Paso 1: Crear un formulario con los campos necesarios
    this.registerFormStep1 = this.formBuilder.group({
      nombre: ['', Validators.compose([Validators.required,Validators.maxLength(45)])],
      description: ['', Validators.compose([Validators.required,Validators.maxLength(200)])],
      idOperator:[this.utilServicios.getOperatorId() ?? '']
    });

    // Paso 2: Crear un formulario para los permisos
    this.registerFormStep2 = this.createPermissionsFormGroup();

    // Escuchar los cambios en los permisos
    this.listenToChanges();
    if (this.handleRol.isAdmin()) this.loadOperators();
    
    await this.cargar();
  }

  private async loadOperators(){
    this.utilServicios.findOperators().subscribe((response)=> this.operatorsList = response);
  }

  async cargar(): Promise<void> {
    try {
      this.utilServicios.findRol().subscribe((listaRolTemp: Rol[]) => {
        this.listaRol = listaRolTemp;
      });
    } catch (error) {
      this.notifier.notify('error', this.translate.instant('error.general'));
    }
  }

  createPermissionsFormGroup(): FormGroup {
    const group: { [key: string]: FormGroup | FormControl } = {};
  
    this.permissions.forEach(category => {
      const categoryGroup: { [key: string]: FormControl } = {};
  
      category.items.forEach(permission => {
        categoryGroup[permission.name] = new FormControl(false);
      });
  
      categoryGroup['selectAll'] = new FormControl(false);
  
      group[category.category] = this.formBuilder.group(categoryGroup);
    });
  
    // Agregar checkbox global
    group['selectAllGlobal'] = new FormControl(false);
  
    return this.formBuilder.group(group);
  }
  
  


  // Método para manejar el evento de "Seleccionar todos" en una categoría
  toggleSelectAll(categoryName: string): void {
    const categoryGroup = this.registerFormStep2.get(categoryName) as FormGroup;
    const selectAllControl = categoryGroup.get('selectAll') as FormControl;
    const isChecked = selectAllControl.value;

    // Actualizar todos los permisos de la categoría
    Object.keys(categoryGroup.controls).forEach(controlName => {
      if (controlName !== 'selectAll') {
        categoryGroup.get(controlName)?.setValue(isChecked);
      }
    });
  }

  

 async onFormSubmit(): Promise<void> {
    if (this.currentStep === 0 && this.registerFormStep1.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
      if (this.currentStep === 1 && this.registerFormStep2.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
    console.log('Form Data:', FormData); 

    const rolData = this.buildRolForDatabase();
    
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.role; 
    dialogData.action = ElementAction.create_masculino;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });
    
    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.saveRol(rolData);
      }
    });
  }
  
  
  
  private buildRolForDatabase(): any {
    const rolName = this.registerFormStep1.value.nombre;
    const rolDescripcion = this.registerFormStep1.value.description;
    const rolOperator = this.registerFormStep1.value.idOperator;
    const permissions = this.registerFormStep2.value;
  
    const listFunction = Object.keys(permissions).flatMap((categoryKey) => {
      const categoryGroup = permissions[categoryKey]; // Subgrupo por categoría
  
      return Object.keys(categoryGroup).flatMap((key) => {
        // Ignorar el checkbox "selectAll"
        if (key === 'selectAll') {
          return [];
        }
  
        const functionKey = key as keyof typeof FUNCTION_IDS;
        const idFunction = FUNCTION_IDS[functionKey];
  
        if (idFunction !== undefined && typeof categoryGroup[key] === 'boolean') {
          return [{ idFunction, status: categoryGroup[key] }];
        }
  
        return [];
      });
    });
  
    return {
      name: rolName,
      description: rolDescripcion,
      listFunction: listFunction,
      idOperator: rolOperator,
    };
  }
  

  onStepChange(event: StepperSelectionEvent) {
    this.currentStep = event.selectedIndex;
    console.log(`Pasaste al paso: ${this.currentStep}`);
    // Validación por paso
    if (this.currentStep === 0 && this.registerFormStep1.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
  
    if (this.currentStep === 1 && this.registerFormStep2.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
  }
  
  
  // Lógica para guardar el rol en la base de datos
  async saveRol(objNew: any) {
    try {
      const response = await this.service.addRol(objNew);
  
      if (response && response.status >= 0) {
        this.notifier.notify('success', this.translate.instant('success.addRol'));
        this.utilServicios.getRolsAndOperators();
        this.dialogRef.close(1);  
      } else {
        this.notifier.notify('error', this.translate.instant('error.addRol'));
      }
    } catch (error) {
      this.notifier.notify('error', this.translate.instant('error.general'));
    }
  }
 
  // Salir del formulario
  onClose(): void {
    this.dialogRef.close();
  }

  // Lógica para escuchar cambios en los permisos de manera dinámica
  private listenToChanges(): void {
    Object.keys(this.registerFormStep2.controls).forEach(categoryKey => {
      const categoryGroup = this.registerFormStep2.get(categoryKey);
  
      if (categoryGroup instanceof FormGroup) {
        const selectAllControl = categoryGroup.get('selectAll') as FormControl;
  
        // Listener para "Seleccionar todos" de la categoría
        selectAllControl?.valueChanges.subscribe(value => {
          Object.keys(categoryGroup.controls).forEach(controlName => {
            if (controlName !== 'selectAll') {
              categoryGroup.get(controlName)?.setValue(value, { emitEvent: false });
            }
          });
  
          // Actualizar el estado global cuando cambia "selectAll" de una categoría
          this.updateGlobalCheckbox();
        });
  
        // Listener para cambios en los permisos individuales dentro de la categoría
        Object.keys(categoryGroup.controls).forEach(controlName => {
          if (controlName !== 'selectAll') {
            const permissionControl = categoryGroup.get(controlName) as FormControl;
  
            permissionControl?.valueChanges.subscribe(() => {
              const allChecked = Object.keys(categoryGroup.controls).every(key => {
                if (key === 'selectAll') return true;
                return categoryGroup.get(key)?.value === true;
              });
  
              selectAllControl?.setValue(allChecked, { emitEvent: false });
  
              // Actualizar el estado global cuando cambia un permiso
              this.updateGlobalCheckbox();
            });
          }
        });
      }
    });
  
    // Listener para el checkbox global "Seleccionar todo"
    const globalControl = this.registerFormStep2.get('selectAllGlobal') as FormControl;
  
    globalControl?.valueChanges.subscribe(value => {
      Object.keys(this.registerFormStep2.controls).forEach(categoryKey => {
        const categoryGroup = this.registerFormStep2.get(categoryKey);
  
        if (categoryGroup instanceof FormGroup) {
          Object.keys(categoryGroup.controls).forEach(controlName => {
            categoryGroup.get(controlName)?.setValue(value, { emitEvent: false });
          });
        }
      });
    });
  }
  
  
  private updateGlobalCheckbox(): void {
    const allCategoriesChecked = Object.keys(this.registerFormStep2.controls).every(categoryKey => {
      const categoryGroup = this.registerFormStep2.get(categoryKey);
  
      if (categoryGroup instanceof FormGroup) {
        return Object.keys(categoryGroup.controls).every(key => {
          if (key === 'selectAll') return true;
          return categoryGroup.get(key)?.value === true;
        });
      }
      return true;
    });
  
    const globalControl = this.registerFormStep2.get('selectAllGlobal') as FormControl;
    if (globalControl) {
      globalControl.setValue(allCategoriesChecked, { emitEvent: false });
    }
  }
  

  selectAllCategories(selectAll: boolean): void {
    Object.keys(this.registerFormStep2.controls).forEach(categoryKey => {
      const categoryGroup = this.registerFormStep2.get(categoryKey);
  
      if (categoryGroup instanceof FormGroup) {
        Object.keys(categoryGroup.controls).forEach(controlName => {
          categoryGroup.get(controlName)?.setValue(selectAll, { emitEvent: false });
        });
      }
    });
  }
  
  

onNextStep(){
  if (this.registerFormStep1.invalid) {
    return ;
  }
  this.stepper.next();

}
}