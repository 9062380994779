<div *ngFor="let packageName of getKeys(groupedDataByPackageAndType)" class="mat-elevation-z2 zonaStadisticas"
    [id]="'_' + packageName">
    <div>
        <span class="header-medium">
            {{ packageName }}
        </span>
    </div>

    <div *ngFor="let type of getKeys(groupedDataByPackageAndType[packageName])">

        <div class="grafica" [id]="'_' + packageName + '_' + type">

            <div>

                <span class="header-medium">
                    <span>&nbsp;</span>
                    <button (click)="redimensionar('_' + packageName + '_' + type)" class="botonExpander">
                        <i class="bi bi-arrows-angle-expand expand"></i>
                        <i class="bi bi-arrows-angle-contract contract" style="display: none;"></i>
                    </button>
                </span>



                <mat-select *ngIf="groupedDataByPackageAndType[packageName][type].data[0].unit"
                (selectionChange)="onSelectChange($event,packageName,type)"  [(value)]="selectedUnit"  style="    width: 100px !important;">
                <mat-option *ngFor="let unit of timeUnits" [value]="unit.value">
                        {{ unit.label | translate }}
                    </mat-option>
                </mat-select>
            </div>


            <apx-chart [series]="groupedDataByPackageAndType[packageName][type].chartOptions.series || []"
                [chart]="groupedDataByPackageAndType[packageName][type].chartOptions.chart || { type: 'line' }"
                [xaxis]="groupedDataByPackageAndType[packageName][type].chartOptions.xaxis || { categories: [] }"
                [yaxis]="groupedDataByPackageAndType[packageName][type].chartOptions.yaxis || {}"
                [dataLabels]="groupedDataByPackageAndType[packageName][type].chartOptions.dataLabels || {}"
                [stroke]="groupedDataByPackageAndType[packageName][type].chartOptions.stroke || {}"
                [title]="groupedDataByPackageAndType[packageName][type].chartOptions.title || { text: '' }"
                [legend]="groupedDataByPackageAndType[packageName][type].chartOptions.legend || {} "
                [plotOptions]="groupedDataByPackageAndType[packageName][type].chartOptions.plotOptions || {}"
                [fill]="groupedDataByPackageAndType[packageName][type].chartOptions.fill || {}"
                *ngIf="groupedDataByPackageAndType[packageName][type].isValid">
            </apx-chart>
            <div class="error-message" *ngIf="!groupedDataByPackageAndType[packageName][type].isValid">
                <p>{{groupedDataByPackageAndType[packageName][type].errorMenssaje}}</p>
            </div>
        </div>
    </div>


</div>