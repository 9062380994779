var chars: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

function trimTrailingEquals(input: string) {
    let end = input.length;
    while (end > 0 && input[end - 1] === '=') {
        end--;
    }
    return input.substring(0, end);
}

function atob(input: string) {
  if (typeof input !== 'string' || input.trim() === '') {
    throw new Error("Input must be a non-empty string.");
  }
  const str = trimTrailingEquals(input);  
  if (str.length % 4 == 1) {
        throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    let output = '';
    let bs = 0;
    let bc = 0;
    for (let idx = 0; idx < str.length; idx++) {
        let buffer = chars.indexOf(str.charAt(idx));
        if (buffer === -1) break;

        bs = (bs * 64) + buffer;
        bc++;
        
        if (bc % 4 === 0) {
            output += String.fromCharCode((bs >> 16) & 255, (bs >> 8) & 255, bs & 255);
            bs = 0;
        }
    }
    if (bc % 4) {
        bs <<= 6 * (4 - bc % 4);
        if (bc % 4 === 3) {
          output += String.fromCharCode((bs >> 16) & 255, (bs >> 8) & 255);
      } else if (bc % 4 === 2) {
          output += String.fromCharCode((bs >> 16) & 255);
      } else {
          output += '';
      }
      
    }

    return output;
}

export function parseJwt(token: string) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
