import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { CUSTOM_ALERTS_TYPES, FormCreateActions } from 'src/app/util/constants';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { NotifierService } from 'angular-notifier';
import { AlertCongif } from 'src/app/models/alertConfig.model';
import { CustomAlertsService } from '../custom-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomVariablesReportService } from '../custom-variables-report.service';
import { VariablesReport } from 'src/app/models/variablesReport.model';
import { Users } from 'src/app/models/users.model';

@Component({
  selector: 'app-form-new-custom-alert',
  templateUrl: './form-new-custom-alert.component.html',
  styleUrls: ['./form-new-custom-alert.component.css']
})
export class FormNewCustomAlertComponent implements OnInit {

  registerForm: FormGroup;
  userSession?: Users;

  selectedOpe:Operator = new Operator();
  listaOpe:Operator[] = [];

  listFather: { value: string }[] = [];
  listSons: { value: string }[] = [];
  uniqueFatherChildPairs: { padre: string; hijo: string, textoVo: string}[] = [];

  listaAlertTypes:{value:string, label:string}[] = Object.keys(CUSTOM_ALERTS_TYPES).map((type:string)=> ({
    label: this.translate.instant('cutomAlertType.'+CUSTOM_ALERTS_TYPES[type].toLowerCase()),
    value: type
  }));

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  private notifier: NotifierService;

 constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,   notifier: NotifierService ,  private services: CustomAlertsService,
    private utilServicios : UtilService,private authService:AuthService,
    private translate:TranslateService,
    private customVariablesReportService:CustomVariablesReportService,
    private dialogRef: MatDialogRef<FormNewCustomAlertComponent>,
    public dialog: MatDialog) { 
      this.notifier = notifier;
      this.registerForm = this.formBuilder.group({
        hierarchy:[{
          idOperator:this.utilServicios.getOperatorId() ?? null, 
          idClient:this.utilServicios.getClientId() ?? null, 
          idSubClient:this.utilServicios.getSubClientId() ?? null
        }],
        type: [""],
        operacion: ["", Validators.required],
        value: ["", [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        priority: [""],
        padreForm: ["", Validators.required],
        hijoForm: ["", Validators.required]

      });

      this.registerForm.get('padreForm')?.valueChanges.subscribe((value:string) => {
        const filteredSons = this.uniqueFatherChildPairs
              .filter((pair) => pair.padre === value) // Filtrar las parejas con el padre seleccionado
              .map((pair) => ({ hijo: pair.hijo, textoVo: pair.textoVo })); // Mapear los valores de 'hijo' y 'textoVo'

            this.listSons = filteredSons.map((son) => ({ value: son.hijo, textoVo: son.textoVo }));
      });
  }


  async cargar(){
    //operadores
    this.utilServicios.findOperators().subscribe((operators:Operator[]) => {
      this.listaOpe = operators;
    });  
    this.loadVariablesReportData();
  }

  private async loadVariablesReportData() {
    const response = await this.customVariablesReportService.getListVariablesReport();
//lang
    this.userSession = this.authService.currentUserValue();   
    const lang = this.userSession.lang;
    const fathers = response?.data.filter((item: any) => !item.hijo).map((item: any) => {
      const variableReport = new VariablesReport();
    
      // Copiar valores base
      variableReport.id = item.id;
      variableReport.idOperator = item.idOperator;
      variableReport.padre = item.padre;
      variableReport.hijo = item.hijo;
      variableReport.traductor = item.traductor;
      
  
      // Extraer el traductor y setear el textoVo basado en el idioma
      if (!item.hijo && item.traductor) {
        try {
          const traductorObj = JSON.parse(item.traductor); 

          if (lang && traductorObj[lang.toUpperCase()]) {
            variableReport.textoVo = traductorObj[lang.toUpperCase()]; 
          } else {
            variableReport.textoVo = variableReport.padre;
          }
        } catch (error) {
          console.error("Error parsing traductor JSON:", error);
          variableReport.textoVo = "";
        }
      } else {
        variableReport.textoVo = variableReport.padre; 
      }
      return variableReport; // Devuelve el objeto completo
    });

    // Filtrar y obtener pares únicos de padre e hijo, y la traducción
    this.uniqueFatherChildPairs = Array.from(
      new Set(
        response?.data
          .filter((item: { padre?: string; hijo?: string }) => !!item.padre && !!item.hijo)
          .map((item: { padre?: string; hijo?: string, traductor?: string }) => {
            // Obtener la traducción si existe
            let textoVo = '';

            if (lang && item.traductor) {
              try {
                const traductorObj = JSON.parse(item.traductor);
                textoVo = traductorObj[lang.toUpperCase()] || "";
              } catch (error) {
                console.error("Error parsing traductor JSON:", error);
                textoVo = ""; 
              }
            }else {
              textoVo = item.hijo || ""; 
            }

            return `${item.padre}|${item.hijo}|${textoVo}`; 
          })
      )
    ).map((pair) => {
      const [padre, hijo, textoVo] = (pair as string).split('|');
      return { padre, hijo, textoVo }; // Crear el objeto con padre, hijo y traducción
    });

    this.listFather = fathers;
    console.log("fathers", this.listFather);
  }


  async ngOnInit(): Promise<void> {
   await this.cargar();
  }

  compareFn(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }


  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }    
    const data = {...this.registerForm.value};
    const infoAdd: string = `${String(data['padreForm'] ?? '')}|${String(data['hijoForm'] ?? '')}`;
    let objNew : AlertCongif = new AlertCongif();
    //Por ahora esta siempre a uno porque no tenemos ningun dato mas entonces siempre mandamos GRAFICA_PUNTUAL
    objNew.typeAlert=Number(1)
    objNew.infoAdicional= infoAdd
    objNew.operation=data['operacion']   
    objNew.value=data['value']   
    objNew.priority=data['priority']
    objNew.dateCreate = new Date

    const { idOperator, idClient, idSubClient } = data.hierarchy;
    objNew.idOperator = idOperator;
    objNew.idClient = idClient;
    objNew.idSubClient = idSubClient;
    
    const dialogData = new ConfirmDialogData(); 
    dialogData.element = Element.alert; 
    dialogData.action = ElementAction.create_femenino;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        let val = await this.services.addUpdate(objNew)
        if(val!.status>=0){
          this.notifier.notify('success', this.translate.instant('success.customAlertCreated'))            
          this.dialogRef.close(FormCreateActions.SAVED);
        }
      }
    });

  }


  onClose(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }

}


