import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormCreateActions } from 'src/app/util/constants';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData, ConfirmDialogActions, Element, ElementAction, DialogClass } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Profile } from 'src/app/models/profiles.model';
import { PerfilesService } from '../perfiles.service';
import { PerfilApp } from 'src/app/models/perfilApp.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { TerminalsSelectorComponent } from '../terminals-selector/terminals-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { HierarchySelectorComponent } from 'src/app/components/hierarchy-selector/hierarchy-selector.component';
import { Factories } from 'src/app/models/factories.model';
import { FactoriesService } from 'src/app/services/factories.service';
import { AppsSelectorComponent } from '../apps-selector/apps-selector.component';
import { FilterableSelectComponent } from 'src/app/components/filterableSelect/filterableSelect.component';


@Component({
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatStepperModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    MatLegacySlideToggleModule,
    MatLegacyInputModule,
    MatLegacyDialogModule,
    TranslateModule,
    TerminalsSelectorComponent,
    HierarchySelectorComponent,
    AppsSelectorComponent,
    FilterableSelectComponent
  ],
  selector: 'app-new-perfiles',
  templateUrl: './new-perfiles.component.html',
  styleUrls: ['./new-perfiles.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class NewPerfilesComponent implements OnInit {
  isLinear = true;
  machines: VendingMachineAtento[] = [];
  // Form groups
  detailsFormGroup!: FormGroup;
  distanceFormGroup!: FormGroup;
  
  resultListApp: PerfilApp[] = [];

  factories?: Factories = new Factories;
  
  @ViewChild('manuFacSelect') manuFacSelect?:FilterableSelectComponent;

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<NewPerfilesComponent>,
    private perfilService: PerfilesService,
    private translate: TranslateService,
    private factoriesService:FactoriesService,
    public hierarchy: HierarchyHandlerService,
    public dialog: MatDialog) {}

  async ngOnInit(): Promise<void> {
    this.initForms();
    this.loadFactoriesData();
  }

  private initForms(): void {
    this.detailsFormGroup = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(40)])],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(200)])],
      manufacID: [ null, Validators.required ],
      hierarchy:[{ idOperator:this.hierarchy.level_1, idClient:this.hierarchy.level_2, idSubClient:this.hierarchy.level_3 }],
    });

    
    // [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad 
    this.distanceFormGroup = this.formBuilder.group({
      enableLocation: [{value:false, disabled:true }],
      distanceLocation: [{value:0, disabled:true }, Validators.compose([Validators.required, Validators.min(0), Validators.max(20038)])] ,
      distanceUnit: [{value:'km', disabled:true }, Validators.required]
    });
    
    this.distanceFormGroup.get('enableLocation')?.valueChanges.subscribe((value)=>{
      if(!value){
        this.distanceFormGroup.get('distanceLocation')?.disable();
        this.distanceFormGroup.get('distanceUnit')?.disable();
      } else {
        this.distanceFormGroup.get('distanceLocation')?.enable();
        this.distanceFormGroup.get('distanceUnit')?.enable();
      }
    });

    this.detailsFormGroup.get('hierarchy')?.valueChanges.subscribe((value)=>{
      if(value) this.resetTerminalsAndApps();
    })
    
    this.detailsFormGroup.get('manufacID')?.valueChanges.subscribe((value)=>{
      if(value) this.resetTerminalsAndApps();
    })
  }

  private resetTerminalsAndApps(){
    this.machines = [];
    this.resultListApp = [];
  }

  public get hierarchyInput() {
    return this.detailsFormGroup.get('hierarchy');
  }

  public get factoryInput() {
    return this.detailsFormGroup.get('manufacID');
  }

  public get nameImput() {
    return this.detailsFormGroup.get('name');
  }

  
  validateInput(){
    this.manuFacSelect?.updateValidity();
  }

  private async loadFactoriesData(){
      const response = await this.factoriesService.getFactoriesList();
      if(response && response.data){
        this.factories = new Factories;
        this.factories.list = response.data;
      }
  }

  
  onFinalSubmit(): void {
    if (this.detailsFormGroup.invalid ||  this.distanceFormGroup.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    const formData = {
      ...this.detailsFormGroup.value,
      ...this.distanceFormGroup.value,
    };

    let objNew: Profile = new Profile();

    objNew.name = formData.name;
    objNew.description = formData.description;
    objNew.listVendingMachine = [];
    objNew.enableLocation = formData.enableLocation;
    objNew.distanceLocation = formData.distanceLocation;
    objNew.distanceUnit = formData.distanceUnit;
    objNew.manufacID = formData.manufacID;
    
    const { idOperator, idClient, idSubClient } = formData.hierarchy;
    objNew.idOperator = idOperator;
    objNew.idClient = idClient;
    objNew.idSubClient = idSubClient;


    objNew.listVendingMachine = [...this.machines];
    objNew.listApp = this.resultListApp.map(app => app.mapToServer());
    objNew.listApp.forEach((item,index)=> item.orden = index);
    

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.profile; 
    dialogData.action = ElementAction.create_masculino;
    dialogData.class = DialogClass.info;  
    dialogData.icon = 'save-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        let response: any;
        response = await this.perfilService.addProfile(objNew);
        if (response?.status >= 0) {
          this.dialogRef.close(FormCreateActions.SAVED);
        }
      }
    });
  }

  onClose(){
    this.dialogRef.close(FormCreateActions.EXIT)
  }
}
