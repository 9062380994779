export class StatisticsVendingMachine {
    total: number;
    active: number;
    off: number;
    pending: number;
    inactive: number;
    perfil: number;
    noPerfil: number;


    constructor(
        total: number = 0,
        active: number = 0,
        off: number = 0,
        pending: number = 0,
        inactive: number = 0,
        perfil: number = 0,
        noPerfil: number = 0
    ) {
        this.total = total;
        this.active = active;
        this.off = off;
        this.pending = pending;
        this.inactive = inactive;
        this.perfil = perfil;
        this.noPerfil = noPerfil;
    }
}
