import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ConsoleRequest } from 'src/app/models/console.request.model';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { UserHistory } from 'src/app/models/userHistory.model';
import { UserHistoryQueryDto } from 'src/app/models/userHistoryQueryDto.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class HistoryUserService {
  

  constructor(private merchantRest : RestService,private authService:AuthService) {}
  
  async find(filterAndPagBean:FilterAndPagBean ){

    let dto:UserHistoryQueryDto=new UserHistoryQueryDto;
    dto.filterAndPagBean=filterAndPagBean;
   
    let value = await this.merchantRest.commonRestCallConsolaRest(dto,'getListUserHistory/',true,true )   
      .catch(err => {
        return null
      })
    return value;   
  }

    async findFileToExport(filterAndPagBean: FilterAndPagBean ){
  
      let dto:UserHistoryQueryDto=new UserHistoryQueryDto;
    dto.filterAndPagBean=filterAndPagBean;


          let value = await this.merchantRest.commonRestCallFile(dto,'getFileListUserHistory',true,true )   
        .catch(err => {
          return null
        })
        
      return value;   
    }

  async countTotal( filterAndPagBean:FilterAndPagBean ){
         
    let dto:UserHistoryQueryDto=new UserHistoryQueryDto;
    dto.filterAndPagBean=new FilterAndPagBean(filterAndPagBean?.orderSentido,filterAndPagBean?.order,filterAndPagBean?.filterGeneric,null,null,null);
    
    let value =await this.merchantRest.commonRestCallConsolaRest(dto,'getCountUserHistory/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
  
 
}