import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { CommonService } from 'src/app/services/common.service';
import { Operator } from 'src/app/models/operator.model';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TranslateService } from '@ngx-translate/core';
import { TxtGraficaService } from './txtGrafica.service';
import { FormEditTxtGrafComponent } from './form-edit-txt-graf/form-edit-txt-graf.component';
import { VariablesReport } from 'src/app/models/variablesReport.model';
import { VariablesAtributo } from 'src/app/models/variablesAtributo.model';



enum Columns {
  atributo = 'atributo',
  ES = 'ES',
  EN = 'EN',
  view = 'view'
}
@Component({
  selector: 'app-texto-graficas',
  templateUrl: './texto-graficas.component.html',
  styleUrls: ['./texto-graficas.component.css'],
   providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]

})
export class TextoGraficasComponent implements OnInit, AfterViewInit{
  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<VariablesAtributo>();  
  exportFileName: string | undefined;

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId:string | null = "padre" ;
  direccion: string | null = "ASC";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  filterList: FilterableValue[] = [];

  constructor(public dialog: MatDialog, 
    public servicios:TxtGraficaService,
    public commonService : CommonService,
    public translate:TranslateService
  ) {     
  }

  async ngOnInit(): Promise<void> {    
    this.lanzarLLamada();
    this.exportFileName = this.translate.instant('export.fileVariablesReport');

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async lanzarLLamada(){
    const request = this.getFilterAndPagBean();
    const value = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);


    if(value){

      let listVariablesAtributo = value.data.map((item: VariablesReport) => {
        const variableAtributo = new VariablesAtributo();
      
        variableAtributo.id = item.id;
        variableAtributo.idOperator = item.idOperator;
      
        // Determinar el valor de atributo: combinar `padre/hijo` o solo `padre`
        variableAtributo.atributo = item.hijo ? `${item.padre}/${item.hijo}` : item.padre;
      
        if (item.traductor) {
          try {
            // Convertir el string JSON de traductor en un objeto
            const traductorObj = JSON.parse(item.traductor);
      
            // Extraer las traducciones y textoVo
            variableAtributo.ES = traductorObj["ES"] || "";
            variableAtributo.EN = traductorObj["EN"] || "";
      
          } catch (error) {
            console.error("Error parsing traductor JSON:", error);
            variableAtributo.ES = "";
            variableAtributo.EN = "";
          }
        } else {
          // Si no hay traductor, valores por defecto
          variableAtributo.ES = "";
          variableAtributo.EN = "";
        }
      
        return variableAtributo;
      });

      this.dataSource = new MatTableDataSource<VariablesAtributo>(listVariablesAtributo);
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  }
  private getFilterAndPagBean():FilterAndPagBean{
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;
    return new FilterAndPagBean(
        this.direccion, 
        this.sortId, 
        this.filterList, 
        startPage,
        endPage,
        pageIndex
      );
  }


 onEdit(row:VariablesReport){
    const dialogRef = this.dialog.open(FormEditTxtGrafComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel' ,
      data: { operatorId : row.id }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }
  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }
}

