import { Component, OnInit, Inject } from '@angular/core';
import { SensorService } from './sensores.service';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import { StadisticSensor } from 'src/app/models/stadisticSensor.model';
import { NotifierService } from 'angular-notifier';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CoveragePeriod } from 'src/app/models/coveragePeriod.model';
import { DataPeriod } from 'src/app/models/dataPeriod.model';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { compareDates, getEndDate, getLastWeekFrom, getNextWeekFrom, getStartDate, parseStringToUTCDate } from 'src/app/util/util';
import { FilterableValue, OPERATION_FILTER, TYPE_FILTER } from 'src/app/models/FilterAndPagBean';


const FILTER_COLUMNS = [
  { field:'date', label:'common.date', type:'date'},
]


@Component({
  selector: 'app-sensores',
  templateUrl: './sensores.component.html',
  styleUrls: ['./sensores.component.css'],
})
export class SensoresComponent implements OnInit {
  idVendingMachine: number;
  consumeList: DataPeriod[] = [];
	coverageList: CoveragePeriod[] = [];
  filterList: FilterableValue[] = [];

  filterColumns:FilterField[]= FILTER_COLUMNS.map((column)=> new FilterField(column.field, column.label, column.type as FieldType));
  initDateFilter = getLastWeekFrom(new Date().toString());
  endDateFilter = new Date();

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { terminalId:number },
    private sensorService: SensorService, 
    private notifier:NotifierService
  ) {
    this.idVendingMachine = this.data.terminalId;
    this.defaultDateFilterState();
  }

  ngOnInit(): void {
    this.loadSensorData();
    this.loadStadistic();
  }

  async loadSensorData() {
    const stadisticBean = new StadisticBean();
    stadisticBean.idVendingMachine = this.idVendingMachine
    stadisticBean.dateIni = this.initDateFilter;
    stadisticBean.dateEnd = this.endDateFilter;
    stadisticBean.typeReport = 2
    stadisticBean.resolutionHour = false

    const sensorData: StadisticSensor = await this.sensorService.getSensors(stadisticBean);
    if(!sensorData){
      this.notifier.notify('error', 'No se encuentran datos para mostrar');
      return;
    }

    this.consumeList = sensorData.listData.sort((a,b)=> compareDates(parseStringToUTCDate(a.period as string), parseStringToUTCDate(b.period as string)));
    this.coverageList = sensorData.listCoverage.sort((a,b)=> compareDates(parseStringToUTCDate(a.period as string), parseStringToUTCDate(b.period as string)));
  }

  defaultDateFilterState(){
    this.initDateFilter = getLastWeekFrom(new Date().toString());
    this.endDateFilter = new Date();
  }

  validateIfRangeIsApplied(filters:AppliedFilter[], searchOperation:string){
    return filters.some((item)=>{ item.filter.operation === searchOperation});
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.defaultDateFilterState()
    appliedFilters.forEach((appliedFilter)=>{
      const { filter } = appliedFilter;
      if (filter.operation === 'GREATER_THAN_OR_EQUAL'){
        this.initDateFilter = new Date(filter.value as string);
        
        const isNotRange = !this.validateIfRangeIsApplied(appliedFilters, 'LESS_THAN_OR_EQUAL'); 
        if(isNotRange) this.endDateFilter = getNextWeekFrom(filter.value as string);
      }
      if (filter.operation === "LESS_THAN_OR_EQUAL"){
        this.endDateFilter = new Date(filter.value as string);
        
        const isNotRange = !this.validateIfRangeIsApplied(appliedFilters, 'GREATER_THAN_OR_EQUAL'); 
        if(isNotRange) this.initDateFilter = getLastWeekFrom(filter.value as string)
      }
      if(filter.operation === "EQUALS"){
        const date = new Date(filter.value as string);
        this.initDateFilter = getStartDate(date.toString());
        this.endDateFilter = getEndDate(date.toString());  
      }
    });
    this.loadSensorData();
    this.loadStadistic(appliedFilters);
  }

  loadStadistic(appliedFilters?:AppliedFilter[]) {
  
   
      this.filterList = [];
      if(appliedFilters) {
        this.filterList = appliedFilters.map((appliedFilter)=>{
          const filter = appliedFilter.filter;
          return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
        });
      }
      this.filterList.push(new FilterableValue("idVM", this.idVendingMachine, TYPE_FILTER.String, OPERATION_FILTER.EQUALS));
  }
  
}
