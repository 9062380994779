<div class="edit-panel">
    <div class="header">
      <div class="title">{{ 'textGrafica.edit_title' | translate }}</div>
      <div class="action-buttons">
        <button class="ct-button ct-button--primary" (click)="onFormSubmit()" [disabled]="registerForm.pristine || registerForm.invalid">
          {{ 'common.submit' | translate }}
        </button>
      
        <button class="ct-button ct-button--tertiary" (click)="formExit()">
          <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
        </button>
      </div>
    </div>
  
    <!-- Formulario principal de Operador -->
    <form [formGroup]="registerForm">
      <fieldset class="edit-container bordered">
        <legend class="details-header">
          <label class="title-small">{{ 'textGrafica.details' | translate }}</label>
        </legend>
        <div class="edit-content">
          
          <div class="row">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'textGrafica.atributo' | translate }}<span class="obligatorio"></span></mat-label>
                  <input [readonly]="true" matInput formControlName="atributo" type="text" required />
                  <mat-error *ngIf="registerForm.get('atributo')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'textGrafica.es' | translate }}<span class="obligatorio"></span></mat-label>
                    <input matInput formControlName="ES" type="text" />
                  </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'textGrafica.en' | translate }}<span class="obligatorio"></span></mat-label>
                    <input matInput formControlName="EN" type="text" />
                  </mat-form-field>
                </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>