
<div class="applied-filters">
    <div class="applied-filters__list" *ngFor="let filter of filters; let i=index">
        <app-filter-pill [filter]="filter.display" (remove)="removeItem(i)"></app-filter-pill>
    </div>

    <div class="applied-filters__trigger" #trigger>
        <button class="ct-button ct-button--secondary" (click)="onTrigger.emit(triggerElem)">
            <mat-icon *ngIf="!isDateFilter" class="ct-icon" svgIcon="filter-lines"></mat-icon>
            <mat-icon *ngIf="isDateFilter" class="ct-icon" svgIcon="calendar"></mat-icon>
        </button>
    </div>
</div>
