import { FileInfo } from "../models/fileInfo.model";


export class FileUtils {

    static async processCapFile(file: File): Promise<FileInfo> {
        const fileInfo = new FileInfo();
        try {
            const fileContent = await this.readFileAsText(file);
            const match = fileContent.match(/\{[\s\S]*?\}/);
            if (match) {
                const jsonContent = match[0].trim();
                const parsedJson = JSON.parse(jsonContent);
                return this.extractFileInfo(parsedJson);
            }
        } catch (error) {
            console.error('Error al leer el archivo .cap:', error);
        }
        return fileInfo; 
    }

    static extractFileInfo(jsonData: any): FileInfo {
        return {
            packageName: jsonData["Appendage"] || '',
            version: jsonData["versionName"] || ''
        };
    }


    static readFileAsText(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target?.result as string);
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    }

    static   validateFileInfo(fileInfo?: { version?: string; packageName?: string }): boolean {
        return !!(fileInfo?.version && fileInfo?.packageName && 
                  fileInfo.version.trim() !== '' && 
                  fileInfo.packageName.trim() !== '');
      }
}

