import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HierarchyInput } from 'src/app/components/hierarchy-selector/hierarchy-selector.component';
import { Aplications } from 'src/app/models/aplications.model';
import { PerfilApp } from 'src/app/models/perfilApp.model';
import { Parameters } from 'src/app/models/parameters.model';
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER, TYPE_FILTER } from 'src/app/models/FilterAndPagBean';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { AppsService } from '../../aplications/aplications.service';
import { ParamService } from '../../param/param.service';
import { NotifierService } from 'angular-notifier';
import { ProfileAppBoxComponent } from '../profile-app-box/profile-app-box.component';
import { FilterableSelectComponent, FilterableSelectOption } from 'src/app/components/filterableSelect/filterableSelect.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-apps-selector',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ProfileAppBoxComponent,
    FilterableSelectComponent
  ],
  templateUrl: './apps-selector.component.html',
  styleUrls: ['./apps-selector.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class AppsSelectorComponent implements OnChanges, OnInit, OnDestroy {
  @Input('hierarchy') hierarchyInput: (HierarchyInput| null) = null;
  @Input() factory: string = '';  
  @Input() referenceList?: PerfilApp[] = [];

  @Input() list: PerfilApp[] = [];
  @Output() listChange = new EventEmitter<PerfilApp[]>();

  
  // Variables for apps and parameters
  appsBBDD: FilterableSelectOption[] = [];
  versionBBDD: FilterableSelectOption[] = [];
  parameters: Parameters[] = [];
  searchAppForm = new FormGroup({
    application: new FormControl(''),
    version:new FormControl(null)
  })
  
  applicationSelectorChange?: Subscription;
  versionSelectorChange?: Subscription;

  constructor(
    private readonly hierarchy: HierarchyHandlerService,
    private readonly appsService: AppsService,
    private readonly paramService: ParamService,
    private readonly notifier:NotifierService,
    private readonly translate:TranslateService
  ){}

  ngOnChanges(): void {
    this.resetSelectors();
    this.loadAvailableApps();
    this.loadParameters();
  }

  ngOnInit(): void {
    this.applicationSelectorChange = this.searchAppForm.get('application')?.valueChanges.subscribe((packageName:(string | null))=>{
      if(packageName) {
        this.onAppSelect(packageName);
      } else {
        this.searchAppForm.get('version')?.reset();
      }
    })
    
    this.versionSelectorChange = this.searchAppForm.get('version')?.valueChanges.subscribe((version:(Aplications|null))=>{
      if(version) this.onVersionSelect(version);
    })
  }

  ngOnDestroy(): void {
    this.applicationSelectorChange?.unsubscribe();
    this.versionSelectorChange?.unsubscribe();
  }

  private resetSelectors(){
    this.searchAppForm.get('application')?.reset();
    this.appsBBDD = [];
    this.versionBBDD = [];
    this.parameters = [];
  }

  private mapAndCleanData(data:Aplications[]){
    //pueden venir duplicados se hya varias versiones, asi que quitamos al duplicadas
    const mappedData:FilterableSelectOption[] = data.map((app:Aplications)=>{ return { value:`${app.pakage.trim()}#${app.idOperator ?? ''}#${app.idClient ?? ''}#${app.idSubClient ?? ''}`, label:app.name } });
    const cleanDuplicates = Array.from(new Map(mappedData.map(item => [item.value, item])).values());
    this.appsBBDD = [...this.appsBBDD, ...cleanDuplicates];

  }

  private async loadAvailableApps(): Promise<void> {
    this.appsBBDD = [];
    // Aquí, deberías hacer una llamada al backend para obtener las aplicaciones filtradas por jerarquía
    if(this.hierarchyInput?.idOperator){
      const inputToFilter = { idOperator:this.hierarchyInput.idOperator };
      const filterablesValues = this.hierarchy.getNullableHierarchyFilters(inputToFilter); 
      if(this.factory) filterablesValues.push(new FilterableValue("manufacID", this.factory, TYPE_FILTER.String, OPERATION_FILTER.EQUALS));
      
      const request = new FilterAndPagBean("ASC","name",filterablesValues,0,0,0);
      const result = await this.appsService.find(request);
      if (result?.status === 0) {
        this.mapAndCleanData(result.data);
      }
    }

    if(this.hierarchyInput?.idClient){
      const inputToFilter = {idOperator:this.hierarchyInput.idOperator, idClient:this.hierarchyInput.idClient};
      const filterablesValues = this.hierarchy.getNullableHierarchyFilters(inputToFilter); 
      if(this.factory) filterablesValues.push(new FilterableValue("manufacID", this.factory, TYPE_FILTER.String, OPERATION_FILTER.EQUALS));
      
      const request = new FilterAndPagBean("ASC","name",filterablesValues,0,0,0);
      const result = await this.appsService.find(request);
      if (result?.status === 0) {
        this.mapAndCleanData(result.data);
      }
    }

    
    if(this.hierarchyInput?.idSubClient){
      const filterablesValues = this.hierarchy.getNullableHierarchyFilters(this.hierarchyInput); 
      if(this.factory) filterablesValues.push(new FilterableValue("manufacID", this.factory, TYPE_FILTER.String, OPERATION_FILTER.EQUALS));
      
      const request = new FilterAndPagBean("ASC","name",filterablesValues,0,0,0);
      const result = await this.appsService.find(request);
      if (result?.status === 0) {
        this.mapAndCleanData(result.data);
      }
    }
    
  }

  private async loadParameters(): Promise<void> {
    // Aquí, deberías hacer una llamada al backend para obtener los parámetros filtradas por jerarquía
    const filterablesValues = this.hierarchy.getFilterableValues(this.hierarchyInput);
    const request = new FilterAndPagBean("ASC","name",filterablesValues,0,0,0);
    const result = await this.paramService.find(request);
    if (result?.status === 0) {
      this.parameters = result.data;
    }
  }

  private async loadVersions(packageHash:string):Promise<void>{
    const [ packageName, idOperator, idClient, idSubClient ] = packageHash.split('#');    
    const inputToFilter = {idOperator: idOperator ? Number(idOperator) : null, idClient: idClient ? Number(idClient) : null, idSubClient:idSubClient ? Number(idSubClient) : null};
    const filterList = this.hierarchy.getNullableHierarchyFilters(inputToFilter); 
    filterList.push(new FilterableValue("pakage", packageName, TYPE_FILTER.String, OPERATION_FILTER.EQUALS));
    if(this.factory){
      filterList.push(new FilterableValue("manufacID", this.factory, TYPE_FILTER.String, OPERATION_FILTER.EQUALS));
    }
    const request = new FilterAndPagBean("ASC","version", filterList, 0, 0, 0);
    const result = await this.appsService.find(request);
    if (result?.status === 0) {
      this.versionBBDD = result.data.map((app:Aplications)=>{ return {value:app, label:`${app.version} ${app.modelNumber ?? ''}` }});
    }
  }

  
  onAppSelect(packageName: string): void {
    this.versionBBDD = [];    
    this.loadVersions(packageName);    
  }

  onVersionSelect(version: Aplications) {
    const exists = this.existsApplicationOnProfile(version);
    if (exists) {
      this.notifier.notify('warning', this.translate.instant('warning.duplicateApp'));
      return
    }

    let perfilApp: PerfilApp = new PerfilApp();
    perfilApp.idApp = version.id;
    perfilApp.appName = version.name;
    perfilApp.appPakage = version.pakage;
    perfilApp.appVersion = version.version;
    perfilApp.manufacID = version.manufacID;
    perfilApp.modelNumber = version.modelNumber;
    perfilApp.isGobernada = version.isGobernada;
    perfilApp.isIntegrada = version.isIntegrada;

    this.list.push(perfilApp);
    this.listChange.emit(this.list);
  }

  
  private existsApplicationOnProfile(appToAdd:Aplications): boolean {
    const exists = this.list.some(app => app.appPakage === appToAdd.pakage);
    const existsInProfile = this.referenceList?.some(app => app.appPakage === appToAdd.pakage) ??  false;
    return (exists || existsInProfile);
  }

  
  removeApp(app: PerfilApp): void {
    this.list = this.list.filter(perfilApp => perfilApp.idApp !== app.idApp);
    this.listChange.emit(this.list);
  }
}
