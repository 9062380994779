<main id="main" class="main">
    <div class="pagetitle">
        <span class="header-large">{{ 'aplications.aplications' | translate }}</span>
        
        <div>
            <button class="ct-button ct-button--secondary" (click)="refrescar()">
                <mat-icon class="ct-icon" svgIcon="refresh-ccw-01"></mat-icon> 
                <span class="hide-on-mobile">{{'common.refresh' | translate }}</span>
            </button>
            <ng-container *ngIf="rol.canByInput(rol.functIds.edit_applications)">
                <button class="ct-button ct-button--primary" (click)="onNew()">
                    <mat-icon class="ct-icon" svgIcon="plus"></mat-icon> 
                    <span class="hide-on-mobile">{{ 'aplications.newAplication' | translate }}</span>
                </button>
            </ng-container>
        </div>
    </div>

    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

    <div class="mat-elevation-z2 tablaPersonal">
        <table mat-table [dataSource]="dataSource" aria-describedby="" matSort
            (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter"
            [hiddenColumns]="getNotExportColumns()">
           
            <!-- Column Definitions -->
           
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="mat-column-name">
                    <div mat-sort-header>{{ 'aplications.name' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="mat-column-name"> {{element.name}} </td>
            </ng-container>

            <!-- Operator Column -->
            <ng-container matColumnDef="idOperator">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'hierarchy.level_1' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.idOperator | levelName:'LEVEL_1' }} </td>
            </ng-container>

            <!-- Client Column -->
            <ng-container matColumnDef="idClient">
                <th mat-header-cell *matHeaderCellDef class="mat-column-region">
                    <div mat-sort-header>{{ 'hierarchy.level_2' | jerarquiaLevelTranslate: hierarchy.level.LEVEL_2 }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="mat-column-region"> {{element.idClient | levelName:'LEVEL_2' }} </td>
            </ng-container>

            <!-- SubClient Column -->
            <ng-container matColumnDef="idSubClient">
                <th mat-header-cell *matHeaderCellDef class="mat-column-region">
                    <div mat-sort-header>{{ 'hierarchy.level_3' | jerarquiaLevelTranslate: hierarchy.level.LEVEL_3 }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="mat-column-region"> {{element.idSubClient | levelName:'LEVEL_3' }} </td>
            </ng-container>

            <!-- dateCreate Column -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'aplications.dateCreate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
            </ng-container>

            <!-- dateUpdate Column -->
            <ng-container matColumnDef="dateUpdate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'aplications.dateUpdate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.dateUpdate | dateOnTimeZone:true }}</td>
            </ng-container>

            <!-- Package Column -->
            <ng-container matColumnDef="pakage">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'aplications.package' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.pakage}} </td>
            </ng-container>

            <!-- Tags Column -->
            <ng-container matColumnDef="tags">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'aplications.tags' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <app-tags-display [tags]="element.listEtiquetas" [limit]="2"></app-tags-display>
                </td>
            </ng-container>

            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
                <td mat-cell *matCellDef="let element" class="button" style="width: 60px;">
                    <button *ngIf="rol.canByInput(rol.functIds.edit_applications)"
                        matTooltip="{{ 'common.view' | translate }}" (click)="onEdit(element)" mat-icon-button
                        class="iconoTabla">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Empty state -->
        <ng-container *ngIf="!dataSource.data.length">
            <app-table-empty-state></app-table-empty-state>
        </ng-container>

        <!-- Botón para exportar -->
        <button class="ct-button ct-button--icon" (click)="exportTable()">
            <mat-icon svgIcon="file-download-03"></mat-icon>
          </button> 

        <!-- Paginador -->
        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>


    </div>
</main>