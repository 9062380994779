<div class="edit-panel">
  <div class="header">
    <div class="title">{{ 'subClient.edit' | translate }}</div>
    <div class="action-buttons">
      <ng-container *ngIf="rol.canByInput(rol.functIds.edit_subclients)">
        <button class="ct-button ct-button--primary" (click)="onFormSubmit()" [disabled]="registerForm.pristine || registerForm.invalid">
          {{ 'editPanel.save' | translate }}
        </button>
      </ng-container>
      
      <button class="ct-button ct-button--tertiary" (click)="formExit()">
        <mat-icon svgIcon="x"></mat-icon>
      </button>
    </div>
  </div>

  <!-- Formulario -->
  <form [formGroup]="registerForm">
    <!-- Details Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header">
        <label class="title-small">{{ 'subClient.details' | translate }}</label>
      </legend>
      <div class="edit-content">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.name' | translate }}<span class="obligatorio"></span></mat-label>
                <input matInput class="form-field" formControlName="name" type="text" required>
                <mat-error *ngIf="registerForm.get('name')?.hasError('required')">{{ 'error.required.field' | translate}}</mat-error>
                <mat-error *ngIf="registerForm.get('name')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.cod' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="cod" type="text">
                <mat-error *ngIf="registerForm.get('cod')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Dropdown para seleccionar operadores -->
        <div class="row" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
          <div class="col-12">
            <div class="form-group">
              <filterable-select #OperatorSelect formControlName="idOperator" [options]="operators" 
                [title]="'subClient.operator' | translate" displayField="name" valueField="id" [required]="true"></filterable-select> 
            </div>
          </div>
        </div>

        <!-- Selección de cliente -->
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <filterable-select #ClientSelect formControlName="idClient" [options]="clients" 
                [title]="'subClient.client' | jerarquiaLevelTranslate: hierarchy.level.LEVEL_2" displayField="name" valueField="id" [required]="true"></filterable-select> 
            </div>
          </div>
        </div>


        
        <div class="row">
         
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.phone' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="phone" type="text">
                <mat-icon matSuffix matTooltip="{{ 'info.phoneValidation' | translate }}" class="custom-icon-color">info</mat-icon>
                <mat-error *ngIf="registerForm.get('phone')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
                <mat-error *ngIf="registerForm.get('phone')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
       
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.personContact' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="personContact" type="text">
                <mat-error *ngIf="registerForm.get('personContact')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <!-- Footer Section -->
    <div class="footer" *ngIf="rol.canByInput(rol.functIds.delete_subclients)">
      <button  class="ct-button ct-button--secondary" (click)="onDelete(data)">
        {{ 'editPanel.deleteVersion' | translate }}
        <mat-icon class="ct-icon red" svgIcon="trash-01"></mat-icon>
      </button>
    </div>
  </form>
</div>