import { ChangeDetectionStrategy, Component, inject, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoveragePeriod } from 'src/app/models/coveragePeriod.model';
import { ApexAxisChartSeries, ApexChart, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, NgApexchartsModule } from 'ng-apexcharts';
import { parseStringToUTCDate } from 'src/app/util/util';
import { CoverageChartPeriod } from './coverage-chart-period.model';
import { FormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StylesConfig } from 'src/app/util/constants';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis,
  yaxis: ApexYAxis,
  tooltip: ApexTooltip,
  stroke: ApexStroke,
};

@Component({
  selector: 'app-coverage-chart',
  standalone: true,
  imports: [CommonModule, FormsModule, NgApexchartsModule],
  templateUrl: './coverage-chart.component.html',
  styleUrls: ['./coverage-chart.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class CoverageChartComponent implements OnChanges {
  translate = inject(TranslateService);

  @Input() list:CoveragePeriod[] = [];
  options?:ChartOptions;
  
  signal: '2G' | '3G' | '4G' = '3G';

  coverage3GSeries: CoverageChartPeriod[] = [];
  coverage2GSeries: CoverageChartPeriod[] = [];
  coverage4GSeries: CoverageChartPeriod[] = [];

  COLOR_3G = '#008FFB';
  COLOR_2G = '#FEB019';
  COLOR_4G = '#00e396';


  ngOnChanges(): void {
    this.mapDataPerDay();
    this.initializeChartOptions();
  }

  filterChartBySignal(){
    this.initializeChartOptions()
  }

  // Obtengo el mínimo valor distinto a 0;
  private compareMin(previusValue:number = 0, currentValue:number = 0): number {
    return (previusValue && currentValue) ? Math.min(previusValue,currentValue) : Math.max(previusValue,currentValue);
  }

  private compareMax(previusValue:number = 0, currentValue:number = 0): number {
    return Math.max(previusValue,currentValue)
  }

  private sumValues(previusValue:number = 0, currentValue:number = 0): number {
    return previusValue + currentValue;
  }
 

  private set2GPeriodData(periodKey:string, period:string, previousPeriod:CoverageChartPeriod|undefined, coveragePeriod:CoveragePeriod, mapListPerDay:Map<string,any>){
    mapListPerDay.set(periodKey, new CoverageChartPeriod('2G', period, 
      this.compareMin(previousPeriod?.min, coveragePeriod.min_2g),
      this.compareMax(previousPeriod?.max, coveragePeriod.max_2g),
      this.sumValues(previousPeriod?.sumAverage, coveragePeriod.average_2g),
      this.sumValues(previousPeriod?.sumCount, (coveragePeriod.count_2g) ? 1 : 0), // prevengo contar filas sin datos;
      this.COLOR_2G
    ));
  }

  private set3GPeriodData(periodKey:string, period:string, previousPeriod:(CoverageChartPeriod|undefined), coveragePeriod:CoveragePeriod, mapListPerDay:Map<string,any>){
    mapListPerDay.set(periodKey, new CoverageChartPeriod('3G', period, 
      this.compareMin(previousPeriod?.min, coveragePeriod.min),
      this.compareMax(previousPeriod?.max, coveragePeriod.max),
      this.sumValues(previousPeriod?.sumAverage, coveragePeriod.average),
      this.sumValues(previousPeriod?.sumCount, (coveragePeriod.count) ? 1 : 0), // prevengo contar filas sin datos;
      this.COLOR_3G
    ));
  }

  private set4GPeriodData(periodKey:string, period:string, previousPeriod:CoverageChartPeriod|undefined, coveragePeriod:CoveragePeriod, mapListPerDay:Map<string,any>){
    mapListPerDay.set(periodKey, new CoverageChartPeriod('4G', period, 
      this.compareMin(previousPeriod?.min, coveragePeriod.min_4g),
      this.compareMax(previousPeriod?.max, coveragePeriod.max_4g),
      this.sumValues(previousPeriod?.sumAverage, coveragePeriod.average_4g),
      this.sumValues(previousPeriod?.sumCount, (coveragePeriod.count_4g) ? 1 : 0), // prevengo contar filas sin datos;
      this.COLOR_4G
    ));
  }

  private mapDataPerDay(){
    const mapListPerDay = new Map<string, CoverageChartPeriod>();
    this.coverage3GSeries = [];
    this.coverage2GSeries = [];
    this.coverage4GSeries = [];
    
    this.list.forEach((coveragePeriod:CoveragePeriod) =>{
      const periodDate = parseStringToUTCDate(coveragePeriod.period as string).toLocaleDateString('en');
      let periodKey, previousPeriod;

      periodKey = periodDate + '_' + '2G';
      previousPeriod = mapListPerDay.get(periodKey);
      this.set2GPeriodData(periodKey, periodDate, previousPeriod, coveragePeriod, mapListPerDay);

      periodKey = periodDate + '_' + '3G';
      previousPeriod = mapListPerDay.get(periodKey);
      this.set3GPeriodData(periodKey, periodDate, previousPeriod, coveragePeriod, mapListPerDay);
      
      periodKey = periodDate + '_' + '4G';
      previousPeriod = mapListPerDay.get(periodKey);
      this.set4GPeriodData(periodKey, periodDate, previousPeriod, coveragePeriod, mapListPerDay);
    });

    mapListPerDay.forEach((groupPeriod)=>{
      switch (groupPeriod.type){
        case '2G':
          this.coverage2GSeries.push(groupPeriod)
          break;
        case '3G':
          this.coverage3GSeries.push(groupPeriod)
          break;
        case '4G':
          this.coverage4GSeries.push(groupPeriod)
          break;
      }
    });

  }

  initializeChartOptions(): void {
    this.options = {
      series: this.series,
      chart: {
        height: 350,
        type: "boxPlot",
        zoom:{
          enabled:false
        },
        locales:[this.chartLocale],
        defaultLocale:this.defaultLocale,
        fontFamily: StylesConfig.fontFamily
      },
      stroke: {
        curve: "straight",
        width: 2
      },
      xaxis: this.xAxisConfig,
      yaxis: this.yAxisConfig,
      tooltip: this.tooltipConfig,
    };
  }

  private get xAxisConfig(): ApexXAxis {
    return {
      type: 'category',
      categories:this.coverage3GSeries.map((serie)=> serie.period),
      tickAmount:this.coverage3GSeries.length,
      labels:{
        formatter: (value:string)=>{
          return new Date(value).toLocaleDateString();
        }
      }
    };
  }

  private get yAxisConfig(): ApexYAxis {
    return  {
      title: { text: this.translate.instant('sensors.coverage-xAxis') },
      labels: {
        formatter: function (value: number) {
          return `${value.toFixed(0)}%`;
        }
      },
      min: 0,
      max: 100
    }
  }

  private get tooltipConfig(): ApexTooltip {
    const minLabel = this.translate.instant('sensors.coverage-min'),
    maxLabel = this.translate.instant('sensors.coverage-max'),
    mediumLabel = this.translate.instant('sensors.coverage-medium');
    return {
      shared: true,
      intersect: false,
      custom: function({_, seriesIndex, dataPointIndex, w}) {
        return (
          "<div class='chart-tooltip'>" +
            "<div class='chart-tooltip-body'>"+
              `<span> ${maxLabel}: ${w.config.series[seriesIndex].data[dataPointIndex].y[4]} </span>` +
              `<span> ${mediumLabel}: ${w.config.series[seriesIndex].data[dataPointIndex].y[2]} </span>` +
              `<span> ${minLabel}: ${w.config.series[seriesIndex].data[dataPointIndex].y[0]} </span>` +
            "</div>" +
          "</div>"
        );
      }
    };
  }

  private getColorBySignal():string{
    switch(this.signal){
      case '2G':
        return this.COLOR_2G;
      case '3G':
        return this.COLOR_3G;
      case '4G':
        return this.COLOR_4G;
    }
  }

  private getSelectedSerie(){
    switch(this.signal){
      case '3G':
        return this.coverage3GSeries;
      case '2G':
        return this.coverage2GSeries;
      case '4G':
        return this.coverage4GSeries;
    }
  }

  private get series():ApexAxisChartSeries{
    console.debug('Get series', this.coverage2GSeries, this.coverage3GSeries, this.coverage4GSeries)
    const serieToShow = this.getSelectedSerie();
    const seriesColor = this.getColorBySignal();

    return[
      {
        name: this.signal,
        type: 'boxPlot',
        color: seriesColor,
        data: serieToShow.map((item)=> item.getBoxPlotSeriesData())
      },
      {
        name: this.signal,
        type: 'line',
        color: seriesColor,
        data: serieToShow.map((item)=> item.getLineSeriesData())
      },
    ];
  }
  

  get defaultLocale(){
    return 'es'
  }

  get chartLocale(){
    return  {
        name: 'es',
        options: {
          toolbar: {
            donwload:this.translate.instant('common.downloadSVG'),
            exportToSVG: this.translate.instant('common.downloadSVG'),
            exportToPNG: this.translate.instant('common.downloadPNG'),
            exportToCSV: this.translate.instant('common.downloadCSV'),
          }
        }
    };
  }
}


