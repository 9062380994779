import { FilterAndPagBean } from "./FilterAndPagBean";

export class UsersConsoleRequest {
  
  id: number | undefined;	
  idUser: number | undefined;	
  idOperator: number | undefined;	
  idClient: number | undefined;	
  idSubClient: number | undefined;	
  idRol: number | undefined;	
  idGroup: number | undefined;	
  idEntorno: number | undefined;	
  estado: number | undefined;	

  
  filterAndPagBean: FilterAndPagBean | undefined;	
   
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}








