import { Injectable } from '@angular/core';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { ListFileRequest } from 'src/app/models/list-file.models';
import { RestService } from 'src/app/services/merchant/Rest.service';

@Injectable({
  providedIn: 'root'
})
export class ListFilesService {

  constructor(private service:RestService) { }

  async find(_body:ListFileRequest){
    let value = await this.service.commonRestCallConsolaRest(_body,'getListFile/', true, true)   
      .catch(err => {
        return null
      })
    return value;   
  }
  
  async count(_body:ListFileRequest){
    const bean = _body.filterAndPagBean;
    _body.filterAndPagBean = new FilterAndPagBean(bean.orderSentido,bean.order,bean.filterGeneric,null, null, null);
    let value = await this.service.commonRestCallConsolaRest(_body,'countListFile/', true, true)   
      .catch(err => {
        return null
      })
    return value;   
  }

  async findById(_id:number){
    let value = await this.service.commonRestCallConsolaRest({ id: _id },'getFile/', true, true)   
      .catch(err => {
        return null
      })
    return value;   
  }
  //getFileByte

  async findFileById(_id:number){
    const contentType = {'Content-Type': ''};
    let value = await this.service.commonRestCallFile({ id: _id },'getFileByte/', true, true, contentType)   
      .catch(err => {
        return null
      })
    return value;   
  }

  async delete(_id:number){     
    let value = await this.service.commonRestCallConsolaRest({ id: _id },'deleteFile/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async deleteAll(_ids:number[]){     
    let value = await this.service.commonRestCallConsolaRest({ ids: _ids },'deleteListFile/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
}
