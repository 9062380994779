<div class="progress-container">
    <!-- Primera barra con su leyenda -->
    <div [class]="perfil ? 'columna' : 'columna-max'">
        <div class="legend">
            <div *ngFor="let segment of segmentsStatus" class="legend-item">
                <span class="legend-color" [ngStyle]="{ 'background-color': segment.color }"></span>
                <span> {{ segment.label }}: {{ segment.value }} ({{ (segment.value / data.total) * 100 | number:'1.2-2' }}%)</span>
            </div>
        </div>
        <div class="progress-wrapper">
            <mat-progress-bar mode="determinate" [value]="100"></mat-progress-bar>
            <div class="progress-overlay">
                <div matTooltip="{{ segment.label }}: {{ segment.value }} ({{ (segment.value / data.total) * 100 | number:'1.2-2' }}%)"
                  *ngFor="let segment of segmentsStatus; let i = index; let last = last" 
                  class="progress-segment"
                  [ngClass]="{
                    'rounded-left': i === firstValidStatusIndex, 
                    'rounded-right': last
                  }"
                  [ngStyle]="{ 'width': segment.percentage + '%', 'background-color': segment.color }">
                </div>
            </div>
        </div>
    </div>

    <!-- Segunda barra con su leyenda -->
    <div *ngIf="perfil" class="columna">
        <div class="legend">
            <div *ngFor="let segment of segmentsProfile" class="legend-item">
                <span class="legend-color" [ngStyle]="{ 'background-color': segment.color }"></span>
                <span> {{ segment.label }}: {{ segment.value }} ({{ (segment.value / data.total) * 100 | number:'1.2-2' }}%)</span>
            </div>
        </div>
        <div class="progress-wrapper">
            <mat-progress-bar mode="determinate" [value]="100"></mat-progress-bar>
            <div class="progress-overlay">
                <div matTooltip="{{ segment.label }}: {{ segment.value }} ({{ (segment.value / data.total) * 100 | number:'1.2-2' }}%)"
                  *ngFor="let segment of segmentsProfile; let i = index; let last = last" 
                  class="progress-segment"
                  [ngClass]="{
                    'rounded-left': i === firstValidProfileIndex, 
                    'rounded-right': last
                  }"
                  [ngStyle]="{ 'width': segment.percentage + '%', 'background-color': segment.color }">
                </div>
            </div>
        </div>
    </div>
</div>
