import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppliedFilter, 
  CompareValueType, 
  DateFilterData, 
  FilterDisplay,
  FilterOption
} from 'src/app/models/filter.model';
import { FilterableSelectComponent } from '../../filterableSelect/filterableSelect.component';
import {MatDatepickerModule } from '@angular/material/datepicker';
import { NotifierService } from 'angular-notifier';



export const DATE_DEFINITIONS = [
  { value: 'today', label: 'filter.date.today' },
  { value: 'yesterday', label: 'filter.date.yesterday' },
  { value: 'this_week', label: 'filter.date.this_week' },
  { value: 'last_week', label: 'filter.date.last_week' },
  { value: 'this_month', label: 'filter.date.this_month' },
  { value: 'last_month', label: 'filter.date.last_month' },
  // { value: 'this_year', label: 'filter.date.this_year' },
  // { value: 'last_year', label: 'filter.date.last_year' },
  { value: 'select', label: 'filter.date.select' }
];


@Component({
  standalone:true,
  imports:[
    CommonModule, 
    TranslateModule, 
    ReactiveFormsModule,
    MatLegacyInputModule,
    MatLegacyFormFieldModule,
    MatLegacyButtonModule,
    MatLegacySelectModule,
    FilterableSelectComponent,
    MatDatepickerModule
  ],
  selector: 'app-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.css']
})
export class FormDateComponent {
  public filterForm: FormGroup;
  public fields: { label: string; value: string }[] = [];
  
  public inputType:string = 'text';
  public hasOptions:boolean = false;
  public options:FilterOption[] = [];

  constructor(
    private formBuilder:FormBuilder,
    public dialog:DialogRef,
    private notifier:NotifierService,
    private translate: TranslateService
  ){
    this.filterForm = this.formBuilder.group({
      field: [null, Validators.required],
      startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    },  { validators: dateRangeValidator } 
  );

    this.fields = DATE_DEFINITIONS.map(dateDef => ({ label: dateDef.label, value: dateDef.value }));

    

    this.filterForm.get('field')?.valueChanges.subscribe((field: { label: string; value: string })=>{
      this.updateOperatorInputConfig(field);
    })

    this.filterForm.statusChanges.subscribe(status => {
      if (this.filterForm.hasError('maxDateRange')) {
        this.notifier.notify('warning', this.translate.instant('error.maxDateRange'));
      }
    });

  }

  

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if(event.key === 'Enter'){
      if(this.filterForm.valid){
        this.onFormSubmit();
      }
    }
  }


  private updateOperatorInputConfig(field: { label: string; value: string }) {
    if (field.value === 'select') {
      this.hasOptions = true;
      // Hacer requeridos solo startDate y endDate
      this.filterForm.get('startDate')?.setValidators(Validators.required);
      this.filterForm.get('endDate')?.setValidators(Validators.required);
    } else {
      this.hasOptions = false;
  
      this.filterForm.get('startDate')?.clearValidators();
      this.filterForm.get('endDate')?.clearValidators();
    }
  
    // Aplicar los cambios
    this.filterForm.get('startDate')?.updateValueAndValidity();
    this.filterForm.get('endDate')?.updateValueAndValidity();
  }

  public getLabelFromOptions(value:CompareValueType): string {
    if(this.hasOptions){
      const optionSelected = this.options.find((opt)=>{ return opt.value === value});
      return optionSelected?.label ?? `${value}`;
    }
    return `${value}`;
  }

  public onFormSubmit(){
    const { field, startDate, endDate } = this.filterForm.value;
    console.log(this.filterForm.value, field)
    let display = null;
    let display2 = null;
    let result = null;
    if(field.value == 'select'){
      result = this.getCustomDateFilter(startDate, endDate)
      display = new FilterDisplay("filter.dateLabel","operator.min", this.formatDate(startDate))
      display2 = new FilterDisplay("filter.dateLabel","operator.max", this.formatDate(endDate))
    }else{
      result = this.getDateFilter(field.value)
      display = new FilterDisplay("filter.dateLabel","operator.min", this.formatDate(result.start.value)) // trasnformar la fecha 
      display2 = new FilterDisplay("filter.dateLabel","operator.max", this.formatDate(result.end.value)) // trasnformar la fe))
    }


    const appliedFilter: AppliedFilter[] = [];
    appliedFilter.push(new AppliedFilter(display,result.start))
    appliedFilter.push(new AppliedFilter(display2,result.end))

    this.dialog.close(appliedFilter);
  }

 

   getDateFilter(label: string): { start: DateFilterData; end: DateFilterData } {
    const now = new Date();
    let startDate: Date;
    let endDate: Date;
  
    switch (label) {
      case 'today':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        endDate = new Date(startDate);
        endDate.setHours(23, 59, 59, 999);
        break;
  
      case 'yesterday':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
        endDate = new Date(startDate);
        endDate.setHours(23, 59, 59, 999);
        break;
  
      case 'this_week':
        const weekStart = new Date(now);
        weekStart.setDate(now.getDate() - now.getDay());
        startDate = new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate());
        endDate = new Date(now);
        endDate.setHours(23, 59, 59, 999);
        break;
  
      case 'last_week':
        const lastWeekStart = new Date(now);
        lastWeekStart.setDate(now.getDate() - now.getDay() - 7);
        startDate = new Date(lastWeekStart.getFullYear(), lastWeekStart.getMonth(), lastWeekStart.getDate());
        const lastWeekEnd = new Date(startDate);
        lastWeekEnd.setDate(startDate.getDate() + 6);
        endDate = new Date(lastWeekEnd);
        endDate.setHours(23, 59, 59, 999);
        break;
  
      case 'this_month':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
  
      case 'last_month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999);
        break;
  
      case 'this_year':
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = new Date(now.getFullYear(), 11, 31);
        endDate.setHours(23, 59, 59, 999);
        break;
  
      case 'last_year':
        startDate = new Date(now.getFullYear() - 1, 0, 1);
        endDate = new Date(now.getFullYear() - 1, 11, 31);
        endDate.setHours(23, 59, 59, 999);
        break;
  
      default:
        throw new Error('Invalid label provided');
    }
  
    return {
      start: new DateFilterData(label, 'GREATER_THAN_OR_EQUAL', startDate, 'date'),
      end: new DateFilterData(label, 'LESS_THAN_OR_EQUAL', endDate, 'date'),
    };
  }

  getCustomDateFilter(startDate: Date, endDate: Date): { start: DateFilterData; end: DateFilterData } {
    if (!startDate || !endDate) {
      throw new Error('Both startDate and endDate are required');
    }
  
    // Normalizamos la fecha de inicio (00:00:00.000)
    const normalizedStartDate = new Date(startDate);
    normalizedStartDate.setHours(0, 0, 0, 0);
  
    // Normalizamos la fecha de fin (23:59:59.999)
    const normalizedEndDate = new Date(endDate);
    normalizedEndDate.setHours(23, 59, 59, 999);
  
    return {
      start: new DateFilterData('custom', 'GREATER_THAN_OR_EQUAL', normalizedStartDate, 'date'),
      end: new DateFilterData('custom', 'LESS_THAN_OR_EQUAL', normalizedEndDate, 'date'),
    };
  }
  formatDate(date: Date | string | number | any): string {
    if (!date) return '';

    // Si la fecha es un número (timestamp), convertirla a Date
    const d = typeof date === 'number' ? new Date(date) : new Date(date);

    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Meses en JavaScript van de 0 a 11
    const year = d.getFullYear();

    return `${day}-${month}-${year}`;
}



}

export const dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const startDate = control.get('startDate')?.value;
  const endDate = control.get('endDate')?.value;

  if (!startDate || !endDate) {
    return null; // No validar si las fechas no están completas
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calcula la diferencia en años y meses
  const diffYears = end.getFullYear() - start.getFullYear();
  const diffMonths = end.getMonth() - start.getMonth();
  const totalMonthsDiff = diffYears * 12 + diffMonths;

  return totalMonthsDiff > 3 ? { maxDateRange: true } : null;
};