<div class="edit-panel">
  <div class="header">
    <div class="title">{{ app.name }}</div>
    <div class="action-buttons">
      <ng-container *ngIf="rol.canByInput(rol.functIds.edit_applications) && !readOnly">
        <button class="ct-button ct-button--primary" (click)="onSave()" [disabled]="firstFormGroup.pristine || firstFormGroup.invalid">
          {{ 'editPanel.save' | translate }}
        </button>
      </ng-container>
      <button class="ct-button ct-button--tertiary" (click)="onCancel()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
      </button>
    </div>
  </div>

  <!-- Formulario principal -->
  <form [formGroup]="firstFormGroup">
    <!-- Details Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('details')">
        <label class="title-small">{{ 'editPanel.details' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['details'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content" *ngIf="isOpen['details']">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'editPanel.name'| translate }}</mat-label>             
            <input matInput formControlName="name" placeholder="{{ 'editPanel.namePlaceholder' | translate }}" />
            <mat-error *ngIf="firstFormGroup.get('name')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
            <mat-error *ngIf="firstFormGroup.get('name')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
           <mat-form-field appearance="outline">
            <mat-label>{{ 'editPanel.packageName'| translate }}</mat-label>   
            <input matInput formControlName="packageName" placeholder="{{ 'editPanel.packagePlaceholder' | translate }}" />
            <mat-error *ngIf="firstFormGroup.get('packageName')?.hasError('required')">{{ 'error.required.field' |
              translate }}</mat-error>
          </mat-form-field>
        </div>

        <!-- selector de etiquetas -->
        <div class="form-group">
          <app-tags-selector formControlName="tags" [hierarchy]="firstFormGroup.get('hierarchy')"></app-tags-selector>
        </div>

        <div class="form-group">

          <div class="checkbox-group-container">
            <div class="checkbox-group horizontal">
                <div class="checkbox-icons-row">
                    <label>{{ 'newApp.subtitle' | translate }}</label>
                </div>
              <div class="checkbox-icons-row">
                <div class="checkbox-item" [class.disabled]="isDisabled('isNotIntegrated')">
                  <mat-checkbox formControlName="isNotIntegrated" ></mat-checkbox>
                  <span>{{ 'newApp.isNotIntegrated' | translate }}</span>
                  <button mat-icon-button class="mat-icon-button">
                    <mat-icon class="mx-1 info-icon" matTooltip="{{ 'newApp.isNotIntegratedPlaceholder' | translate }}">info</mat-icon>
                </button>
                </div>
                <div class="checkbox-item">
                  <mat-checkbox formControlName="isNotGoverned"></mat-checkbox>
                  <span>{{ 'newApp.isNotGoverned' | translate }}</span>
                  <button mat-icon-button class="mat-icon-button">
                    <mat-icon class="mx-1 info-icon" matTooltip="{{ 'newApp.isNotGovernedPlaceholder' | translate }}">info</mat-icon>
                </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </fieldset>

 <!-- Versiones Section -->
 <fieldset class="edit-container bordered">
  <legend class="details-header" (click)="toggleSection('versiones')">
    <div class="d-flex">
      <label class="title-small" style="margin-right: 15px;">{{ 'editPanel.versions' | translate }}</label>
      <!-- Botón para agregar nueva versión -->
      <div *ngIf="!readOnly">
        <button class="ct-button ct-button--secondary" (click)="addVersion($event)">{{ 'editPanel.addNewVersion' | translate }}</button>
      </div>  
    </div>
    <mat-icon class="toggle-icon">{{ isOpen['versiones'] ? 'expand_less' : 'expand_more' }}</mat-icon>
  </legend>

  <div class="edit-content" *ngIf="isOpen['versiones']">
    <!-- Lista de versiones con scroll -->
    <div class="versions-list">
          <ng-container *ngFor="let version of versions">
            <div class="block">
              <div class="block-header">
                <div class="block-content-row">
                  <div class="block-content-item">
                    <a class="reditect-tag" (click)="downloadVersion(version)">{{ version.version }}</a>
                  </div>
                  <div class="block-content-item">
                    <p class="disabled-text">{{ 'editPanel.registrationDate' | translate }}: {{ version.dateCreate?.toString() | dateOnTimeZone:true }}</p>
                  </div>
                </div>
      
                <div class="block-actions">
                  <ng-container>                 
                    <button mat-icon-button (click)="downloadVersion(version)">
                      <mat-icon svgIcon="download-01"></mat-icon>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="rol.canByInput(rol.functIds.delete_applications) && !readOnly">
                    <button mat-icon-button class="delete-icon" (click)="removeVersion(version.id)">
                      <mat-icon svgIcon="trash-01"></mat-icon>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="!readOnly">
                    <button mat-icon-button (click)="editVersion(version.id)">
                      <mat-icon svgIcon="chevron-right"></mat-icon>
                    </button>
                  </ng-container>
                </div>
              </div>
        
              <div class="block-content">
                <div class="block-content-row">
                  <div class="block-content-item">
                    <label class="text-xs">{{ 'common.factory' | translate }}</label>
                    {{ version.manufacID}}<ng-container *ngIf="version.modelNumber"> - {{ version.modelNumber }}</ng-container> 
                 </div>
                  
                  <div class="block-content-item" >
                    <label class="text-xs">{{ 'editPanel.fileName' | translate }}</label>
                    {{ version.fileName}}
                  </div>
                  
                  <div class="block-content-item" >
                    <label class="text-xs">{{ 'editPanel.description' | translate }}</label>
                    {{ version.description || ('common.noDescription' | translate) }}
                   </div>
                </div>
              </div>
            </div>

          </ng-container>
    </div>
  </div>
</fieldset>

    <!-- Hierarchy Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('hierarchy')">
        <label class="title-small">{{ 'editPanel.hierarchy' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['hierarchy'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      
      <div class="edit-content" *ngIf="isOpen['hierarchy']">
        <div class="row">
          <ng-template #AllTemplate><p>{{ 'common.all-f' | translate }}</p></ng-template>
          <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
              <div class="info-block">
                  <label>{{ 'information.organizationField' | translate }}</label>
                  <ng-container *ngIf="app.idOperator!== undefined;else AllTemplate">
                    <p>{{ app.idOperator | levelName:'LEVEL_1' }}</p>
                  </ng-container>
              </div>
          </div>
          <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_2)">
              <div class="info-block">
                  <label>{{ 'information.divisionField' | translate }}</label>
                  <ng-container *ngIf="app.idClient!== undefined;else AllTemplate">
                    <p>{{ app.idClient | levelName:'LEVEL_2' }}</p>
                  </ng-container>
              </div>
          </div>
          <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_3)">
              <div class="info-block">
                  <label>{{ 'information.regionField' | translate }}</label>
                  <ng-container *ngIf="app.idSubClient!== undefined;else AllTemplate">
                    <p>{{ app.idSubClient | levelName:'LEVEL_3' }}</p>
                  </ng-container>
              </div>
          </div>
        </div>
      </div>
    </fieldset>

    <!-- Dates Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('dates')">
        <label class="title-small">{{ 'editPanel.registrationDate' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['dates'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content row" *ngIf="isOpen['dates']">
        <div class="form-group col col-lg-6">
           <mat-form-field appearance="outline">            
            <mat-label>{{ 'editPanel.registrationDate'| translate }}</mat-label>   
            <mat-icon matPrefix>calendar_month</mat-icon>
            <input matInput formControlName="dateCreate" type="text"
              placeholder="{{ 'editPanel.registrationDate' | translate }}" />
          </mat-form-field>
        </div>

        <div class="form-group col col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'editPanel.modificationDate'| translate }}</mat-label>   
            <mat-icon matPrefix>calendar_month</mat-icon>
            <input matInput formControlName="dateUpdate" type="text"
              placeholder="{{ 'editPanel.modificationDate' | translate }}" />
          </mat-form-field>
        </div>
      </div>
    </fieldset>
  </form>


   

  <div class="footer" *ngIf="rol.canByInput(rol.functIds.delete_applications) && !readOnly">
    <button class="ct-button ct-button--secondary" (click)="onDelete()">
      {{ 'editPanel.deleteVersion' | translate }}
      <mat-icon class="ct-icon red" svgIcon="trash-01"></mat-icon>
    </button>
  </div>
</div>