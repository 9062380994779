import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';

@Pipe({
  standalone:true,
  name: 'jerarquiaLevelTranslate'
})
export class JerarquiaLevelTranslatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  private authService  = inject(AuthService);
  private lang = this.authService.language.toUpperCase();
  transform(value: string, level: number): string {
    let userSession = this.authService.currentUserValue();
    let operator = userSession.operator;
    let labelLevel: string | undefined;
    let porDefecto: string = "";

      // Verificar el nivel y asignar el correspondiente label
    if (level === 2) {
      labelLevel = operator?.labelLevel2;    
      porDefecto=this.translate.instant(`hierarchy.level_2`)
    } else if (level === 3) {
      labelLevel = operator?.labelLevel3;
      porDefecto=this.translate.instant(`hierarchy.level_3`)
    } else if (level === 4) {
      labelLevel = operator?.labelLevel4;
      porDefecto=this.translate.instant(`hierarchy.level_4`)
    } else if (level === 5) {
      labelLevel = operator?.labelLevel5;
      porDefecto=this.translate.instant(`hierarchy.level_5`)
    }

    if (labelLevel) {
      try {
        // Parsear el JSON
        const labelObj = JSON.parse(labelLevel);
        console.log("labelObj",labelObj);
        // Verificar si la clave del idioma (lang) existe en el objeto
        if (labelObj && labelObj[this.lang]) {
          return labelObj[this.lang]; // Devolver la traducción según el idioma
          
        } else { 
          return porDefecto; // Si no hay traducción para el idioma, devolver cadena vacía
        }
      } catch (error) {
        console.error("Error parsing label JSON:", error);
        return porDefecto; // Si hay un error al parsear el JSON, devolver cadena vacía
      }
    }
   
    return porDefecto;
  }

}
