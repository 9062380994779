import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Aplications } from 'src/app/models/aplications.model';
import { Parameters } from 'src/app/models/parameters.model';
import { PerfilApp } from 'src/app/models/perfilApp.model';
import { EditAppComponent } from '../../aplications/edit-app/edit-app.component';
import { EditParamComponent } from '../../param/edit-param/edit-param.component';
import { ListRelatedExtraComponent } from '../list-related-extra/list-related-extra.component';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateFromTerminalPipe } from 'src/app/pages/comun/pipes/date-from-terminal.pipe';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { ListRelatedNoGovernedComponent } from '../list-related-no-governed/list-related-no-governed.component';

@Component({
  standalone:true,
  imports:[
    CommonModule, 
    MatIconModule, 
    MatLegacyButtonModule,
    MatIconModule,
    TranslateModule,  
    TableEmptyStateComponent,
    DateFromTerminalPipe,
    DragDropModule
  ],
  selector: 'app-list-related-apps',
  templateUrl: './list-related-apps.component.html',
  styleUrls: ['./list-related-apps.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class ListRelatedAppsComponent implements OnChanges{
  @Input() idProfile?:number;
  @Input('data') listedApps:PerfilApp[] = [];
  @Input() apps:Aplications[] = [];
  @Input() parameters:Parameters[] = [];
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() sort = new EventEmitter();

  relatedApps:PerfilApp[] = []; 

  constructor(
    private dialog: MatLegacyDialog,
    private translate:TranslateService,
    public rol:RolHandlerService,
  ){}

  ngOnChanges(): void {
    this.relatedApps = this.listedApps.map((perfilApp:PerfilApp)=>{
      const app = this.apps.find((app)=>{return app.id === perfilApp.idApp});
      const param = this.parameters.find((param)=>{return param.id === perfilApp.idParam}); 
      const relatedApp:PerfilApp = new PerfilApp();
      relatedApp.mapToPresentation(perfilApp, app, param);
      return relatedApp;
    })
  }

  openParamExtra(relatedApp:PerfilApp){
    this.dialog.open(ListRelatedExtraComponent,{
      data:{ idProfile: this.idProfile, idApp:relatedApp.idApp },
      panelClass: 'custom-modalbox-big'
    })
  }

  openAppDetail(relatedApp:PerfilApp){
    this.dialog.open(EditAppComponent,{
      data:{ app:{ id:relatedApp.idApp, name:relatedApp.appName }, readOnly:true },
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
    })
  }

  openParamDetail(relatedApp:PerfilApp){
    this.dialog.open(EditParamComponent,{
        data:{ param:{ id:relatedApp.idParam, name:relatedApp.paramName }, readOnly:true },
        backdropClass: 'custom-edit-panel-backdrop',
        panelClass: 'edit-panel',
    });
  }

  deleteItem(relatedApp:PerfilApp){
    // Confirmar y enviar los datos al backend
    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.application; 
    dialogData.action = ElementAction.delete_femenino;
    dialogData.class = DialogClass.error;
    dialogData.extraInfo = this.translate.instant("related-apps.deleteInfoExtra");
    dialogData.icon = 'trash-01';

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
        width: '35%', panelClass: 'custom-modalbox',
        data: dialogData
    });

    confirmSave.afterClosed().subscribe(async (response: ConfirmDialogActions) => {
        if (response === ConfirmDialogActions.CONFIRM) this.delete.emit(relatedApp.id);
    });
  }

  editItem(relatedApp:PerfilApp){
    this.edit.emit(relatedApp);
  }

    public isGoverned(app:PerfilApp) {
       this.dialog.open(ListRelatedNoGovernedComponent, {
        width: '700px',
        panelClass: 'custom-modalbox-big',
        data: { profile: this.idProfile, packageName: app.appPakage , version: app.appVersion }
      });
    }
  dropItem(event: CdkDragDrop<PerfilApp[]>) {
    console.log('dropItem', event.previousIndex, event.currentIndex);
    if(event.previousIndex!==event.currentIndex){
      moveItemInArray(this.relatedApps, event.previousIndex, event.currentIndex);
      this.relatedApps.forEach((item, index)=> item.orden = index);
      this.sort.emit(this.relatedApps);
    }
  }
}
