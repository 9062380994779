
export class VariablesAtributo {

  	id!: number | undefined;
	idOperator!: number | undefined;
	//padre!: string;
	//hijo!: string;
	//traductor!: string;


	atributo!: string;
	ES!: string;
	EN!: string;

	nivel!: number;
	
}



