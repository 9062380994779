import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ConsoleRequest } from 'src/app/models/console.request.model';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { VariablesReport } from 'src/app/models/variablesReport.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class CustomVariablesReportService {

  constructor(private merchantRest : RestService,private authService:AuthService) {}

    async getListVariablesReport(){
         const _body = new ConsoleRequest;
         let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'getListVariablesReport/', true, true)
         .catch(err => {
           return null
         })
         return value;
     }
  /*
  async find(_body: VariablesReport){
    let userSession = this.authService.currentUserValue();
    if(userSession.operator) _body.idOperator=userSession.operator?.id;
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getVariablesReport/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


  async countTotal(_body:VariablesReport) {
    const { filterAndPagBean } = _body;
    _body.filterAndPagBean=new FilterAndPagBean(filterAndPagBean?.orderSentido, filterAndPagBean?.order, filterAndPagBean?.filterGeneric,null,null,null);

    let userSession = this.authService.currentUserValue();
    if(userSession.operator) _body.idOperator=userSession.operator?.id;
  
    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'countMisAlertas/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }*/


  
  async addUpdate(_body:VariablesReport){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'addVariablesReport/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async delete(id:number){
    let value = await this.merchantRest.commonRestCallConsolaRest({ id },'deleteVariablesReport/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

}