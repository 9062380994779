import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { FUNCTION_IDS, LEVEL_ROL, NAVIGATE_IDS } from "../util/constants";

@Injectable({
    providedIn:'root'
})
export class RolHandlerService {
    functIds = FUNCTION_IDS;
    
    constructor(private readonly  _auth:AuthService){}
    
    /**
     * Este método devuelve si usuario loggeado posee rol de ADMIN
     * @returns boolean
     */
    public isAdmin(): boolean {
        const userSession = this._auth.currentUserValue();
        return userSession?.rol !== undefined && userSession.rol.id === LEVEL_ROL.ADMIN;
    }
    
    /**
     * @param tag
     * Este método devuelve si usuario loggeado puede acceder a una funcionalidad
     * @returns boolean
     */
    canByInput(tag:FUNCTION_IDS): boolean {
        if(this.isAdmin()) return true;
        const userSession = this._auth.currentUserValue();
        const rolPermissions = userSession.activeFuncion;
        return rolPermissions ? rolPermissions[tag] : false; 
    }

    /**
     * @param tag
     * Este método devuelve si usuario loggeado puede acceder a una funcionalidad, 
     * basado en varias function_ids.
     * @returns boolean
     */
    canByInputs(tags:FUNCTION_IDS[]): boolean {
        if(this.isAdmin()) return true;
        const userSession = this._auth.currentUserValue();
        const rolPermissions = userSession.activeFuncion;
        let enableItems = false;
        tags.forEach((tag)=>{
            const rolPermission = rolPermissions ? rolPermissions[tag] : false;
            if(rolPermission) enableItems = true; 
        })
        return enableItems;
    }

    /**
     * 
     * Este método devuelve la url a la que debería entrar un usuario cuando inicia sesión.
     * @returns string - url
     */
    getPathByPermissions(): string {
        let url = sessionStorage.getItem('url');



        if(url && url!=="/pages-login" && url!=="/welcome"){
            return url;
        }else{  
            
            if(this.isAdmin()) return NAVIGATE_IDS[FUNCTION_IDS.view_terminals];

            const userSession = this._auth.currentUserValue();
            const rolPermissions = userSession.activeFuncion;
            if(rolPermissions){
                for (let permission in rolPermissions){
                    const path = NAVIGATE_IDS[Number(permission)];    
                    if(path) return path;
                }
            }
        }

        return NAVIGATE_IDS[FUNCTION_IDS.view_terminals]       
    }

}
