<ng-container>
    <div class="card-terminals" *ngIf="!hideButtons">
        <div class="file-upload-container">
            <label class="upload-label">{{ 'profiles.selectLabel' | translate }}</label>
            <!-- Buttons to trigger file input -->
            <div class="file-upload-triggers">
                <button class="ct-button ct-button--secondary w-100" (click)="openDropZone()">
                    {{ 'common.uploadFiles' | translate }}
                    <ng-container *ngIf="showDropZone || hasUploadAFile">
                        <mat-icon svgIcon="check"></mat-icon>
                    </ng-container>
                </button>
                <button class="ct-button ct-button--secondary w-100" (click)="openSelectMachine()">
                    {{ 'profiles.selectFromPortal' | translate }}
                    <ng-container *ngIf="hasSelectedFromPortal">
                        <mat-icon svgIcon="check"></mat-icon>
                    </ng-container>
                </button>
            </div>
        </div>
    </div>

    <!-- Drag-and-drop zone -->
    <div class="mx-4" *ngIf="fixDropZone || showDropZone">
        <div class="d-flex align-items-center">
            <label class="upload-label">{{ 'common.uploadFiles' | translate }}</label>
            <button mat-icon-button (click)="downloadExampleFile()">
                <mat-icon class="mx-1 info-icon" matTooltip="{{ 'tooltips.terminalsFileFormat' | translate }}">info</mat-icon>
            </button>
        </div>
        
        <app-input-files [allowedExtensions]="['csv']" (onChanges)="onFilesSelected($event)"></app-input-files>
    </div>
    
    <div class="mx-4 mt-3" *ngIf="selectedFile">
        <app-file-display [invalid]="inputInvalid" [fileName]="selectedFile.name" (delete)="removeSelected()"></app-file-display>
    </div>

    <div class="card-terminals-info card-terminals-info--valid" *ngIf="machines.length && !invalidRows.length && !invalidTerminals.length">
        <p>{{ 'profiles.selectedTerminals' | translate }}: {{ machines.length }}</p>
    </div>

    <div class="card-terminals-info card-terminals-info--invalid" *ngIf="invalidRows.length">
        <ng-container *ngFor="let item of invalidRows">
            <p> La fila {{ item }} no tiene un formato correcto </p>
        </ng-container>
    </div>

    <div  class="card-terminals-info card-terminals-info--invalid" *ngIf="invalidTerminals.length">
        <ng-container *ngFor="let item of invalidTerminals">
            <p>{{ 'warning.importValidFactory' | translate:item }}</p>
        </ng-container>
    </div>

    <div  class="card-terminals-info card-terminals-info--invalid" *ngIf="invalidFactories.length">
        <ng-container *ngFor="let item of invalidFactories">
            <p> {{ 'warning.importUnrelatedFactory' | translate:{value:item} }}</p>
        </ng-container>
    </div>
    
</ng-container>