import { FilterAndPagBean } from "./FilterAndPagBean";

export class AlertCongif {

  	id!: number;
	idVM!: number | undefined;
	idOperator!: number | undefined;
	idClient?: number;
	idSubClient?: number;
	dateCreate!: Date;

	typeAlert!: number;
	priority!: number;
	numProduct!: number;
	value!: number;
	operation!: string;
	filterAndPagBean: FilterAndPagBean | undefined;
	infoAdicional!: string;

}



