import { VariablesReport } from "../models/variablesReport.model";

export function searchVariableReport(list: VariablesReport[], padre: string, hijo: string | null, lang:  string | undefined): string  { 
    let foundItem: VariablesReport | undefined;
    let result = "";
    if (padre) {  
        if (!hijo) {  
            result = padre;  
            foundItem = list.find(item => item.padre &&  item.hijo == null &&  item.padre.toLowerCase().includes(padre.toLowerCase())); 
        } else {  
            result = hijo;  
            foundItem = list.find(item => item.hijo && item.padre && item.hijo.toLowerCase().includes(hijo.toLowerCase()) && item.padre.toLowerCase().includes(padre.toLowerCase()));  
        }  
    }
    if (foundItem?.traductor && lang) {
        try {
            // Parsear el string 'traductor' a un objeto            
            const traductorObj = JSON.parse(foundItem.traductor);
            if (traductorObj?.hasOwnProperty(lang)) {
                return traductorObj[lang] ;
            }
        } catch (error) {
            console.error("Error parsing traductor JSON:", error);
            return "";
        }
    }
     return result; 
}
