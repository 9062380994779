<div mat-dialog-title class="ct-dialog-header">
    <span class="header-large">{{ 'newApp.title' | translate }}</span>
    
    <button class="ct-button ct-button--tertiary" (click)="onClose()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>
</div>


<mat-dialog-content class="ct-dialog-content ct-dialog-content__extended p-0">
    <mat-stepper [linear]="isLinear" #stepper>
        <!-- Paso 1: Detalles -->
        <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>{{ 'newApp.step1' | translate }}</ng-template>

                <div class="details-container">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'newApp.name' | translate }} <span class="obligatorio"></span></mat-label>
                            <input matInput formControlName="name"
                                placeholder="{{ 'newApp.namePlaceholder' | translate }}" required>
                            <mat-error *ngIf="firstFormGroup.get('name')?.hasError('required')">{{
                                'error.required.field' | translate }}</mat-error>
                            <mat-error *ngIf="firstFormGroup.get('name')?.hasError('maxlength')">{{ 'error.maxLength' |
                                translate }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="row">
                        <!-- Hierarchy Form-->
                        <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
                    </div>

                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newApp.description' | translate }} <span class="obligatorio"></span></mat-label>
                        <textarea matInput formControlName="description" rows="4" maxlength="200"
                            placeholder="{{ 'newApp.descriptionPlaceholder' | translate }}" cdkTextareaAutosize
                            cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"></textarea>
                        <mat-error *ngIf="firstFormGroup.get('description')?.hasError('required')"> {{
                            'error.required.field' | translate }}</mat-error>
                        <mat-hint align="end">{{ firstFormGroup.get('description')?.value?.length || 0 }} /
                            200</mat-hint>
                    </mat-form-field>
                </div>

                <!-- selector de etiquetas -->
                <div class="form-group">
                    <app-tags-selector formControlName="tags"
                        [hierarchy]="firstFormGroup.get('hierarchy')"></app-tags-selector>
                </div>

                <div class="button-group">
                    <button class="ct-button ct-button--secondary" (click)="onClose()">{{ 'common.cancel' | translate }}</button>
                    <button class="ct-button ct-button--primary" matStepperNext (click)="validateInput()">{{ 'common.next' | translate }}</button>
                </div>
            </form>
        </mat-step>

        <!-- Paso 2: Archivos -->
        <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>{{ 'newApp.step2' | translate }}</ng-template>
                <div class="terminals-container">
                    <!-- Zona de carga de archivos -->
                    <div class="file-upload-container mb-3">
                        <app-input-files class="w-100" [invalid]="fileUploadError && !selectedFile" (onChanges)="onFileSelected($event)"></app-input-files>
                    </div>

                    <!-- Nombre del archivo seleccionado y botón de eliminar -->
                    <div *ngIf="selectedFile" class="mb-3">
                        <app-file-display [fileName]="selectedFile.name" (delete)="deleteFile()"></app-file-display>
                    </div>

                    <!-- Campo "Versión" -->
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'newApp.version' | translate }}</mat-label>
                            <input matInput formControlName="version"
                                placeholder="{{ 'newApp.versionPlaceholder' | translate }}" required />
                            <mat-error *ngIf="secondFormGroup.get('version')?.hasError('required')">
                                {{'error.required.field' | translate }}</mat-error>
                            <mat-error *ngIf="secondFormGroup.get('version')?.hasError('maxlength')">{{
                                'error.maxLength' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'newApp.packageName' | translate }} </mat-label>
                            <input matInput formControlName="packageName"
                                placeholder="{{ 'newApp.packagePlaceholder' | translate }}" required>
                            <mat-error *ngIf="secondFormGroup.get('packageName')?.hasError('required')">{{
                                'error.required.field' | translate }}</mat-error>
                            <mat-error *ngIf="secondFormGroup.get('packageName')?.hasError('maxlength')">{{
                                'error.maxLength' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>


                <div class="form-group-inline">
                    <div class="form-group">
                        <filterable-select #manuFacSelect formControlName="manufacID" [title]="'common.factory' | translate" 
                            [options]="factories.list" valueField="name" displayField="name"  [required]="true"
                        ></filterable-select> 
                    </div>
                    <div class="form-group">
                        <filterable-select formControlName="modelNumber" [title]="'common.terminalModel' | translate" 
                            [options]="terminalModels" valueField="name" displayField="name" ></filterable-select> 
                    </div>
                </div>


                <div class="checkbox-group-container">
                    <div class="checkbox-group horizontal">
                        <div class="checkbox-icons-row titulo">
                            <label>{{ 'newApp.subtitle' | translate }}</label>
                        </div>
                      <div class="checkbox-icons-row">
                        <div class="checkbox-item" [class.disabled]="isDisabled('isNotIntegrated')">
                          <mat-checkbox formControlName="isNotIntegrated" ></mat-checkbox>
                          <span>{{ 'newApp.isNotIntegrated' | translate }}</span>
                          <button mat-icon-button class="mat-icon-button">
                            <mat-icon class="mx-1 info-icon" matTooltip="{{ 'newApp.isNotIntegratedPlaceholder' | translate }}">info</mat-icon>
                        </button>
                        </div>
                        <div class="checkbox-item">
                          <mat-checkbox formControlName="isNotGoverned"></mat-checkbox>
                          <span>{{ 'newApp.isNotGoverned' | translate }}</span>
                          <button mat-icon-button class="mat-icon-button">
                            <mat-icon class="mx-1 info-icon" matTooltip="{{ 'newApp.isNotGovernedPlaceholder' | translate }}">info</mat-icon>
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- Botones para navegación -->
                <div class="button-group">
                    <button class="ct-button ct-button--secondary" matStepperPrevious>{{ 'common.back' | translate
                        }}</button>
                    <button class="ct-button ct-button--primary" (click)="onFormSubmit()">{{ 'common.submit' | translate
                        }}</button>
                </div>
            </form>
        </mat-step>
    </mat-stepper>
</mat-dialog-content>
