
<div [ngClass]="stateClass" [matTooltip]="tooltip" class="coverage-icon">
    <ng-container *ngIf="isActive; else noDataIcon">
        <!-- Íconos de señal -->
         <ng-container *ngIf="hasIcon; else defaultConnectionType">
            <mat-icon [svgIcon]="icon"></mat-icon>
         </ng-container>
         
        <ng-template #defaultConnectionType>
            <span>{{ connectionType }}</span>
        </ng-template>
    </ng-container>
    <ng-template #noDataIcon>
        <!-- Íconos tachados para "Sin datos" -->
        <mat-icon svgIcon="wifi-off"></mat-icon>
    </ng-template>
  </div>