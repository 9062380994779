import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone:true,
  imports:[CommonModule, TranslateModule, MatIconModule, MatButtonModule],
  selector: 'app-filter-trigger',
  templateUrl: './trigger.component.html',
  styleUrls: ['./trigger.component.css']
})
export class TriggerComponent {
  @Output() onClick = new EventEmitter();
  @ViewChild('trigger', { static: false }) triggerElem: ElementRef | undefined;
  @Input() isDateFilter:boolean = false;
  
  public onTrigger(){
    this.onClick.emit(this.triggerElem);
  }
}
