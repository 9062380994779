
import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FilterAndPagBean, FilterableValue, OPERATION_FILTER } from 'src/app/models/FilterAndPagBean';
import { environment } from 'src/environments/environment';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { AppsService } from './aplications.service';
import { Parameters } from 'src/app/models/parameters.model';
import { AuthService } from 'src/app/auth/auth.service';
import { NewAppComponent } from './new-app/new-app.component';
import { Aplications } from 'src/app/models/aplications.model';
import { EditAppComponent } from './edit-app/edit-app.component';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { FormCreateActions } from 'src/app/util/constants';
import { TagsService } from '../tags/tags.service';
import { Tag } from '../tags/tag.model';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { NotifierService } from 'angular-notifier';
import { downloadFile } from 'src/app/util/util';


enum Columns {//en el orden que se quiera mostrar
  name = 'name',
  idOperator = 'idOperator',
  idClient = 'idClient',
  idSubClient = 'idSubClient',
  dateCreate = 'dateCreate',
  dateUpdate = 'dateUpdate',
  pakage = 'pakage',
  tags = 'tags',
  view = 'view'
}

const FILTER_COLUMNS: { field: string, type: FieldType, options?: FilterOption[] }[] = [
  { field: 'name', type: 'string' },
  { field: 'dateCreate', type: 'date' },
  { field: 'dateUpdate', type: 'date' },
]


@Component({
  selector: 'app-aplications',
  templateUrl: './aplications.component.html',
  styleUrls: ['./aplications.component.css'],
  providers: [{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class AplicationsComponent implements OnInit, AfterViewInit {
  displayedColumns = [...Object.keys(Columns)];
  filterColumns: FilterField[] = FILTER_COLUMNS.map((column) => {
    return new FilterField(column.field, `aplications.${column.field}`, column.type, column.options);
  });
  
  exportFileName: string | undefined;
  dataSource = new MatTableDataSource<Aplications>();

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.dateCreate + "";
  direccion: string | null = "DESC";
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  inactiveTerminals = 0;
  isAdmin: boolean = true;

  ///filtros
  filterList: FilterableValue[] = [];


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public aplications: AppsService,
    public dialog: MatDialog, 
    public authService: AuthService,
    public translate: TranslateService,
    public rol: RolHandlerService,
    public hierarchy: HierarchyHandlerService,
    public tagsService:TagsService,
    private notifier:NotifierService,
    public commonService: CommonService) {}

  private exitsFilterableValue(column: string): boolean {
    return this.filterList.some((filter) => filter.column === column);
  }

  async lanzarLLamada() {
    const existIdVM = this.exitsFilterableValue('idVM');
    const existIdOerator = this.exitsFilterableValue('idOperator');
    const existIsLast = this.exitsFilterableValue('isLast');

    if (this.data?.obj && !existIdVM) {
      const idVM = this.data.obj.id;
      this.filterList.push(new FilterableValue("idVM", idVM, "long", OPERATION_FILTER.EQUALS));
    }

    let userSession = this.authService.currentUserValue();
    if (userSession?.operator && !existIdOerator) {
      this.filterList.push(new FilterableValue("idOperator", userSession.operator.id + "", "long", OPERATION_FILTER.EQUALS));
    }

    if (!existIsLast) this.filterList.push(new FilterableValue("isLast", "true", "boolean", OPERATION_FILTER.EQUALS));

    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    const request = new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      startPage,
      endPage,
      pageIndex
    );

    let value = await this.aplications.find(request);
    let pagCount = await this.aplications.countTotal(request);
    
    if (value) {
      this.dataSource = new MatTableDataSource<Aplications>(value.data);
      // Setear el conteo de páginas
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  }
  
  private async loadTags(){
    const request = new FilterAndPagBean(null,null,[],null,null,null);
    const response = await this.tagsService.find(request);
    if(response && response.data){
      this.filterColumns.push(new FilterField('listEtiquetas', 'common.tag', 'long', 
        response.data.map((tag:Tag)=> ({ label: tag.name, value: tag.id }))));
    }
  }

  async ngOnInit(): Promise<void> {
    this.displayedColumns = this.hierarchy.filterColumns(Object.keys(Columns));
    this.hierarchy.getFilterFieldsAsync(this.filterColumns);
    this.exportFileName = this.translate.instant('export.fileApps');
    this.loadTags();
    this.lanzarLLamada();
  }


  ngAfterViewInit() {
    if (this.paginator && this.sort) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  onFilterApply(appliedFilters: AppliedFilter[]) {
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  resetPaginatorState() {
    this.paginator.firstPage();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    this.sortId = sortState.direction ? sortState.active.replace("_", ".") : null;  // Asegura que el sortId sea correcto
    this.direccion = sortState.direction ? sortState.direction.toUpperCase() : null;
    
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  async onNew() {
    const newDialogRef: MatDialogRef<NewAppComponent> = this.dialog.open(NewAppComponent, {
      width: '600px',
      panelClass: 'custom-modalbox-big',
      data: {
        vm: this.data.obj
      }
    });

    newDialogRef.afterClosed().subscribe((result) => {
      if (result === FormCreateActions.SAVED) {
        this.lanzarLLamada(); // Recargar la tabla
      }
    });

  }


  async onEdit(row: Aplications) {
    const editDialogRef: MatDialogRef<EditAppComponent> = this.dialog.open(EditAppComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
      data: { app: row, readOnly: false }
    });

    editDialogRef.afterClosed().subscribe((result) => {
      if (result === FormCreateActions.SAVED) this.lanzarLLamada();
    });
  }


  async onClose(row: Parameters) {
    const dialogRef = this.dialog.open(NewAppComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {
        incidencia: row, vm: this.data.obj, close: true
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === FormCreateActions.SAVED) this.lanzarLLamada();
    });

  }

  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }

   public async exportTable(){
  
      const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
      const startPage = pageIndex * pageSize;
      const endPage = startPage + pageSize;
  
      const filterAndPagBean = new FilterAndPagBean(
        this.direccion,
        this.sortId,
        this.filterList,
        startPage,
        endPage,
        pageIndex
      );
  
      const fileResponse = await this.aplications.findFileToExport(filterAndPagBean);
      if (fileResponse?.byteLength) {
        downloadFile(this.translate.instant('export.fileApps'), fileResponse);
      } else {
        this.notifier.notify('error', this.translate.instant('error.errorOnDownload'));
      }
      
    }


}
