import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Input, SimpleChanges, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-coverage-icon',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatLegacyTooltipModule, MatIconModule],
  templateUrl: './coverage-icon.component.html',
  styleUrls: ['./coverage-icon.component.css']
})
export class CoverageIconComponent implements AfterViewInit {
  @Input() level:number = 0;
  @Input() type:string = '';
  @Input() connectionType:string = '';
  @Input() isInactive?:boolean = false;

  translate = inject(TranslateService);

  typeIconTemplate: TemplateRef<any> | null = null;
  isActive:boolean = false

  ngAfterViewInit(): void {
    this.isActive = this.getIsActive();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['level'] || changes['type'] || changes['isInactive'] || changes['connectionType']) {
        this.isActive = this.getIsActive();
    }
  }

  getIsActive():boolean{
    return (!this.isInactive && !!this.level);
  }


  get stateClass(): string {
    if (!this.isActive) {
      return 'no-coverage';
    } else if (this.level >= 75) {
      return 'high-coverage';
    } else if (this.level >= 30) {
      return 'medium-coverage';
    } else {
      return 'low-coverage';
    }
  }

  private getCoverageType(): string {
    if(this.type) return this.type.toLowerCase()+'-';
    return '';
  }

  private getConnectionType(): string {
    if(this.connectionType === 'WIFI') return 'wifi';
    if(this.connectionType === 'GPRS') return 'signal-coverage';
    return '';
  }

  private getWifiLevel(): string {
    if (this.level >= 75) return '3';
    if (this.level >= 30) return '2';
    return '1';
  }

  private getSignalLevel(): string {
    if (this.level >= 100) return '5';
    if (this.level >= 60) return '4';
    if (this.level >= 30) return '3';
    if (this.level > 0) return '2';
    return '1';
  }

  private getCoverageLevel(): string {
    if(this.connectionType === 'WIFI') return this.getWifiLevel();
    return this.getSignalLevel();
  }

  get hasIcon(): boolean {
    if(this.connectionType === 'WIFI' || this.connectionType === 'GPRS') return true;
    return false;
  }

  get icon(): string {
    return `${this.getCoverageType()}${this.getConnectionType()}-${this.getCoverageLevel()}`;
  }

  get tooltip(): string {
    return this.isActive ? `${this.connectionType ?? ''} ${this.type ?? ''} ${this.level}%` : this.translate.instant('common.noData');
  }
}
