<main id="main" class="main">
  <div class="my-2 d-flex justify-content-between">
    <span class="header-large">{{ 'pendingTasks.title' | translate }}</span>   
    
    <!-- <button class="ct-button ct-button--secondary" (click)="refrescar()">
        <mat-icon class="ct-icon" svgIcon="refresh-ccw-01"></mat-icon> 
        <span class="hide-on-mobile">{{'common.refresh' | translate }}</span>
    </button> -->
  </div>
  
  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" hidden>{{ 'pendingTasks.tableDescription' | translate }}</div>

  <div class="mat-elevation-z2 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort (matSortChange)="announceSortChange($event)" 
      matTableExporter #exporter="matTableExporter" [hiddenColumns]="getNotExportColumns()">
      <!-- serialNumber Column -->
      <ng-container matColumnDef="vm_serialNumber">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div>{{ 'pendingTasks.serialNumber' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.vm.serialNumber}}"> {{element.vm.serialNumber}} </td>
      </ng-container> 

      <!-- manufacID Column -->
      <ng-container matColumnDef="vm_manufacID">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div>{{ 'common.factory' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.vm.manufacID}}"> {{element.vm.manufacID}} </td>
      </ng-container> 

      <!-- modelNumber Column -->
      <ng-container matColumnDef="vm_modelNumber">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max" ><div>{{ 'common.terminalModel' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.vm.modelNumber}}"> {{element.vm.modelNumber}} </td>
      </ng-container> 

      <!-- operator Column -->
      <ng-container matColumnDef="idOperator">
        <th mat-header-cell *matHeaderCellDef><div >{{ 'pendingTasks.operator' | translate }}</div></th>
        <td mat-cell *matCellDef="let element"> {{ element.idOperator | levelName:'LEVEL_1' }} </td>
      </ng-container>
      <!-- typeSend Column -->
      <ng-container matColumnDef="typeSend">
        <th mat-header-cell *matHeaderCellDef ><div mat-sort-header>{{ 'pendingTasks.typeSend' | translate }}</div></th>       
        <td mat-cell  *matCellDef="let element" > 
          <span>{{ 'pendingTasksTypes.'+element.typeSend | translate}}</span>
        </td>
      </ng-container>
      <!-- sendJson Column -->
      <ng-container matColumnDef="sendJson">
          <th mat-header-cell *matHeaderCellDef  appResizableColumn><div mat-sort-header>{{ 'pendingTasks.sendJson' | translate }}</div></th>       
          <td mat-cell *matCellDef="let element"  [matTooltip]="getTooltip(element)"> 
            <div class="d-flex flex-column">
              <ng-container *ngIf="element.isCommand; else FileConfig">
                <span>{{ 'pendingTasks.command' | translate }}: {{ element.commandType }}</span>
                <span *ngIf="element.packegeName">{{ 'pendingTasks.packageName' | translate }}: {{ element.packegeName }}</span>
              </ng-container>
              
              <ng-template #FileConfig>
                <span *ngIf="element.fileName">{{ 'pendingTasks.fileName' | translate }}: {{ element.fileName ?? '-' }}</span>
                <span *ngIf="element.packegeName">{{ 'pendingTasks.packageName' | translate }}: {{ element.packegeName ?? '-' }}</span>
              </ng-template>
            </div>
          </td>
      </ng-container>
      <!-- dateCreate Column -->
      <ng-container matColumnDef="dateCreate">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.dateCreate' | translate }}</div></th>       
          <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
      </ng-container>
      <!-- retries Column -->
      <ng-container matColumnDef="retries">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.retries' | translate }}</div></th>       
          <td mat-cell *matCellDef="let element"> {{ element.retries }}</td>
      </ng-container>
      <!-- codError Column -->
      <ng-container matColumnDef="codError">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.codError' | translate }}</div></th>       
          <td mat-cell *matCellDef="let element" matTooltip="{{element.errorLabel | translate}} ">
            <span> {{ element.errorLabel | translate }} 
                <span  *ngIf="element.codError">(cod.{{ element.codError }})</span>
            </span>
          </td>
      </ng-container>
      <!-- setMachine Column -->
      <!--ng-container matColumnDef="setMachine">
          <th mat-header-cell *matHeaderCellDef>
            <div style="text-align: center;">{{ 'pendingTasks.setMachine' | translate }}</div>
          </th>       
          <td mat-cell *matCellDef="let element"  style="text-align: center;"> 
            <ng-container *ngIf="element.setMachine"> 
              <mat-icon matTooltip="{{ 'pendingTasks.setMachine-tooltip' | translate }}"  svgIcon="check-circle-broken"></mat-icon>
            </ng-container>  
          </td>
      </ng-container!-->
      <!-- horaIni Column -->
      <ng-container matColumnDef="dateIni">
        <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.horaIni' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element"> {{ element.dateIni | dateFromTerminal }}</td>
     </ng-container>
      <!-- numBlocks Column -->
      <ng-container matColumnDef="numBlocks">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header>{{ 'pendingTasks.numBlocks' | translate }}</div></th>       
          <td mat-cell *matCellDef="let element">
              <div style="position: relative" matTooltip="{{element.lastBlock ?? 0}} / {{element.numBlocks ?? 0}}">
                  <mat-progress-bar mode="determinate" [value]="element.percentage"></mat-progress-bar>
                  {{ element.percentage }}%
              </div>
          </td>
      </ng-container>
      
      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef><div>{{ 'pendingTasks.status' | translate }}</div></th>       
        <td mat-cell *matCellDef="let element"> 
          <div class="status-indicator">
            <i [ngClass]="element.statusClass" class="status-icon"></i>
            <span>{{ element.statusLabel | translate }}</span>
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="button">
          <div>
            <ng-container *ngIf="element.canReset && !element.setMachine">
              <button matTooltip="{{ 'tooltips.reset' | translate }}" (click)="onReset(element)" mat-icon-button class="iconoTabla">
                <mat-icon>restart_alt</mat-icon>
              </button>  
            </ng-container>
            <ng-container *ngIf="element.idVM && canOpenModal && rol.canByInput(rol.functIds.view_terminals)">
              <button matTooltip="{{ 'tooltips.viewTerminal' | translate }}" (click)="onView(element)" class="ct-button ct-button--tertiary">
                <mat-icon>phone_android</mat-icon>
              </button>       
            </ng-container>
            <ng-container *ngIf="rol.canByInput(rol.functIds.delete_pending_tasks)">
              <button  matTooltip="{{ 'tooltips.delete' | translate }}" (click)="onDelete(element)" class="ct-button ct-button--tertiary">
                <mat-icon class="ct-icon red" svgIcon="trash-01"></mat-icon>
              </button>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
      <app-table-empty-state></app-table-empty-state>
    </ng-container>
 
    <button class="ct-button ct-button--icon" (click)="exportTable()">
      <mat-icon svgIcon="file-download-03"></mat-icon>
    </button>
    
    <mat-paginator [length]="pageLength"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="pageEvent()"
              showFirstLastButtons 
              aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</main>