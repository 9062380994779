import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'; // Usando ngx-translate para las traducciones
import { AuthService } from 'src/app/auth/auth.service';
import { CUSTOM_ALERTS_TYPES } from 'src/app/util/constants';

@Pipe({
  name: 'historicotestPipe'
})
export class HistroicoPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}
   private authService  = inject(AuthService);
   private lang = this.authService.language.toUpperCase();

  transform(value: string, operacion: number): string {
    let texto: string;
    let result = value ? value.split('|') : [];
    let convertir = false;
    console.log("test",value);
    // Verificar si operacion es 46, 85 o 86 para usar typeSend en la clave de traducción
    if ([34, 46, 85, 86].includes(operacion) && result.length > 0) {
        let typeSend = result[0];
        let typeDir_11 = result[2];
        let typeDir_9 = result[1]; // Obtener el primer valor como typeSend
        if(typeSend == "11"){
        texto = this.translate.instant(`historico.operation.${operacion}_${typeSend}_${typeDir_11}_desc`);
          convertir =true;
        }else if(typeSend == "9" && typeDir_9 =="publicDirectory"){
          texto = this.translate.instant(`historico.operation.${operacion}_${typeSend}_${typeDir_9}_desc`);
        }else{
          if(typeSend == "1" || typeSend == "10"){
            convertir =true;
          }
          texto = this.translate.instant(`historico.operation.${operacion}_${typeSend}_desc`);
        }

    } else if (operacion == 53 && result.length > 0) {
      let typeDir = result[1]; // Obtener el primer valor como typeSend
      texto = this.translate.instant(`historico.operation.${operacion}_${typeDir}_desc`);

    }else {
        texto = this.translate.instant(`historico.operation.${operacion}_desc`);
    }

    if(texto == `historico.operation.${operacion}_desc`){
      texto = value;
    }else{
    // Si value contiene '|', dividir en un array
      let result = value.split('|');
      for (let i = 0; i < result.length; i++) {
        let index = i;

        if (operacion == 90 || operacion == 91 || operacion == 57) {
          //90 = alta y 91 =  act 57 borrado
            index = result.length - 1 - i; // Invertir el índice para estos casos
        }
        if ((operacion == 34 || operacion == 46 || operacion == 86 || operacion == 85)) {
          index = i + 1;
        }
        let replacementValue = result[index];
        if (operacion == 31 || operacion == 32) {
            // 31 alta, 32 delete alerta
            replacementValue = this.translate.instant('cutomAlertType.' + CUSTOM_ALERTS_TYPES[replacementValue]?.toLowerCase());
        } else if ((operacion == 53 || operacion == 94) && index == 1) {
              ////0=Indefinido 1=App , 2=Param, 3=ParamExtra
          replacementValue = this.translate.instant(`fileTypes.${replacementValue}`);
        }else if (convertir && (operacion == 34 || operacion == 46 || operacion == 86 || operacion == 85) && index == 2) {
          ////0=Indefinido 1=App , 2=Param, 3=ParamExtra
          replacementValue = this.translate.instant(`fileTypes.${replacementValue}`);
        }

        if(replacementValue == undefined || !replacementValue){
          replacementValue = "";
        }
        texto = texto.replace(`{{${i}}}`, replacementValue);
       
      }
  

      switch(operacion) {
        case 38:
        case 39:
        case 40:
          // Alta= 38, mod = 39, delete = 40  region
          const jerarquiaValue2 = this.formatJerarquia(2);
          texto = texto.replace("{{LEVEL2}}", jerarquiaValue2);
          break;
      
        case 41:
        case 42:
        case 43:
          // Alta= 41, mod = 42, delete = 43  division
          const jerarquiaValue3 = this.formatJerarquia(3);
          texto = texto.replace("{{LEVEL3}}", jerarquiaValue3);
          break;
      
        case 74:
        case 75:
        case 76:
          // Alta= 74, mod = 75, delete = 76  lvl4
          const jerarquiaValue4 = this.formatJerarquia(4);
          texto = texto.replace("{{LEVEL4}}", jerarquiaValue4);
          break;
      
        case 77:
        case 78:
        case 79:
          // Alta= 77, mod = 78, delete = 79  lvl5
          const jerarquiaValue5 = this.formatJerarquia(5);
          texto = texto.replace("{{LEVEL5}}", jerarquiaValue5);
          break;
      
        default:
          // Aquí puedes agregar un caso por defecto si es necesario
          break;
      }
    }
    texto = this.replacePlaceholders(texto);
    // Si value es null o undefined, devolver la traducción sin cambios
    return texto;
  }

  replacePlaceholders(text: string): string {
    if (!text) return ''; // Manejar caso de texto vacío o undefined
    return text.replace(/\{\{\d+\}\}/g, ''); // Reemplaza {{n}} por vacío
  }

  // Función para redondear el número a una cantidad específica de decimales
  private formatJerarquia(level: number): string {
    let userSession = this.authService.currentUserValue();
    let operator = userSession.operator;
    let labelLevel: string | undefined;
    let porDefecto: string = "";

      // Verificar el nivel y asignar el correspondiente label
    if (level === 2) {
      labelLevel = operator?.labelLevel2;    
      porDefecto=this.translate.instant('hierarchy.level_2')
    } else if (level === 3) {
      labelLevel = operator?.labelLevel3;
      porDefecto=this.translate.instant('hierarchy.level_3')
    } else if (level === 4) {
      labelLevel = operator?.labelLevel4;
      porDefecto=this.translate.instant('hierarchy.level_4')
    } else if (level === 5) {
      labelLevel = operator?.labelLevel5;
      porDefecto=this.translate.instant('hierarchy.level_5')
    }

    if (labelLevel) {
      try {
        // Parsear el JSON
        const labelObj = JSON.parse(labelLevel);
        // Verificar si la clave del idioma (lang) existe en el objeto
        if (labelObj && labelObj[this.lang]) {
          return labelObj[this.lang]; // Devolver la traducción según el idioma
          
        } else { 
          return porDefecto; // Si no hay traducción para el idioma, devolver cadena vacía
        }
      } catch (error) {
        console.error("Error parsing label JSON:", error);
        return porDefecto; // Si hay un error al parsear el JSON, devolver cadena vacía
      }
    }
   
    return porDefecto;
  }
}
