import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Users } from 'src/app/models/users.model';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { FormUsersComponent } from './form-users/form-users.component';
import { UsersService } from './users.service';
import { FilterAndPagBean, FilterableValue, OPERATION_FILTER } from 'src/app/models/FilterAndPagBean';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { FormEditUsersComponent } from './form-edit-users/form-edit-users.component';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { RolService } from '../rol/rol.service';
import { Rol } from 'src/app/models/rol.model';
import { downloadFile } from 'src/app/util/util';
import { NotifierService } from 'angular-notifier';

enum Columns {
  nickName = 'nickName',
  name = 'name',
  rol_name = 'rol.name',
  idOperator = 'idOperator',
  idClient = 'idClient',
  idSubClient = 'idSubClient',
  email = 'email',
  status = 'status',
  view = 'view',
}

const FILTER_COLUMNS: { field: string, type: FieldType }[] = [
  { field: 'name', type: 'string' },
  { field: 'email', type: 'string' }, 
]

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class UsersComponent implements OnInit, AfterViewInit {

  displayedColumns = Object.keys(Columns);
  dataSource = new MatTableDataSource<Users>();
  isAdmin: boolean = false;
  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = "id";
  direccion: string | null = "ASC";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  // Filters config
  filterColumns: FilterField[] = FILTER_COLUMNS.map((column) => {
    const fielterField = new FilterField(column.field, `users.${column.field}`, column.type);
    return fielterField;
  })
  filterList: FilterableValue[] = [];
  private dialogRef!: MatDialogRef<FormUsersComponent>;
  private dialogRefEdit!: MatDialogRef<FormEditUsersComponent>;

  ///filtros
  nameFilter = new FormControl();
  userNameFilter = new FormControl();
 // identifierFilter = new FormControl();

  selectedOpe: Operator = new Operator();
  listaOpe: Operator[] = [];
  exportFileName: string | undefined;


  constructor(public dialog: MatDialog,
    public servicios: UsersService,
    public commonService: CommonService,
    public rol:RolHandlerService,
    public hierarchy:HierarchyHandlerService,
    private translate: TranslateService,
    private roleService:RolService,
    private notifier:NotifierService,
    
  ) { }

  async ngOnInit(): Promise<void> {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.exportFileName = this.translate.instant('export.fileUser');
    this.displayedColumns = this.hierarchy.filterColumns(Object.keys(Columns));
    this.hierarchy.getFilterFieldsAsync(this.filterColumns,'operator.id', 'client.id', 'subClient.id');
    this.getRoles();
    this.lanzarLLamada();
  }

  async getRoles(){
    const query = new FilterAndPagBean(null,null,[],null,null,null);
    const response = await this.roleService.find(query);
    if(response?.status === 0){
      const options = response.data.map((item:Rol)=> ({label:item.name, value:item.id}));
      this.filterColumns.push(new FilterField('rol.id', 'users.rolName','long', options));
    }
  }

  private getFilterableValue(column: string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  private removeFilterableValue(column: string) {
    const toRemoveIndex = this.filterList.findIndex((filter) => filter.column === column);
    this.filterList.splice(toRemoveIndex, 1);
  }

  async lanzarLLamada() {
    const request = new UsersConsoleRequest;
    const selectedOperator = this.getFilterableValue("idOperator");
    if (selectedOperator) {
      request.idOperator = selectedOperator.value as number;
      this.removeFilterableValue('idOperator');
    }
    
    if(!this.filterList.some((filter:FilterableValue) => (filter.column === 'dateDelete'))){
      this.filterList.push(new FilterableValue("dateDelete", "", "date", OPERATION_FILTER.IS_NULL));
    }

    request.filterAndPagBean = this.getFilterAndPagBean();

    let value = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);
    //console.log(JSON.stringify(value))
    if (value) {
      this.dataSource = new MatTableDataSource<Users>(value.data);
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  }

  private getFilterAndPagBean(): FilterAndPagBean {
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;
    return new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      startPage,
      endPage,
      pageIndex
    );
  }

  onFilterApply(appliedFilters: AppliedFilter[]) {
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag: PageEvent) {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    console.log("ordenar=" + JSON.stringify(sortState))
    let active = sortState.active.replace("_", ".")
    this.sortId = active;

    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase()
    } else {
      this.direccion = null
      this.sortId = null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  onNew() {
    this.dialogRef = this.dialog.open(FormUsersComponent, {
      width: '700px', panelClass: 'custom-modalbox-big',
      data: {/*vacio al ser nuevo*/ }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  onEdit(row: Users) {
    this.dialogRefEdit = this.dialog.open(FormEditUsersComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
      data: { obj: row }
    });

    this.dialogRefEdit.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }

   public async exportTable(){
      const request = new UsersConsoleRequest();
  
      const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
      const startPage = pageIndex * pageSize;
      const endPage = startPage + pageSize;
  
      request.filterAndPagBean = new FilterAndPagBean(
        this.direccion,
        this.sortId,
        this.filterList,
        startPage,
        endPage,
        pageIndex
      );
  
      const fileResponse = await this.servicios.findFileToExport(request);
      if (fileResponse?.byteLength) {
        downloadFile(this.translate.instant('export.fileUser'), fileResponse);
      } else {
        this.notifier.notify('error', this.translate.instant('error.errorOnDownload'));
      }
      
    }
}
