import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { STORAGE_TYPES, FormCreateActions, LEVEL_HIERARCHY, STORAGE_TYPE, FILE_SIZE_LIMIT_COMPARE_VALUES, FILE_SIZE_LIMIT_LABELS } from 'src/app/util/constants';
import { ParamService } from '../param.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { Parameters } from 'src/app/models/parameters.model';
import { Tag } from '../../tags/tag.model';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { Client, SubClient } from 'src/app/models/client.model';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-new-param',
  templateUrl: './new-param.component.html',
  styleUrls: ['./new-param.component.css']
})
export class NewParamComponent implements OnInit {
  isLinear = true;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  selectedFile: File | null = null;
  isCloseParam: boolean = false;
  listaOpe: Operator[] = [];
  showDropZone = false;
  isAdmin: boolean = false;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  uploadProgress = 0;
  storageTypes = STORAGE_TYPES;
  hierarchyOptions: { value: LEVEL_HIERARCHY, label: string }[] = []
  clientsList: Client[] = [];
  subClientsList: SubClient[] = [];

  fileUploadError:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private service: ParamService,
    public hierarchy: HierarchyHandlerService,
    private utilServicios: UtilService,
    public dialogNewParam: MatDialogRef<NewParamComponent>,
    private translate: TranslateService,
    private notifier: NotifierService,
    private spinnerService:NgxSpinnerService,
    public dialog: MatDialog) {}


  async ngOnInit(): Promise<void> {
    this.isAdmin = this.utilServicios.isAdmin();
    this.firstFormGroup = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(45)])],
      description: ['', Validators.compose([ Validators.maxLength(200)])],
      version: ['', Validators.compose([Validators.required, Validators.maxLength(45)])],
      storageType: [false],
      hierarchy:[{ idOperator:this.hierarchy.level_1, idClient:this.hierarchy.level_2, idSubClient:this.hierarchy.level_3 }],
      tags: ['']
    });

    this.secondFormGroup = this.formBuilder.group({
      uploadFiles: [null, Validators.required],
      fileBase64: ['']
    });
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }

  async onFileSelected(files: File[]): Promise<void> {
    if (files.length > 0) {
      const file = files[0];
      this.deleteFile();
      if(file.size > FILE_SIZE_LIMIT_COMPARE_VALUES.parameters){
        this.notifier.notify('error', this.translate.instant('error.maxFileSize', { fileSize: FILE_SIZE_LIMIT_LABELS.parameters }));
        return;
      }
      this.mapFileToForm(file);
    }
  }

  deleteFile(): void {
    this.selectedFile = null;
    this.secondFormGroup.get('uploadFiles')?.reset();
    this.uploadProgress = 0;
    this.fileUploadError = true; // Mostrar error si no hay archivo
  }


  onFormSubmit(): void {
    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || !this.selectedFile) {
      this.fileUploadError = !this.selectedFile; // Si no hay archivo, mostrar el error
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    const formData = {
      ...this.firstFormGroup.getRawValue(),
      ...this.secondFormGroup.value,
      selectedFile: this.selectedFile?.name,
      fileBase64: this.secondFormGroup.get('fileBase64')?.value

    };

    let paramNew: Parameters = new Parameters
    paramNew.name = formData.name;
    paramNew.description = formData.description;
    paramNew.version = formData.version;
    paramNew.base64 = formData.fileBase64;
    paramNew.fileName = formData.selectedFile;
    paramNew.storageType = formData.storageType ? STORAGE_TYPE.PUBLIC_DIR : STORAGE_TYPE.FILES_DIR;
    paramNew.path = formData.path;
    if (formData.tags && formData.tags.map) {
      paramNew.listEtiquetas = formData.tags.map((tag: Tag) => ({ id: tag.id }));
    }
    
    const { idOperator, idClient, idSubClient } = formData.hierarchy;
    paramNew.idOperator = idOperator;
    paramNew.idClient = idClient;
    paramNew.idSubClient = idSubClient;


    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.parameter; 
    dialogData.action = ElementAction.create_masculino;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.service.addUpdateWithFile(paramNew, formData.uploadFiles);
        if (response!.status >= 0) {
          this.dialogNewParam.close(FormCreateActions.SAVED);
        }
      }
    });
  }

  onClose(): void {
    this.dialogNewParam.close(FormCreateActions.EXIT);
  }



  
    private async mapFileToForm(file: File) {
  
      if (file.size === 0) {
        this.notifier.notify('warning', this.translate.instant('error.fileEmpty'));
        return;
      }
  
      this.selectedFile = file;
  
      this.secondFormGroup.patchValue({
        uploadFiles: file,
      });  
     
      this.secondFormGroup.get('uploadFiles')?.markAsTouched();
    }
  

}
