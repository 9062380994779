import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { CommonService } from 'src/app/services/common.service';
import { Operator } from 'src/app/models/operator.model';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TranslateService } from '@ngx-translate/core';
import { TxtJerarquiaService } from './txtJerarquia.service';
import { FormEditTxtJerComponent } from './form-edit-txt-jer/form-edit-txt-jer.component';
import { VariablesReport } from 'src/app/models/variablesReport.model';
import { VariablesAtributo } from 'src/app/models/variablesAtributo.model';
import { Users } from 'src/app/models/users.model';
import { AuthService } from 'src/app/auth/auth.service';

enum Columns {
  atributo = 'atributo',
  ES = 'ES',
  EN = 'EN',
  view = 'view'
}
@Component({
  selector: 'app-texto-jerarquia',
  templateUrl: './texto-jerarquia.component.html',
  styleUrls: ['./texto-jerarquia.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]

})
export class TextoJerarquiaComponent implements OnInit, AfterViewInit{
  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<VariablesAtributo>();  
  exportFileName: string | undefined;
  userSession?: Users;

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId:string | null = "atributo" ;
  direccion: string | null = "ASC";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  filterList: FilterableValue[] = [];

  constructor(public dialog: MatDialog, 
    public servicios:TxtJerarquiaService,
    public commonService : CommonService,
    public translate:TranslateService,
    private authService:AuthService
  ) {     
  }

  async ngOnInit(): Promise<void> {    
    this.lanzarLLamada();
    this.exportFileName = this.translate.instant('export.fileVariablesReport');

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async lanzarLLamada(){
    const request = this.getFilterAndPagBean();
    let pagCount = 2;
      const atributoLevel2 = new VariablesAtributo();
      const atributoLevel3 = new VariablesAtributo();
      //const atributoLevel4 = new VariablesAtributo();
      //const atributoLevel5 = new VariablesAtributo();

      atributoLevel2.atributo="Region";
      atributoLevel3.atributo="Division";
      //atributoLevel4.atributo="nivel4";
      //atributoLevel5.atributo="nivel5";
      this.userSession = this.authService.currentUserValue();
      let operator = this.userSession.operator;

      if (operator) {
        const niveles = [
          { nivel: 2, label: operator.labelLevel2, atributo: atributoLevel2 },
          { nivel: 3, label: operator.labelLevel3, atributo: atributoLevel3 },
          // { label: operator.labelLevel4, atributo: atributoLevel4 },
          // { label: operator.labelLevel5, atributo: atributoLevel5 },
        ];

        // Procesar los niveles dinámicamente
        niveles.forEach(({ nivel, label, atributo }) => {
          if (label) {
            try {
              const traductorObj = JSON.parse(label); // Parsear JSON
              Object.entries(traductorObj).forEach(([key, value]) => {
                (atributo as any)[key] = value; // Asignar traducciones
              });
              // Asignar el nivel al atributo
              (atributo as any).nivel = nivel; // Asignar el nivel
            } catch (error) {
              console.error(`Error parsing label: ${label}`, error);
            }
          }
        });

        // Generar la lista de atributos con su nivel correspondiente
        const listaAtributos: VariablesAtributo[] = niveles.map((nivel) => {
          // Devolver el atributo con el nivel asignado
          return { ...nivel.atributo, nivel: nivel.nivel }; 
        });
      this.dataSource = new MatTableDataSource<VariablesAtributo>(listaAtributos);
    }
      if (pagCount) {
        this.pageLength = 2;
      }
    
  }
  private getFilterAndPagBean():FilterAndPagBean{
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;
    return new FilterAndPagBean(
        this.direccion, 
        this.sortId, 
        this.filterList, 
        startPage,
        endPage,
        pageIndex
      );
  }


 onEdit(row:VariablesAtributo){
    const dialogRef = this.dialog.open(FormEditTxtJerComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel' ,
      data: { level : row.nivel , atributo : row.atributo, es : row.ES, en: row.EN}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }
  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }
}

