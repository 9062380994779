
export class VariablesReport {

  	id!: number | undefined;
	idOperator!: number | undefined;
	padre!: string;
	hijo!: string;
	traductor!: string;

	ES!: string;
	EN!: string;

	textoVo!: string;
	
}



