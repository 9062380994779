<div mat-dialog-title class="headerStyle">
  <div class="header-content">
    <!-- Ícono y nombre del terminal -->
    <div class="terminal-info" *ngIf="terminal">
      <div class="terminal-info-icon">
        <mat-icon svgIcon="phone-02"></mat-icon>
      </div> 
      
      <div class="terminal-info-data">
        <span class="header-medium">#{{ terminal.serialNumber }}</span>
      
        <div class="status-indicator">
          <i [ngClass]="terminal.statusClass" class="status-icon"></i>
          <span class="status-label">{{ terminal.statusLabel | translate }}</span>
        </div>

        <div class="status-indicator"  *ngIf="terminal.statusLabel !== 'monitoring.status.pending' && terminal.hasPending">
          <i  class="status-icon blue"></i>
          <span class="status-label">{{ 'pendingTasks.pending' | translate }}</span>
        </div>

      </div>
     
    </div>
    <!-- Botón de cerrar -->
    <div class="action-buttons">
      <button class="ct-button ct-button--tertiary" (click)="closeScreen()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="changeTab($event)" class="custom-tabs custom-tabs__info">
  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.info' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-info-terminal [terminal]="terminal"></app-info-terminal>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.installedApps' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-installed-apps></app-installed-apps>
    </ng-template>
  </mat-tab>

  <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
  <!--mat-tab *ngIf="rol.canByInput(rol.functIds.view_sells)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.sells' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-ventas></app-ventas>
    </ng-template>
  </mat-tab!-->

  <mat-tab *ngIf="rol.canByInput(rol.functIds.view_alerts)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.alerts' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-alerts></app-alerts>
    </ng-template>
  </mat-tab>

  
  <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
  <!--mat-tab *ngIf="rol.canByInput(rol.functIds.view_incidences)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.incidences' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-incidences></app-incidences>
    </ng-template>
  </mat-tab!-->

  
  <mat-tab *ngIf="rol.canByInput(rol.functIds.view_sensors)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.sensors' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-sensores></app-sensores>
    </ng-template>
  </mat-tab>

  <mat-tab
    *ngIf="rol.canByInputs([rol.functIds.send_file,rol.functIds.get_file,rol.functIds.commands,rol.functIds.view_file])">
    <ng-template mat-tab-label>
      <span>{{'monitoring.tabs.maintenance' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-tabs-mantenance></app-tabs-mantenance>
    </ng-template>
  </mat-tab>
</mat-tab-group>