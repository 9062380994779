import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { Pending } from 'src/app/models/pending.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class PendingService {

  
  constructor(private merchantRest : RestService,private authService:AuthService) {}
  
  async find(idMachine:number, filterAndPagBean:FilterAndPagBean ){

    let userSession = this.authService.currentUserValue();
    let _body: Pending = new Pending();

    _body.idVM = idMachine
    _body.filterAndPagBean = filterAndPagBean
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.idOperator=userSession.operator?.id
      if (userSession.client){
        _body.idClient=userSession.client?.id
      }
    }   
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListPendingMachineSend/',false,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  
    async findFileToExport(idMachine:number, filterAndPagBean:FilterAndPagBean){
  
     
    let userSession = this.authService.currentUserValue();
    let _body: Pending = new Pending();

    _body.idVM = idMachine
    _body.filterAndPagBean = filterAndPagBean
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.idOperator=userSession.operator?.id
      if (userSession.client){
        _body.idClient=userSession.client?.id
      }
    }   

    
          let value = await this.merchantRest.commonRestCallFile(_body,'getFileListPendingMachineSend',true,true )   
        .catch(err => {
          return null
        })
        
      return value;   
    }

  

  async countTotal(idMachine:number, filterAndPagBean:FilterAndPagBean ){

    let userSession = this.authService.currentUserValue();
    let _body: Pending = new Pending();

    _body.idVM = idMachine
    _body.filterAndPagBean = filterAndPagBean
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.idOperator=userSession.operator?.id
      if (userSession.client){
        _body.idClient=userSession.client?.id
      }
    }     
    _body.filterAndPagBean= new FilterAndPagBean(_body.filterAndPagBean?.orderSentido,_body.filterAndPagBean?.order,_body.filterAndPagBean?.filterGeneric,null,null,null);

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListPendingMachineCount/',false,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
  

  async delete(idPending:number){

    let _body: Pending = new Pending();

    _body.id= idPending
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'deletePendingMachineSend/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


  async resetPendingMachine(idPending:number){

    let _body: Pending = new Pending();

    _body.id= idPending
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'resetPendingMachineSend/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

 
}