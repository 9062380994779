<div class="profile-app">
    <div class="profile-app-header">
        <p>{{ selectedApp.appName }} ({{ selectedApp.appVersion }}<ng-container *ngIf="selectedApp.modelNumber"> - {{selectedApp.modelNumber}}</ng-container>)</p>

        <!-- Delete button -->
        <ng-container *ngIf="removeButton">
            <button class="ct-button ct-button--tertiary" (click)="removeApp(selectedApp)">
                <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
            </button>
        </ng-container>
    </div>
    <div *ngIf="selectedApp.isIntegrada || selectedApp.isGobernada"> <p>{{ 'profiles.notSupported' | translate }}</p> </div>
    <form  *ngIf="!(selectedApp.isIntegrada || selectedApp.isGobernada)"
     [formGroup]="perfilAppForm">
        <div class="profile-app-form">
            <div class="mb-3 w-50">
                <label for="paramSelect" class="form-label">{{ 'newProfile.selectParameter' | translate }}</label>
                <select class="form-select" aria-label="Parameters select"  formControlName="idParam">
                    <option [value]="null" selected></option>
                    <option *ngFor="let param of parameters" [value]="param.id">{{ param.name }} ({{param.version}})</option>
                </select>
            </div>

            <div class="select-file w-50">
                <div class="form-label">
                    <label for="fileSelect">{{ 'newProfile.paramExtra' | translate }} (CSV)</label>
                </div>
                   
                <!-- File upload -->
                <div *ngIf="selectedApp.paramExtra">
                    <div class="selected-file">
                        <p> {{ selectedApp.fileName}}</p>
                        <button class="ct-button ct-button--icon" (click)="removeFileApp()">
                            <mat-icon class="ct-icon red" svgIcon="trash-01"></mat-icon>
                        </button>
                    </div>
                </div>

                <div *ngIf="!selectedApp.paramExtra">
                    <button class="drop-zone w-100" (drop)="onFilesDrop($event, selectedApp)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
                        <mat-icon>cloud_upload</mat-icon>
                        <input id="fileSelect" type="file" (change)="onAppFileSelected($event, selectedApp)" #fileUpload accept=".csv" style="display:none;">
                    </button>
                </div>
            
            </div>
    
        </div>

        
        <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
        <!--div class="profile-app-form">
            <div class="mb-3 w-50">
                <mat-slide-toggle class="toggle-selector" formControlName="enableDate">{{ 'newProfile.immediateActivation' | translate }}</mat-slide-toggle>
            </div>
        </div>
    
        <div class="profile-app-form">
            <div class="d-flex flex-column mb-3 w-50">
                <label for="activationDate" class="form-label">{{ 'newProfile.activationDate' | translate }}</label>
                <input type=date class="form-control" formControlName="startDate" name="activationDate" id="activationDate">
            </div>
            <div class="d-flex flex-column mb-3 w-50">
                <label for="activationTime" class="form-label">{{ 'newProfile.activationTime' | translate }}</label>
                <input type=time class="form-control" formControlName="startTime" name="activationTime" id="activationTime">
            </div>
        </div!-->
    </form>



</div>