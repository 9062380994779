<div class="edit-panel">
    <div class="header">
        <div class="title">{{ 'users.edit' | translate }}</div>
        <div class="action-buttons">
            <ng-container *ngIf="rol.canByInput(rol.functIds.edit_users) && !readOnly">
                <button class="ct-button ct-button--primary" (click)="onFormSubmit()" [disabled]="userForm.pristine || userForm.invalid">
                    {{ 'editPanel.save' | translate }}
                </button>
            </ng-container>
            <button class="ct-button ct-button--tertiary" (click)="formExit()">
                <mat-icon svgIcon="x"></mat-icon>
            </button>
        </div>
    </div>


    <mat-tab-group class="custom-tabs mt-2" style="height: calc(100% - 68px)">
        <mat-tab>
            <ng-template mat-tab-label>
            <span>{{ 'users.details' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
                <!-- Formulario -->
                <form [formGroup]="userForm" *ngIf="user">
                    <!-- Details Section -->
                    <fieldset class="edit-container bordered">
                        <div class="edit-content">
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline" class="custom-form-field">
                                            <mat-label>{{ 'users.nickName' | translate }}<span
                                                    class="obligatorio"></span></mat-label>
                                            <input matInput class="form-field" formControlName="username" type="text" required>
                                            <mat-error *ngIf="userForm.get('username')?.hasError('required')">{{
                                                'error.required.field' | translate }}</mat-error>
                                            <mat-error *ngIf="userForm.get('username')?.hasError('maxLength')">{{ 'error.maxLength'
                                                | translate }}</mat-error>
                                            <mat-error *ngIf="userForm.get('username')?.hasError('pattern')">{{ 'error.pattern' |
                                                translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline" class="custom-form-field">
                                            <mat-label>{{ 'users.name' | translate }}<span class="obligatorio"></span></mat-label>
                                            <input matInput class="form-field" formControlName="name" type="text" required>
                                            <mat-error *ngIf="userForm.get('name')?.hasError('required')">{{ 'error.required.field'
                                                | translate }}</mat-error>
                                            <mat-error *ngIf="userForm.get('name')?.hasError('pattern')">{{ 'error.pattern' |
                                                translate }}</mat-error>
                                            <mat-error *ngIf="userForm.get('name')?.hasError('maxLength')">{{ 'error.maxLength' |
                                                translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <!-- Email Section -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline" class="custom-form-field">
                                            <mat-label>{{ 'users.email' | translate }}<span class="obligatorio"></span></mat-label>
                                            <input matInput class="form-field" formControlName="email" type="text" required>
                                            <mat-error *ngIf="userForm.get('email')?.hasError('required')">{{ 'error.required.field'
                                                | translate }}</mat-error>
                                            <mat-error *ngIf="userForm.get('email')?.hasError('maxLength')">{{ 'error.maxLength' |
                                                translate }}</mat-error>
                                            <mat-error *ngIf="userForm.get('email')?.hasError('pattern')">{{ 'error.email' | translate
                                                }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <!-- Hierarchy Section -->
                            <div class="row">
                                <div class="col-12" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_3)">
                                    <div class="form-group">
                                        <filterable-select #hierarchyLevelSelect formControlName="hierarchyLevel" [options]="hierarchyOptions" 
                                        [title]="'users.hierarchy' | translate" [required]="true"></filterable-select> 
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
                                    <div class="form-group">
                                        <filterable-select #operatorSelect formControlName="idOperator" [options]="listaOpe" [title]="'users.operator' | translate"
                                        displayField="name" valueField="id" [required]="true"></filterable-select> 
                                    </div>
                                </div>
                                <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_2)">
                                    <div class="form-group">
                                        <filterable-select #clientSelect formControlName="idClient" [options]="clientsList" [title]="'users.client' | jerarquiaLevelTranslate: hierarchy.level.LEVEL_2"
                                        displayField="name" valueField="id" [required]="true"></filterable-select>
                                    </div>
                                </div>
                                <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_3)">
                                    <div class="form-group">
                                        <filterable-select #subClientSelect formControlName="idSubClient" [options]="subClientsList" [title]="'users.subClient' | jerarquiaLevelTranslate: hierarchy.level.LEVEL_3"
                                        displayField="name" valueField="id" [required]="true"></filterable-select>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Role Section -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <filterable-select #rolSelect formControlName="idRol" [options]="listaRol" 
                                        [title]="'users.rol' | translate" displayField="name" valueField="id" [required]="true"
                                        ></filterable-select> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                
                <div class="footer" *ngIf="!readOnly">
                    <ng-container *ngIf="rol.canByInput(rol.functIds.delete_subclients) && user?.dateBloqueo">
                        <button class="ct-button ct-button--secondary"  matTooltip="{{ 'users.delete' | translate }}" (click)="onDelete()">
                            <span class="hide-on-mobile">{{ 'users.delete' | translate }}</span>
                            <mat-icon class="ct-icon red" svgIcon="trash-01"></mat-icon>
                        </button>
                    </ng-container>
                    
                    <ng-container *ngIf="rol.canByInput(rol.functIds.delete_subclients) && user?.dateBloqueo">
                        <button class="ct-button ct-button--secondary"  matTooltip="{{ 'users.unblock' | translate }}" (click)="onUnblock()">
                            <span class="hide-on-mobile">{{ 'users.unblock' | translate }}</span>
                            <mat-icon class="ct-icon green" svgIcon="slash-circle-01"></mat-icon>
                        </button>
                    </ng-container>
            
                    <ng-container *ngIf="rol.canByInput(rol.functIds.delete_subclients) && !user?.dateBloqueo">
                        <button class="ct-button ct-button--secondary"  matTooltip="{{ 'users.block' | translate }}" (click)="onBlock()">
                            <span class="hide-on-mobile">{{ 'users.block' | translate }}</span>
                            <mat-icon class="ct-icon red" svgIcon="slash-circle-01"></mat-icon>
                        </button>
                    </ng-container>
                    
                    <ng-container *ngIf="rol.canByInput(rol.functIds.edit_users) && !user?.dateBloqueo">
                        <button class="ct-button ct-button--secondary" matTooltip="{{ 'tooltips.resetPassword' | translate }}" (click)="onResetPassword()">
                            <mat-icon class="ct-icon blue" svgIcon="refresh-ccw-03"></mat-icon>
                        </button>
                    </ng-container>
                </div>
            
            </ng-template>
        </mat-tab>
    
        <mat-tab>
            <ng-template mat-tab-label>
                <span>{{ 'users.history' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
                <app-history-user [userId]="user.id"></app-history-user>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>