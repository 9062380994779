import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from 'src/app/auth/auth.service';
import { EventsService } from 'src/app/services/events.service';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { UtilService } from 'src/app/services/util.service';
import { NAVIGATION_SECTIONS, NavigationSectionType } from 'src/app/util/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  isAdmin: boolean = false;
  isoperator: boolean = false;
  public selectedAdminItem: string = '';
  eventsSubscription?: Subscription;

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private events:EventsService,
    private router:Router,
    public hierarchy:HierarchyHandlerService,
    public rol:RolHandlerService
  ) {}


  ngOnInit(): void {
    this.isAdmin = this.utilService.isAdmin();
    this.isoperator = this.utilService.isOperator()
  
    this.eventsSubscription = this.events.navigationChange$.subscribe((section:(NavigationSectionType|null))=>{
      if(section){
        const sectionConfig = NAVIGATION_SECTIONS[section];
        this.marcarMenu(sectionConfig.button);
        this.router.navigateByUrl(sectionConfig.path);
        let element = document.getElementById(sectionConfig.collapse);
        element?.click();
      }
    })
  }

  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
  }


  closeAllCollapse(){
    const collapseElementList = document.querySelectorAll('.collapse');
    collapseElementList.forEach((element) => element.classList.remove('show') );
    
    const togglerElementList = document.querySelectorAll('.nav-link');
    togglerElementList.forEach((element) => element.classList.add('collapsed') );
  }

  marcarMenu(id_Li:any){
    const testElements = document.getElementsByClassName('toc');
    const testDivs = Array.prototype.filter.call(testElements,
      (testElement) => testElement.checked = false,
    );

    const slectToc = document.getElementById(id_Li) as HTMLInputElement;    
    if(slectToc){
      slectToc.checked = true;
    }
    this.selectedAdminItem = id_Li
  }




}
