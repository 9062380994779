import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogModule, } from '@angular/material/legacy-dialog';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { TranslateModule } from '@ngx-translate/core';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { environment } from 'src/environments/environment';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { PerfilesService } from '../perfiles.service';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Profile } from 'src/app/models/profiles.model';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

const FILTER_COLUMNS:{ field:string, label:string,type: FieldType}[] = [
  { field: 'vendingMachineAtento.serialNumber', label:'vendingMachine.serialNumber', type: 'string' },
  { field: 'vendingMachineAtento.manufacID', label:'vendingMachine.manufacID', type: 'string' },
  { field: 'vendingMachineAtento.modelNumber', label:'vendingMachine.modelNumber', type: 'string' }, 
]

@Component({
  standalone:true,
  imports:[CommonModule, 
    TranslateModule, 
    MatLegacyListModule, 
    MatIconModule, 
    MatPaginatorModule,
    MatLegacyDialogModule,
    FilterComponent,
    TableEmptyStateComponent
  ],
  selector: 'app-list-related-machines',
  templateUrl: './list-related-machines.component.html',
  styleUrls: ['./list-related-machines.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class ListRelatedMachinesComponent implements OnInit {
  machines:VendingMachineAtento[] = [];
  availableMachines:VendingMachineAtento[] = [];
  
  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, column.label, column.type);
    return fielterField;
  });
  filterList: FilterableValue[] = [];
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data:{ profile:Profile },
    private dialogRef:MatLegacyDialogRef<ListRelatedMachinesComponent>,
    private dialog:MatDialog,
    private profileService:PerfilesService,
    public rol:RolHandlerService
  ){}
 
  private async loadProfileMachines(){
    if(!this.data.profile?.id) return;
    
    const filterAndPagBean = this.getFilterAndPagBean();
    const result = await this.profileService.getVendingMachines(this.data.profile.id, filterAndPagBean);
    if (result?.status === 0){
      this.machines = result.data;
      this.pageLength = this.machines.length;
    }
  }

  async ngOnInit(): Promise<void> {
    await this.loadProfileMachines();
  }
  
  getFilterAndPagBean(): FilterAndPagBean {
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    return new FilterAndPagBean(null,null,this.filterList,startPage,endPage,pageIndex);
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.loadProfileMachines();
  }

  public delete(item:VendingMachineAtento){
    const itemIndex = this.machines.findIndex((machine)=>{ return JSON.stringify(machine) === JSON.stringify(item) });
    if(itemIndex >= 0) this.machines.splice(itemIndex, 1);
    this.pageLength = this.machines.length;
  }

  public pageEvent(){
    this.loadProfileMachines();
  }

  public onClose(){
    this.dialogRef.close();
  }

  public submitChanges(){
    const dialogData = new ConfirmDialogData;
    dialogData.enableCancel = false;
    dialogData.element = Element.profile; 
    dialogData.action = ElementAction.udpate_masculino;
    dialogData.class = DialogClass.info;  
    dialogData.icon = 'save-01';

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
      width: '35%', panelClass: 'custom-modalbox',
      data:dialogData
    });

    confirmSave.afterClosed().subscribe((response:ConfirmDialogActions) => {
      if (response === ConfirmDialogActions.CONFIRM) this.updateProfile();
    });
  }

  public async updateProfile() {
    const profile = this.data.profile;
  
    // Validar las máquinas
    const invalidMachines = this.machines.filter(machine => 
      !machine.serialNumber	 || !machine.manufacID || !machine.modelNumber
    );
  
    if (invalidMachines.length > 0) {
      return; // No continuar si hay máquinas inválidas
    }
  
    profile.listVendingMachine = [...this.machines];
    const response = await this.profileService.saveTerminals(profile);
  
    if (response!.status >= 0) this.dialogRef.close({ result: 1 });
  }
  
}

