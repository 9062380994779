<article class="blocks-container" cdkDropList (cdkDropListDropped)="dropItem($event)">
  <ng-container *ngFor="let app of relatedApps">
    <div class="block draggable" cdkDrag>
      <div class="block-header">
        <div class="block-content-row">
          <div class="block-content-item">
            <a class="reditect-tag" (click)="openAppDetail(app)">{{ app.appName }} ({{ app.appVersion }}<ng-container
                *ngIf="app.modelNumber"> - {{app.modelNumber}}</ng-container>) </a>
          </div>
          <div class="block-content-item dech">
            <p class="disabled-text" *ngIf="app.dateStart"> {{ 'related-apps.activation' | translate }}: {{
              app.dateStart | dateFromTerminal }} </p>
            <p class="disabled-text" *ngIf="!app.dateStart"> {{ 'related-apps.enableActivation' | translate }} </p>
          </div>
        </div>
        <div class="block-actions">
          <ng-container *ngIf="rol.canByInput(rol.functIds.delete_profiles)">
            <button mat-icon-button class="delete-icon" (click)="deleteItem(app)">
              <mat-icon svgIcon="trash-01"></mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="rol.canByInput(rol.functIds.edit_profiles)">
            <button mat-icon-button (click)="editItem(app)">
              <mat-icon svgIcon="chevron-right"></mat-icon>
            </button>
          </ng-container>
        </div>
      </div>

      <div class="block-header" *ngIf="app.isGobernada">
        <div class="block-content-row">
          <div class="block-content-item">
            <p>&nbsp;</p>
           </div>
          <div class="block-content-item dech">
            <p class="disabled-text"> {{ 'related-apps.governed' | translate }} </p>
          </div>
        </div>
        <div class="block-actions">
          <ng-container *ngIf="rol.canByInput(rol.functIds.edit_profiles)">
            <button mat-icon-button (click)="isGoverned(app)">
              <mat-icon svgIcon="chevron-right"></mat-icon>
            </button>
          </ng-container>
        </div>
      </div>

      <div class="block-header" *ngIf="app.isIntegrada">
        <div class="block-content-row">
          <div class="block-content-item">
            <p>&nbsp;</p>
           </div>
          <div class="block-content-item dech">
            <p class="disabled-text"> {{ 'related-apps.integrated' | translate }} </p>
          </div>
        </div>
        <div class="block-actions">
        </div>
      </div>

      <div class="block-content">
        <div class="block-content-row">
          <div class="block-content-item" *ngIf="app.idParam">
            <label class="text-xs">{{ 'related-apps.parameters' | translate }}</label>
            <a class="reditect-tag" (click)="openParamDetail(app)">{{ app.paramName }} ({{ app.paramVersion }})</a>
          </div>
          
          <div class="block-content-item" *ngIf="app.paramExtra">
            <label class="text-xs">{{ 'related-apps.parametersExtra' | translate }}</label>
            <a class="reditect-tag" (click)="openParamExtra(app)">{{ app.fileName }}</a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</article>

<!-- Empty state -->
<ng-container *ngIf="!relatedApps.length">
  <app-table-empty-state [icon]="false"></app-table-empty-state>
</ng-container>
