import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertCongif } from 'src/app/models/alertConfig.model';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class CustomAlertsService {

  constructor(private merchantRest : RestService,private authService:AuthService) {}

 
  
  async find(_body: UsersConsoleRequest){
    let userSession = this.authService.currentUserValue();
    if(userSession.operator) _body.idOperator=userSession.operator?.id;
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getMisAlertas/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


  async countTotal(_body:UsersConsoleRequest) {
    const { filterAndPagBean } = _body;
    _body.filterAndPagBean=new FilterAndPagBean(filterAndPagBean?.orderSentido, filterAndPagBean?.order, filterAndPagBean?.filterGeneric,null,null,null);

    let userSession = this.authService.currentUserValue();
    if(userSession.operator) _body.idOperator=userSession.operator?.id;
  
    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'countMisAlertas/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }


  
  async addUpdate(_body:AlertCongif){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'addAlertConfig/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async delete(_body:AlertCongif){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'deleteAlertConfig/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

}