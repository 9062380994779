import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { NotifierService } from 'angular-notifier';
import { Rol } from 'src/app/models/rol.model';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { RolService } from '../rol.service';
import { FormCreateActions, FUNCTION_IDS } from 'src/app/util/constants';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { Operator } from 'src/app/models/operator.model';
import { PERMISSIONS_DEFINITIONS, PERMISSIONS_RELATED } from '../permissions';
import { UsersService } from '../../users/users.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER } from 'src/app/models/FilterAndPagBean';

@Component({
  selector: 'app-form-edit-rol',
  templateUrl: './form-edit-rol.component.html',
  styleUrls: ['./form-edit-rol.component.css']
})
export class FormEditRolComponent implements OnInit {
  registerFormStep1!: FormGroup;
  registerFormStep2!: FormGroup;
  onEditState:boolean = false;
  public rolService: RolService | undefined;
  listaRol: Rol[] = [];
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  rolForm!: FormGroup;
  rol: Rol | null = null;
  rolId: string | undefined; 
  readOnly: boolean = false;

  isOpen: { [key: string]: boolean } = {
    details: true,        
    permissions: true, 
  };
  
  operatorsList:Operator[] = [];
  permissions = PERMISSIONS_DEFINITIONS;
  static EXIT: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: RolService,
    private utilServicios: UtilService,
    private userService:UsersService,
    public dialogRef: MatDialogRef<FormEditRolComponent>,
    public dialog: MatDialog,
    private translate: TranslateService,
    public handleRol:RolHandlerService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.registerFormStep1 = this.formBuilder.group({
      nombre: ['', Validators.compose([Validators.required,Validators.maxLength(45)])],
      description: ['', Validators.compose([Validators.required,Validators.maxLength(200)])],
      idOperator:[ this.utilServicios.getOperatorId() ?? '']
    });

    this.rol = this.data.rol;        
    this.rolId = this.data.rolId;   

    this.registerFormStep2 = this.createPermissionsFormGroup();

    if (this.rol) {
      this.loadFormData(this.rol);
      this.initializePermissions();
    }

    if (this.handleRol.isAdmin()) this.loadOperators();
    this.listenToChanges();

    await this.cargar();

  }

  private async loadOperators(){
    this.utilServicios.findOperators().subscribe((response)=> this.operatorsList = response);
  }

  private initializePermissions() {
    // Hacer un mapeo entre idFunction y el nombre del permiso
    const permissionMap = this.createPermissionMap();

    if (this.rol?.listFunction) {
      this.rol.listFunction.forEach((func) => {
        Object.keys(this.registerFormStep2.controls).forEach(categoryKey => {
          const categoryGroup = this.registerFormStep2.get(categoryKey) as FormGroup;
    
          if (categoryGroup) { // Verifica que la categoría existe
            const permissionName = permissionMap[func.idFunction]; // Buscar el nombre del permiso por idFunction
            const permissionControl = categoryGroup.get(permissionName); // Busca dentro de la categoría
    
            if (permissionControl) { // Si el control existe, asigna el valor
              permissionControl.setValue(func.status);
            }
          }
        });
      });
      this.updateSelectAllCheckboxes();
    }
    
  }

  private updateSelectAllCheckboxes() {
    let allCategoriesSelected = true; 
  
    Object.keys(this.registerFormStep2.controls).forEach(categoryKey => {
      const categoryGroup = this.registerFormStep2.get(categoryKey) as FormGroup;
  
      if (categoryGroup?.controls) { // Verifica que la categoría y sus controles existen
        const allPermissions = Object.keys(categoryGroup.controls)
          .filter(permission => permission !== 'selectAll') // Ignorar el checkbox de "selectAll"
          .map(permission => categoryGroup.get(permission)?.value ?? false); 
  
        const allSelected = allPermissions.length > 0 && allPermissions.every(value => value === true); 
        categoryGroup.get('selectAll')?.setValue(allSelected, { emitEvent: false }); 
  
        if (!allSelected) {
          allCategoriesSelected = false; // Si al menos una categoría no está completamente seleccionada, desmarcamos selectAllGlobal
        }
      }
    });
  
    // Marcar o desmarcar el selectAllGlobal según si todas las categorías están seleccionadas
    this.registerFormStep2.get('selectAllGlobal')?.setValue(allCategoriesSelected, { emitEvent: false });
  }
  
  
  
  private createPermissionMap(): { [key: number]: string } {
    const map: { [key: number]: string } = {};
    this.permissions.forEach(category => {
      category.items.forEach(permission => {
        // Asegurarse de que permission.name es una clave válida de FUNCTION_IDS
        const functionId = FUNCTION_IDS[permission.name as keyof typeof FUNCTION_IDS];
        map[functionId] = permission.name;
      });
    });
    return map;
  }
  
  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }

  loadFormData(rol: Rol): void {
    this.registerFormStep1.patchValue({
      nombre: rol.name,
      description: rol.description,
      idOperator: rol.idOperator
    });

    if (rol.listFunction) {
      rol.listFunction.forEach((func) => {
        const permissionControl = this.registerFormStep2.get(func.idFunction.toString());
        if (permissionControl) {
          permissionControl.setValue(func.status);
        }
      });
    }
  }

  async cargar(): Promise<void> {
    try {
      this.utilServicios.findRol().subscribe((listaRolTemp: Rol[]) => {
        this.listaRol = listaRolTemp;
        if (this.rol) {
          this.loadFormData(this.rol);
        }
      });
    } catch (error) {
      this.notifier.notify('error', this.translate.instant('error.general'));
    }
  }
  

   createPermissionsFormGroup(): FormGroup {
    const group: { [key: string]: FormGroup | FormControl } = {};
  
    this.permissions.forEach(category => {
      const categoryGroup: { [key: string]: FormControl } = {};
  
      category.items.forEach(permission => {
        categoryGroup[permission.name] = new FormControl(false);
      });
  
      categoryGroup['selectAll'] = new FormControl(false);
  
      group[category.category] = this.formBuilder.group(categoryGroup);
    });
  
    // Agregar checkbox global
    group['selectAllGlobal'] = new FormControl(false);
  
    return this.formBuilder.group(group);
  }
  

  // Cambiar el nombre a onSave
  async onSave(): Promise<void> {
    if (this.registerFormStep1.invalid || this.registerFormStep2.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    const rolData = this.buildRolForDatabase();

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.role; 
    dialogData.action = ElementAction.udpate_masculino;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.saveRol(rolData);
      }
    });
  }

  toggleSelectAll(categoryName: string): void {
    const categoryGroup = this.registerFormStep2.get(categoryName) as FormGroup;
    const selectAllControl = categoryGroup.get('selectAll') as FormControl;
    const isChecked = selectAllControl.value;

    // Actualizar todos los permisos de la categoría
    Object.keys(categoryGroup.controls).forEach(controlName => {
      if (controlName !== 'selectAll') {
        categoryGroup.get(controlName)?.setValue(isChecked);
      }
    });
  }

  


 
   private buildRolForDatabase(): any {
      const rolId = this.rolId;  

     const rolName = this.registerFormStep1.value.nombre;
     const rolDescripcion = this.registerFormStep1.value.description;
     const rolOperator = this.registerFormStep1.value.idOperator;
     const permissions = this.registerFormStep2.value;
   
     const listFunction = Object.keys(permissions).flatMap((categoryKey) => {
       const categoryGroup = permissions[categoryKey]; // Subgrupo por categoría
   
       return Object.keys(categoryGroup).flatMap((key) => {
         // Ignorar el checkbox "selectAll"
         if (key === 'selectAll') {
           return [];
         }
   
         const functionKey = key as keyof typeof FUNCTION_IDS;
         const idFunction = FUNCTION_IDS[functionKey];
   
         if (idFunction !== undefined && typeof categoryGroup[key] === 'boolean') {
           return [{ idFunction, status: categoryGroup[key] }];
         }
   
         return [];
       });
     });
   
     return {
       id: rolId,  // Usa el rolId para la actualización
       name: rolName,
       description: rolDescripcion,
       listFunction: listFunction,
       idOperator: rolOperator,
     };
   }
   
  
  
  async saveRol(rolData: any) {
    try {
      const response = await this.service.updateRol(rolData);

      if (response && response.status >= 0) {
        this.notifier.notify('success', this.translate.instant('success.updateRol'));
        this.utilServicios.getRolsAndOperators();
        this.dialogRef.close(1);
      } else {
        this.notifier.notify('error', this.translate.instant('error.updateRol'));
      }
    } catch (error) {
      this.notifier.notify('error', this.translate.instant('error.general'));
    }
  }

  formExit(): void {
    this.dialogRef.close();
  }
  onCancel(){
    this.dialogRef.close(FormEditRolComponent.EXIT);
  }

  // TODO: enviar condición al back
  private async canDeleteRol(rolId:number): Promise<boolean> {
    const filters = [new FilterableValue('rol.id',rolId,'long', OPERATION_FILTER.EQUALS)]
    const request = new UsersConsoleRequest();
    request.filterAndPagBean = new FilterAndPagBean(null,null,filters,null,null,null);
    const response = await this.userService.countTotal(request);
    if(response?.data){
      const { total } = response.data;
      return (total > 0) ? false : true;
    }

    return true;
  }

  async onDelete(rol: Rol) {
    if(!rol?.id) return;

    const rolId = rol.id; 

    const canDelete = await this.canDeleteRol(rol.id); 
    if(!canDelete){
      this.notifier.notify('error', this.translate.instant('warning.rolIsOnUse'));
      return;
    }

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.role; 
    dialogData.action = ElementAction.delete_masculino;
    dialogData.class = DialogClass.error;
    dialogData.icon = 'trash-01';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });
  
    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        this.deleteRol(rolId);
      }
    });
  }

  private async deleteRol(rolId: number) {
    const result = await this.service.deleteRol(rolId);
    if (result && result.status === 0) {
      this.notifier.notify('success', this.translate.instant('success.roleDeleted'));
      this.dialogRef.close(FormCreateActions.SAVED);
      this.utilServicios.getRolsAndOperators();
    }
  }


  
   


  private listenToChanges(): void {
    Object.keys(this.registerFormStep2.controls).forEach(categoryKey => {
      const categoryGroup = this.registerFormStep2.get(categoryKey);
  
      if (categoryGroup instanceof FormGroup) {
        const selectAllControl = categoryGroup.get('selectAll') as FormControl;
  
        // Listener para "Seleccionar todos" de la categoría
        selectAllControl?.valueChanges.subscribe(value => {
          Object.keys(categoryGroup.controls).forEach(controlName => {
            if (controlName !== 'selectAll') {
              categoryGroup.get(controlName)?.setValue(value, { emitEvent: false });
            }
          });
  
          // Actualizar el estado global cuando cambia "selectAll" de una categoría
          this.updateGlobalCheckbox();
        });
  
        // Listener para cambios en los permisos individuales dentro de la categoría
        Object.keys(categoryGroup.controls).forEach(controlName => {
          if (controlName !== 'selectAll') {
            const permissionControl = categoryGroup.get(controlName) as FormControl;
  
            permissionControl?.valueChanges.subscribe(() => {
              const allChecked = Object.keys(categoryGroup.controls).every(key => {
                if (key === 'selectAll') return true;
                return categoryGroup.get(key)?.value === true;
              });
  
              selectAllControl?.setValue(allChecked, { emitEvent: false });
  
              // Actualizar el estado global cuando cambia un permiso
              this.updateGlobalCheckbox();
            });
          }
        });
      }
    });
  
    // Listener para el checkbox global "Seleccionar todo"
    const globalControl = this.registerFormStep2.get('selectAllGlobal') as FormControl;
  
    globalControl?.valueChanges.subscribe(value => {
      Object.keys(this.registerFormStep2.controls).forEach(categoryKey => {
        const categoryGroup = this.registerFormStep2.get(categoryKey);
  
        if (categoryGroup instanceof FormGroup) {
          Object.keys(categoryGroup.controls).forEach(controlName => {
            categoryGroup.get(controlName)?.setValue(value, { emitEvent: false });
          });
        }
      });
    });
  }
  
  
  private updateGlobalCheckbox(): void {
    const allCategoriesChecked = Object.keys(this.registerFormStep2.controls).every(categoryKey => {
      const categoryGroup = this.registerFormStep2.get(categoryKey);
  
      if (categoryGroup instanceof FormGroup) {
        return Object.keys(categoryGroup.controls).every(key => {
          if (key === 'selectAll') return true;
          return categoryGroup.get(key)?.value === true;
        });
      }
      return true;
    });
  
    const globalControl = this.registerFormStep2.get('selectAllGlobal') as FormControl;
    if (globalControl) {
      globalControl.setValue(allCategoriesChecked, { emitEvent: false });
    }
  }
  

  selectAllCategories(selectAll: boolean): void {
    Object.keys(this.registerFormStep2.controls).forEach(categoryKey => {
      const categoryGroup = this.registerFormStep2.get(categoryKey);
  
      if (categoryGroup instanceof FormGroup) {
        Object.keys(categoryGroup.controls).forEach(controlName => {
          categoryGroup.get(controlName)?.setValue(selectAll, { emitEvent: false });
        });
      }
    });
  }
  
}