export class ConfirmDialogData {
  element?: Element;
  action?: ElementAction;
  class?:DialogClass;
  icon?:string;
  enableCancel: boolean = true;
  enableButtons: boolean = true;
  extraInfo?:string;
}

export enum ElementAction {
  create = '',
  udpate= '',
  delete = '',
  upload ='',
  create_femenino = 'create_femenino',
  create_masculino = 'create_masculino',
  create_x = 'create_x',
  udpate_femenino = 'udpate_femenino',
  udpate_masculino = 'udpate_masculino',
  udpate_x = 'udpate_x',
  delete_femenino = 'delete_femenino',
  delete_masculino = 'delete_masculino',
  delete_masculinos = 'delete_masculinos',
  delete_x = 'delete_x',
  upload_femenino = 'upload_femenino',
  upload_masculino = 'upload_masculino',
  upload_x = 'upload_x',
  block = 'block',
  unblock = 'unblock',
  select = 'select',
  send = 'send',
  request = 'request',
  resetPassword = 'resetPassword',
  resetTask = 'resetTask',
  clone = 'clone'
}

export enum Element {
  user = 'user',
  terminal = 'terminal',
  application = 'application',
  version = 'version',
  parameter = 'parameter',
  profile = 'profile',
  alert = 'alert',
  tag = 'tag',
  incidence = 'incidence',
  role = 'role',
  command = 'command',
  file = 'file',
  task = 'task',
  client = 'client',
  subClient = 'subClient',
  operator = 'operator',
  grafica = 'grafica',
  jerarquia = 'jerarquia',
}

export enum DialogClass {
  info = 'info',
  warning = 'warning',
  error = 'error'
}

export enum ConfirmDialogActions {
  CONFIRM = 1,
  CANCEL = 0
}

