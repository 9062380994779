import { ApexOptions } from "ng-apexcharts";
import { ChartType, TimeUnit } from "../models/StadisticDetails.model";
import { searchVariableReport } from "./utilGraf";
import { VariablesReport } from "../models/variablesReport.model";


export function valueOrDefault<T>(value: T | undefined, defaultValue: T) {
  return typeof value === 'undefined' ? defaultValue : value;
}

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function months(config: any) {
  let cfg = config || {};
  let count = cfg.count || 12;
  let section = cfg.section;
  let values = [];
  let i, value;

  for (i = 0; i < count; ++i) {
    value = MONTHS[Math.ceil(i) % 12];
    values.push(value.substring(0, section));
  }

  return values;
}

const COLORS = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba'
];

export function color(index: any) {
  return COLORS[index % COLORS.length];
}

export const CHART_COLORS = {
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)'
};

export const NAMED_COLORS = [
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
];

export function namedColor(index: any) {
  return NAMED_COLORS[index % NAMED_COLORS.length];
}


export function validateJsonAttributes(dataItems: any[]): { isValid: boolean; error?: string } {
  try {
    if (!dataItems || dataItems.length === 0) {
      return { isValid: false, error: "The dataItems array is empty or invalid." };
    }

    // const firstParsedData = JSON.parse(dataItems[0].data);
    // const referenceKeys = Object.keys(firstParsedData);

    for (const item of dataItems) {
      const parsedData = item.data;

      // Verificar si las claves coinciden
      // const keysMatch = JSON.stringify(Object.keys(parsedData)) === JSON.stringify(referenceKeys);
      // if (!keysMatch) {
      //   return { isValid: false, error: "stadisticsDetails.errorJsonAttributes" };
      // }

      // Verificar si los valores son numéricos
      const valuesAreNumeric = Object.values(parsedData).every(
        (value) => typeof value === 'number' && !isNaN(value)
      );
      if (!valuesAreNumeric) {
        return { isValid: false, error: "stadisticsDetails.errorNumeric" };
      }
    }

    return { isValid: true };
  } catch (error) {
    return { isValid: false, error: "stadisticsDetails.errorGeneric" };
  }
}




export function chartUtilConstructor(chartType: string,
  dataItems: any[],
  title: string,
  options: ApexOptions,
  lang: string | undefined,
  variablesReport: VariablesReport[],
  unitTimeFrom?: string,
  unitTimeTo?: string): ApexOptions {

  let chartOptions = { ...options };
  const series: any[] = [];
  let categories: string[] = [];
  chartOptions.title = {
    text: searchVariableReport(variablesReport, title, null, lang)
  };

  switch (chartType) {
    case "line":
    case "area":
    case "bar-vertical":
    case "scatter":
      dataItems.forEach((item) => {
        if (!categories.includes(item.period)) {
          categories.push(item.period);
        }

        const parsedData = item.parsedData;

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === key);

          if (!seriesItem) {
            seriesItem = { name: key, data: Array(categories.length).fill(0) };
            series.push(seriesItem);
          }

          const index = categories.indexOf(item.period);
          seriesItem.data[index] = parsedData[key];
        });
      });


      // cambio de nombre de la etiqueta si tiene // convertir si es de tiempo
      if (unitTimeTo || dataItems[0].unit) {
        series.forEach(item => {
          item.name = searchVariableReport(variablesReport, title, item.name, lang);
          item.data = convertTimeUnits(item.data, unitTimeFrom ?? dataItems[0].unit, unitTimeTo ??  TimeUnit.MINUTE);
        });

      } else {
        series.forEach(item => item.name = searchVariableReport(variablesReport, title, item.name, lang));
      }


      chartOptions.series = series;
      chartOptions.xaxis = { ...chartOptions.xaxis, categories };
      if (chartType === "bar-vertical") {
        chartOptions.plotOptions = {
          bar: { horizontal: false },
        };
      }


      // ajustes e inicializando cuando tiene unit
      if (unitTimeTo || dataItems[0].unit) {
        chartOptions.yaxis = {
          opposite: true,
          labels: {
            formatter: function (val, opts) {
              if (!isNumber(opts)) {
                return formatTimeLabel(val, unitTimeTo ?? TimeUnit.MINUTE);
              } else {
                return formatYAxisLabel(val, unitTimeTo ?? TimeUnit.MINUTE);;
              }
            }
          }
        };

        chartOptions.markers = {
          size: 3,
          strokeWidth: 2,
          hover: {
            size: 10 
          }
        }
        chartOptions.dataLabels = {
          enabled: false,
        }
      

      } 
      break;

    case "bar-horizontal":

      dataItems.forEach((item) => {
        if (!categories.includes(item.period)) {
          categories.push(item.period);
        }

        const parsedData = item.parsedData;

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === key);

          if (!seriesItem) {
            seriesItem = { name: key, data: [] };
            series.push(seriesItem);
          }

          seriesItem.data.push(parsedData[key]);
        });
      });
      series.forEach(item => item.name = searchVariableReport(variablesReport, title, item.name, lang));

      chartOptions.series = series;
      chartOptions.xaxis = {
        categories,
        title: { text: 'Cantidad' },
      };
      chartOptions.yaxis = {
        title: { text: 'Periodos' },
      };
      chartOptions.plotOptions = {
        bar: { horizontal: true },
      };
      break;

    case "pie":
    case "donut":
      const aggregatedData: Record<string, number> = {};

      dataItems.forEach((item) => {
        const parsedData = item.parsedData;

        Object.keys(parsedData).forEach((key) => {
          if (!aggregatedData[key]) {
            aggregatedData[key] = 0;
          }
          aggregatedData[key] += parsedData[key];
        });
      });
      chartOptions.series = Object.values(aggregatedData);
      chartOptions.labels = Object.keys(aggregatedData);

      delete chartOptions.xaxis;
      delete chartOptions.yaxis;
      break;
    case "heatmap":
      dataItems.forEach((item) => {
        const parsedData = item.parsedData;

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === key);

          if (!seriesItem) {
            seriesItem = { name: key, data: [] };
            series.push(seriesItem);
          }

          seriesItem.data.push({ x: item.period, y: parsedData[key] });
        });
      });
      series.forEach(item => item.name = searchVariableReport(variablesReport, title, item.name, lang));

      chartOptions.series = series;
      delete chartOptions.xaxis;
      delete chartOptions.yaxis;
      break;

    case "bubble":
      dataItems.forEach((item) => {
        const parsedData = item.parsedData;

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === key);

          if (!seriesItem) {
            seriesItem = { name: key, data: [] };
            series.push(seriesItem);
          }

          seriesItem.data.push({ x: item.period, y: parsedData[key], z: Math.random() * 100 });
        });
      });
      series.forEach(item => item.name = searchVariableReport(variablesReport, title, item.name, lang));

      chartOptions.series = series;
      delete chartOptions.xaxis;
      delete chartOptions.yaxis;
      break;

    case "radar":
      dataItems.forEach((item) => {
        const parsedData = item.parsedData;

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === item.period);

          if (!seriesItem) {
            seriesItem = { name: item.period, data: [] };
            series.push(seriesItem);
          }

          seriesItem.data.push(parsedData[key]);
        });

        categories = Object.keys(JSON.parse(dataItems[0].parsedData));
      });

      chartOptions.series = series;
      chartOptions.xaxis = undefined;
      break;

    case "cm-battery":

      dataItems.forEach((item) => {
        if (!categories.includes(item.period)) {
          categories.push(item.period);
        }

        const parsedData = item.parsedData;

        Object.keys(parsedData).forEach((key) => {
          if (key === 'batteryUnit') {
            return
          }
          let seriesItem = series.find((s) => s.name === key);

          if (!seriesItem) {
            seriesItem = { name: key, data: Array(categories.length).fill(0) };
            series.push(seriesItem);
          }

          const index = categories.indexOf(item.period);
          seriesItem.data[index] = key === "batteryState" ? parsedData[key] * 100 : parsedData[key];
        });
      });



      series.forEach(item => item.name = searchVariableReport(variablesReport, title, item.name, lang));

      chartOptions = {
        series: series.map(s => ({
          name: s.name,
          type: s.name === searchVariableReport(variablesReport, title, "batteryLevel", lang) ? 'column' : 'area',
          data: s.data,
          color: s.name === searchVariableReport(variablesReport, title, "batteryLevel", lang) ? 'rgb(0, 227, 150)' : 'rgba(0, 227, 150, 0.2)',
        })),

        chart: {
          height: 350,
          type: 'line',
        },
        // colors: ['rgb(0, 227, 150)', 'rgba(0, 227, 150, 0.2)'], 
        stroke: {
          width: [0, 0], // Grosor: 0 para barras, 0 para el área
          curve: 'stepline',
        },
        fill: {
          colors: ['rgb(0, 227, 150)', 'rgba(0, 227, 150, 0.2)'],
          opacity: [1, 0.1],
        },
        plotOptions: {
          bar: {
            columnWidth: '95%',
            borderRadiusApplication: 'end',
            borderRadius: 20,
          },
        },
        title: {
          text: title,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
          categories: categories,
          labels: {
            rotate: -45, // Gira las etiquetas en el eje X
          },
        },
        yaxis: [
          {
            opposite: true,
            min: 0,
            max: 100,
            labels: {
              formatter: (value) => value + ' %',
            },
          },
        ],
        grid: {
          borderColor: '#f1f1f1',
        },
        tooltip: {
          shared: true,
          intersect: false,
        }

      };


      break;

    default:
      dataItems.forEach((item) => {
        if (!categories.includes(item.period)) {
          categories.push(item.period);
        }

        const parsedData = item.parsedData;

        Object.keys(parsedData).forEach((key) => {
          let seriesItem = series.find((s) => s.name === key);

          if (!seriesItem) {
            seriesItem = { name: searchVariableReport(variablesReport, title, key, lang), data: Array(categories.length).fill(0) };
            series.push(seriesItem);
          }

          const index = categories.indexOf(item.period);
          seriesItem.data[index] = parsedData[key];
        });
      });

      chartOptions.series = series;
      chartOptions.xaxis = { ...chartOptions.xaxis, categories };
      chartOptions.chart!.type = ChartType.LINE;
      break;

  }


  return chartOptions;
}


function isNumber(value: any): boolean {
  return typeof value === 'number' && !isNaN(value);
}

const timeConversionFactors: Record<TimeUnit, number> = {
  [TimeUnit.MILLISECOND]: 0.001,
  [TimeUnit.SECOND]: 1,
  [TimeUnit.MINUTE]: 60,
  [TimeUnit.HOUR]: 3600,
  [TimeUnit.DAY]: 86400
};

function convertTimeUnits(values: number[], fromUnit: string, toUnit: string): number[] {
  const fromFactor = timeConversionFactors[fromUnit as TimeUnit];
  const toFactor = timeConversionFactors[toUnit as TimeUnit];

  if (fromFactor === undefined || toFactor === undefined) {
    throw new Error(`Unidad de tiempo inválida: ${fromUnit} -> ${toUnit}`);
  }
  const convertedValues = values.map(value => (value * fromFactor) / toFactor);
  return convertedValues;
}
function formatTimeLabel(value: number, unit: string): string {
  const conversionFactors: Record<string, number> = {
    Day: 24,        // A horas
    Hour: 60,       // A minutos
    Minute: 60,     // A segundos
    Second: 1000,   // A milisegundos
    Millisecond: 1  // Sin conversión
  };

  const units = ["Day", "Hour", "Minute", "Second", "Millisecond"];

  const unitIndex = units.indexOf(unit);
  if (unitIndex === -1) throw new Error(`Unidad de tiempo inválida: ${unit}`);

  let remainingValue = value;
  let result: number[] = [];

  // Calcular las unidades descendentes
  for (let i = unitIndex; i < units.length; i++) {
    let wholePart = Math.floor(remainingValue);
    result.push(wholePart);
    remainingValue = (remainingValue - wholePart) * conversionFactors[units[i]];
  }

  // Formato especial si es un día o más
  if (unit === "Day" || result.length === 5) {
    return `${result[0]}d ${result[1].toString().padStart(2, '0')}:${result[2].toString().padStart(2, '0')}:${result[3].toString().padStart(3, '0')}`;
  }

  // Formato estándar (horas:minutos:segundos:milisegundos)
  return result
    .map((val, index) => index === result.length - 1 ? val.toString().padStart(3, '0') : val.toString().padStart(2, '0'))
    .join(":");
}

function formatYAxisLabel(value: number, unit: string): string {
  const roundedValue = Math.floor(value); // Parte entera
  const decimalPart = value - roundedValue; // Parte decimal

  const unitSuffixes: Record<string, string> = {
    Millisecond: "ms",
    Second: "s",
    Minute: "min",
    Hour: "h",
    Day: "d"
  };

  // Si la parte entera es 0 y hay decimales, mostrar con 2 decimales
  if (roundedValue === 0 && decimalPart > 0) {
    return `${decimalPart.toFixed(2)} ${unitSuffixes[unit] || ""}`;
  }

  // Si el valor es mayor a 0, mostrarlo normalmente sin decimales
  return `${roundedValue} ${unitSuffixes[unit] || ""}`;
}

