import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone:true,
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {

  transform(offset: string | number | null | undefined, defaultValue: string = '-'): string {
    if (offset === null || offset === undefined) return defaultValue;

    // Convertir string a número si es necesario
    const numericOffset = typeof offset === 'string' ? parseInt(offset, 10) : offset;

    if (isNaN(numericOffset)) return defaultValue; // Si la conversión falla

    const timeZone = this.getTimeZoneByOffset(numericOffset);
    if (!timeZone) return `UTC${numericOffset >= 0 ? '+' : ''}${numericOffset}`;

    return new Intl.DateTimeFormat('en', {
      timeZone,
      timeZoneName: 'short'
    }).formatToParts(new Date()).find(part => part.type === 'timeZoneName')?.value || `UTC${numericOffset >= 0 ? '+' : ''}${numericOffset}`;
  }

  private getTimeZoneByOffset(offset: number): string | null {
    const timeZoneMap: { [key: number]: string } = {
      "-12": "Etc/GMT+12",
      "-11": "Pacific/Niue",
      "-10": "Pacific/Honolulu",
      "-9": "America/Anchorage",
      "-8": "America/Los_Angeles",
      "-7": "America/Denver",
      "-6": "America/Chicago",
      "-5": "America/New_York",
      "-4": "America/Caracas",
      "-3": "America/Buenos_Aires",
      "-2": "America/Noronha",
      "-1": "Atlantic/Azores",
      "0": "UTC",
      "1": "Europe/Madrid",
      "2": "Europe/Berlin",
      "3": "Europe/Moscow",
      "4": "Asia/Dubai",
      "5": "Asia/Karachi",
      "6": "Asia/Dhaka",
      "7": "Asia/Bangkok",
      "8": "Asia/Singapore",
      "9": "Asia/Tokyo",
      "10": "Australia/Sydney",
      "11": "Pacific/Noumea",
      "12": "Pacific/Fiji"
    };

    return timeZoneMap[offset] || null;
  }
}