import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Status, VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { FormCreateActions } from 'src/app/util/constants';
import { TerminalService } from './terminal.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { EditPerfilesComponent } from '../../../config/profile/edit-perfiles/edit-perfiles.component';
import { Coordinates } from 'src/app/models/coordinates.model';

@Component({
  selector: 'app-info-terminal',
  templateUrl: './info-terminal.component.html',
  styleUrls: ['./info-terminal.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class InfoTerminalComponent implements OnChanges {

  @Input('terminal') infoTerminal: VendingMachineAtento = new VendingMachineAtento;
  listPerfil: any[] = [];
  validProfiles: any[] = [];
  showMap = true;
  coordinates: Coordinates[] = [];
  STATUS_INACTIVE = Status.INACTIVE;

  // Controlador para saber qué panel está expandido
  isOpen: { [key: string]: boolean } = {
    jerarquia: true,
    info: true,
    estado: true,
    mapa: true,
    ubicacion: true,
  };

  constructor(
    private notifier: NotifierService,
    private services: TerminalService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<InfoTerminalComponent>,
    public dialog: MatDialog,
    public rol: RolHandlerService,
  ) {}


  ngOnChanges(): void {
    this.loadTerminal()
  }

  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }
  
  loadTerminal() {
    this.validProfiles = Array.isArray(this.infoTerminal.listPerfil)
      ? this.infoTerminal.listPerfil.filter(profile => profile !== null)
      : [];
    
    // this.vaildatePendings(this.infoTerminal.hasPending);
    this.setCoordinates();
  }

  // vaildatePendings(hasPending: boolean) {
  //   if (hasPending) {
  //     this.notifier.notify('info', this.translate.instant('warning.terminalHasPendings'));
  //   }
  // }

  onView(row: VendingMachineAtento): void {
    const editDialogRef: MatDialogRef<EditPerfilesComponent> = this.dialog.open(EditPerfilesComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
      data: row
    });
    editDialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed', result);
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  private  setCoordinates(): void {
    const { latitude, longitude, serialNumber, manufacID, modelNumber, commerce, swRev } = this.infoTerminal;
  
    // Verifica que las coordenadas sean válidas
    if (!latitude || !longitude) {
      this.coordinates = []; // Asigna un arreglo vacío si no hay coordenadas
      return;
    }
  
    // Construye el arreglo de coordenadas para pasarlo al mapa
    this.coordinates = [{
      lat: Number(latitude),
      lng: Number(longitude),
      type: 'pointer',
      label: serialNumber ?? 'No serial number', // Valor por defecto si serialNumber es undefined
      tooltip: [
        { label: this.translate.instant('monitoring.manufacID'), value: manufacID ?? '' },
        { label: this.translate.instant('monitoring.modelNumber'), value: modelNumber ?? '' },
      ]
    }];
  }

  onDelete(){
    const terminalId = this.infoTerminal.id;
    if(terminalId === undefined) return;

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.terminal; 
    dialogData.action = ElementAction.delete_masculino;
    dialogData.class = DialogClass.error; 
    dialogData.icon = 'trash-01' 

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialog.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.services.delete(terminalId);
        if (response?.status === 0) {
          this.notifier.notify('success', this.translate.instant('success.terminalDeleted'));
          this.dialogRef.close(FormCreateActions.SAVED)
        }
      }
    });
  } 
}
