import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'; // Usando ngx-translate para las traducciones
import { AuthService } from 'src/app/auth/auth.service';

@Pipe({
  name: 'operationPipe'
})
export class OperationPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}
   private authService  = inject(AuthService);
   private lang = this.authService.language.toUpperCase();

  transform(value: string): unknown {
    if (value === null || value === undefined) {
      return this.translate.instant('common.undefined'); 
    }
    let texto = this.translate.instant(`historico.operation.${value}`);
    
    switch(value) {
      case "38":
      case "39":
      case "40":
        // Alta= 38, mod = 39, delete = 40  region
        const jerarquiaValue2 = this.formatJerarquia(2);
        texto = texto.replace("{{LEVEL2}}", jerarquiaValue2);
        break;
    
      case "41":
      case "42":
      case "43":
        // Alta= 41, mod = 42, delete = 43  division
        const jerarquiaValue3 = this.formatJerarquia(3);
        texto = texto.replace("{{LEVEL3}}", jerarquiaValue3);
        break;
    
      case "74":
      case "75":
      case "76":
        // Alta= 74, mod = 75, delete = 76  lvl4
        const jerarquiaValue4 = this.formatJerarquia(4);
        texto = texto.replace("{{LEVEL4}}", jerarquiaValue4);
        break;
    
      case "77":
      case "78":
      case "79":
        // Alta= 77, mod = 78, delete = 79  lvl5
        const jerarquiaValue5 = this.formatJerarquia(5);
        texto = texto.replace("{{LEVEL5}}", jerarquiaValue5);
        break;
    
      default:
        // Otras operaciones si es necesario
        break;
    }

    // Traducir usando el valor numérico del enum
    return texto;
  }

  // Función para redondear el número a una cantidad específica de decimales
  private formatJerarquia(level: number): string {
    let userSession = this.authService.currentUserValue();
    let operator = userSession.operator;
    let labelLevel: string | undefined;
    let porDefecto: string = "";

      // Verificar el nivel y asignar el correspondiente label
    if (level === 2) {
      labelLevel = operator?.labelLevel2;    
      porDefecto=this.translate.instant('hierarchy.level_2')
    } else if (level === 3) {
      labelLevel = operator?.labelLevel3;
      porDefecto=this.translate.instant('hierarchy.level_3')
    } else if (level === 4) {
      labelLevel = operator?.labelLevel4;
      porDefecto=this.translate.instant('hierarchy.level_4')
    } else if (level === 5) {
      labelLevel = operator?.labelLevel5;
      porDefecto=this.translate.instant('hierarchy.level_5')
    }

    if (labelLevel) {
      try {
        // Parsear el JSON
        const labelObj = JSON.parse(labelLevel);
        console.log("labelObj",labelObj);
        // Verificar si la clave del idioma (lang) existe en el objeto
        if (labelObj && labelObj[this.lang]) {
          return labelObj[this.lang]; // Devolver la traducción según el idioma
          
        } else { 
          return porDefecto; // Si no hay traducción para el idioma, devolver cadena vacía
        }
      } catch (error) {
        console.error("Error parsing label JSON:", error);
        return porDefecto; // Si hay un error al parsear el JSON, devolver cadena vacía
      }
    }
   
    return porDefecto;
  }
}
