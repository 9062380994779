<main id="main" class="main">
  <div class="pagetitle">
    <span class="header-large">{{ 'stadisticsDetails.title' | translate }}</span>
  </div>

  <div class="filter-container">
    <app-filter *ngIf="canOpenModal"
    [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
  
    <app-filter   [class.disabled]="isDisabled"
       [isDateFilter]="true"  (onApply)="onFilterApplyDate($event)"></app-filter>
  </div>


  
  <ng-container *ngFor="let packageName of getKeys(groupedDataByPackageName)">

      <app-stadistic-details-item [packageName]="packageName"
        [data]="groupedDataByPackageName[packageName]" [variablesReport]="variablesReport"></app-stadistic-details-item>
  </ng-container>

  <ng-container *ngIf="!hasData">
    <app-table-empty-state></app-table-empty-state>
  </ng-container>
</main>