import { formatDate } from "@angular/common";
import { environment } from "src/environments/environment";
import { DATE_FORMATS, TIME_DEFINITIONS } from "./constants";

export function promiseTimeout(ms: number, promise: Promise<any>) {
    let id:any
    // Create a promise that rejects in <ms> milliseconds
    let timeout = new Promise((resolve, reject) => {
        id = setTimeout(() => {
            clearTimeout(id);
            reject('Timed out in ' + ms + 'ms.')
        }, ms)
    })

    // Returns a race between our timeout and the passed in promise
    return Promise.race([
        promise,
        timeout
    ]).then((result) => {
        clearTimeout(id)
        // We also need to pass the result back
        return result
    })
}

export function base64DecToArr(str: string): Uint8Array {
    return Uint8Array.from(atob(str), c => c.charCodeAt(0))
}

export function convertFileToBase64(file:File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(parseBase64Data(reader.result as string));
      reader.onerror = error => reject(error);
    });
}

const COMPARE_TEXT = 'base64';
const COMPARE_CHARACTER = ',';
export function parseBase64Data(data:string){
    const lastIndex = data.lastIndexOf(COMPARE_TEXT);
    let headlessText = data.slice((lastIndex + COMPARE_TEXT.length), data.length);
    if(headlessText.charAt(0) === COMPARE_CHARACTER){
        headlessText = headlessText.slice(1, headlessText.length);
    }
    return headlessText;
}

export function downloadFileFromBase64(fileName:string, file:string){
    const parseData = base64DecToArr(file);
    downloadFile(fileName, parseData);
}

export function extractVersion(fileName: string): string {
    const versionPattern = /(\d+\.\d+\.\d+[-\w]*)/; // Patrón para encontrar la versión
    const match = fileName.match(versionPattern);
    return match ? match[0] : ''; // Devuelve la versión o cadena vacía
}

export function isApkFile(file: File): boolean {
    const validMimeType = 'application/vnd.android.package-archive';
    const validExtension = '.apk';
      const isMimeTypeValid = file.type === validMimeType;
    // Verifica la extensión del archivo
    const isExtensionValid = file.name.toLowerCase().endsWith(validExtension);
      return isMimeTypeValid || isExtensionValid;
  }

  export function isCapFile(file: File): boolean {
    const validMimeType = 'application/vnd.fingerprint.cap';
    const validExtension = '.cap';
    const isMimeTypeValid = file.type === validMimeType;
    const isExtensionValid = file.name.toLowerCase().endsWith(validExtension);
    return isMimeTypeValid || isExtensionValid;
}

  
export function downloadFile(fileName:string, data:any){
    const fileBlob = new Blob([data]);
    const fileURL = URL.createObjectURL(fileBlob);
    const downloadButton:HTMLAnchorElement = document.createElement('a');
    downloadButton.href=  fileURL;
    downloadButton.setAttribute('download', fileName);
    downloadButton.click();
}

const CSV_CELLS_SEPARATOR = /,|;\W*/g;

interface DefaultObject { [key: string]: string; };
function setRowAsHeader(row: string, headers: string[]): void {
    row.split(CSV_CELLS_SEPARATOR).forEach((cell) => {
        const header = cell.replace('-','_').toLowerCase().trim();
        headers.push(header);
    });
}

function setRowAsCells(row: string, headers: string[], result: any[], invalidRows:any[]): void {
    let newObj:DefaultObject = {};
    let splittedRow = row.split(CSV_CELLS_SEPARATOR);
    if(splittedRow.length < headers.length) {
        invalidRows.push(row);
        return;
    };
    splittedRow.forEach((cell, i) => {
        newObj[headers[i]] = cell.trim() ?? "";
    });
    result.push(newObj);
}

export function parseCSVStringDataToArray(fileText: string) {
    const rows = fileText.split('\r\n').filter(item=> (item != ""));
    let headers: string[] = [];
    let newArray: DefaultObject[] = [];
    let invalidRows: string[] = [];
    rows.forEach((row: string, index: number) => {
        const validationEmpty = row.replace(CSV_CELLS_SEPARATOR,"");
        if(!validationEmpty) return;
        if (index === 0) {
            setRowAsHeader(row, headers);
        } else {
            setRowAsCells(row, headers, newArray, invalidRows)
        }
    });
    return { array: newArray, invalidRows: invalidRows };
}

// Método para convertir archivos en JSON
export function convertFileToJson(file: File): Promise<{ array: DefaultObject[], invalidRows:string[] }> {
return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
    const result = reader.result as string;
    const parsedResult = parseCSVStringDataToArray(result);
    resolve(parsedResult);
    };
    reader.onerror = error => reject(error);
});
}

export function isStringBlank(str: string | null | undefined): boolean {
    return !str || str.trim().length === 0;
}

export function parsedDateFromTerminalFormat(terminalDate:(string|null)): { date:string, hour:string } {
    if(terminalDate){
        const [terminalDay, terminalHour] = terminalDate.split('_');
        const year = terminalDay.slice(0,2),
            month = terminalDay.slice(2,4),
            day = terminalDay.slice(4,6);
        const hour = terminalHour.slice(0,2),
            minutes = terminalHour.slice(2,4);
    
        return { date: `${'20'+year}-${month}-${day}`, hour: `${hour}:${minutes}`};
    }
    return { date: '', hour:''} 
}

export function getDateFromTerminalFormat(terminalDate:(string|null)): Date | null {
    if(!terminalDate) return null;
    const dateHour = parsedDateFromTerminalFormat(terminalDate);
    const newDate = new Date(dateHour.date);
    const [ hour, minutes ] = dateHour.hour.split(':');
    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minutes));
    return newDate;
}

export function parseStringToUTCDate(value:string): Date {
    const [ date, time ] = value.split(" ");
    const [ day, month, year ] = date.split("-");
    const [ hour, min, secs ] = time.split(":");
    const utcDate = Date.UTC(Number(year), Number(month)-1, Number(day), Number(hour), Number(min), Number(secs)); 

    return new Date(utcDate); 
}

export function parseDateToFormat(value: string | Date, format: string): string {
    if(!value) return '';

    const toParseDate = new Date(value);
    return formatDate(toParseDate, format, 'es');
}

export function parseAndSetDateStart(startDate:string, startTime:string): (string | null) {
    if(!startDate) return null;

    const date = new Date(startDate);
    if(startTime){
        const [ hour, min ] = startTime.split(':');
        date.setHours(Number(hour));
        date.setMinutes(Number(min));
    }
    
    return parseDateToFormat(date, DATE_FORMATS.terminal);
} 

export function getDateToNow(value:(string | Date)):string{
    const today = new Date();
    const referenceDate = new Date(value);
    const difference = today.getTime() - referenceDate.getTime();

    if(difference > TIME_DEFINITIONS.weekInMs){
      const weekAgo = Math.round(difference / TIME_DEFINITIONS.weekInMs);
      return `${weekAgo}w`;
    }

    if(difference > TIME_DEFINITIONS.dayInMs){
      const daysAgo = Math.round(difference / TIME_DEFINITIONS.dayInMs);
      return `${daysAgo}d`;
    }

    if(difference > TIME_DEFINITIONS.hourInMs){
      const daysAgo = Math.round(difference / TIME_DEFINITIONS.hourInMs);
      const restMinutes = Math.round((difference%TIME_DEFINITIONS.hourInMs) / TIME_DEFINITIONS.minuteInMs);
      return `${daysAgo}hs ${restMinutes}ms`;
    }
 
    const minutesAgo = Math.round(difference / TIME_DEFINITIONS.minuteInMs);
    return `${minutesAgo}ms`;
}

export function getLastWeekFrom(value:string): Date {
    const dayTime = new Date(value).getTime();
    const weekAgo = dayTime - TIME_DEFINITIONS.weekInMs;
    return new Date(weekAgo);
}

export function getNextWeekFrom(value:string): Date {
    const dayTime = new Date(value).getTime();
    const weekAgo = dayTime + TIME_DEFINITIONS.weekInMs;
    return new Date(weekAgo);
}

export function getStartDate(value:string): Date {
    const date = new Date(value);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0,0,0);
}


export function getEndDate(value:string): Date {
    const date = new Date(value);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23,59,59);
}

export function getDurationOnMinutes(init:string, end:string): number {
    if (!init || !end) return 0;
    const initTime = new Date(init).getTime();
    const endTime = new Date(end).getTime();
    const diferenceTime = endTime - initTime;

    return Math.floor(diferenceTime / 60000); // 10000 -> 1min
}

export function conversionByteToMegaByte(bytes:number): number {
    let result:number = 0;
    result = bytes / 1000000; 
    return result;
}

export function olderThan(date?:(Date | string), amount?:number ): boolean {
    
   
    if(!date) return true;
    const compareTime = amount ?? environment.minutesDeactive;
    const connectionDate = parseStringToUTCDate(date as string);
    const fecha1 = new Date(connectionDate);    
    const fecha2 = new Date();
    const diff_m = Math.round((fecha2.getTime() - fecha1.getTime()) / TIME_DEFINITIONS.minuteInMs) // Diff in minutes
    console.log(">>>>>>>>"+date+">>>>"+amount+">>>"+diff_m+";;fecha1="+fecha1+";;fecha2="+fecha2)

    return diff_m >= compareTime;
}

export function compareDates(date1:(Date | string), date2:(Date | string)) {
    const time1 = new Date(date1).getTime();
    const time2 = new Date(date2).getTime();
    return time1 - time2; // Sort in ascending order
}

export class ByteUtil {
    private static readonly decoder = new TextDecoder("utf-8")
    private static readonly encoder = new TextEncoder()

    static bytesToStr(buf: BufferSource) {
        return this.decoder.decode(buf)
    }

    static strToBytes(str: string) {
        return this.encoder.encode(str);
    }

    /* Little Endian es el protocolo por defecto con el Touchless, pero en algunos sitios como la actualización
    de firmware se hace siguiendo el protocolo XMODEM-1k que usa Big Endian. Por eso permitimos por parametro la configuracion
    */
    static toBytesInt32(num: number, littleEndian = true) {
        let arr = new ArrayBuffer(4); // an Int32 takes 4 bytes
        let view = new DataView(arr);
        view.setUint32(0, num, littleEndian); // byteOffset = 0; litteEndian = true
        return new Uint8Array(arr);
    }

    static toBytesInt16(num: number, littleEndian = true) {
        let arr = new ArrayBuffer(2); // an Int16 takes 2 bytes
        let view = new DataView(arr);
        view.setUint16(0, num, littleEndian); // byteOffset = 0; litteEndian = true
        return new Uint8Array(arr);
    }

    static toBytesInt8(num: number) {
        let arr = new ArrayBuffer(1); // an Int16 takes 1 byte
        let view = new DataView(arr);
        view.setUint8(0, num); // byteOffset = 0
        return new Uint8Array(arr);
    }

    static bytesToHex(buffer: Uint8Array): string { // buffer is an ArrayBuffer
        return Array.prototype.map.call(buffer, x => ('00' + x.toString(16)).toUpperCase().slice(-2)).join('');
    }

    static toUInt8Array(value: string){
        let result = [];
        for(let i = 0; i < value.length; i+=2)
        {
            result.push(parseInt(value.substring(i, i + 2), 16));
        }
        return Uint8Array.from(result)
    }
 
}