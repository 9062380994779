<div #filter>
    <ng-container *ngIf="!appliedFilters.length">
        <app-filter-trigger [isDateFilter]="isDateFilter" (onClick)="triggerDropDown($event)"></app-filter-trigger>
    </ng-container>
    
    <ng-container *ngIf="appliedFilters.length">
        <app-filter-display 
            [isDateFilter]="isDateFilter"
            [filters]="appliedFilters" 
            (onTrigger)="triggerDropDown($event)"
            (onRemove)="removeFilter($event)"
        ></app-filter-display>
    </ng-container>
</div>
