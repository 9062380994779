export class Operator {
  id: number | undefined;
  name!: string ;
  cod!: string;
	idEntorno: number | undefined;

  labelLevel2!: string | "";
  labelLevel3!: string | "";
  labelLevel4!: string | "";
  labelLevel5!: string | "";

}


