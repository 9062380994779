<app-header *ngIf="(isAuthenticated$ | async)" ></app-header>
<app-sidebar *ngIf="(isAuthenticated$ | async)"></app-sidebar>
<app-topbar *ngIf="_router.url !== '/terms-conditions' && (isAuthenticated$ | async) === false " ></app-topbar>


<router-outlet></router-outlet><notifier-container></notifier-container>

<!-- loading spinner -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" color="#fff"  size="medium" [type]="typeSelected"></ngx-spinner>

<!-- footer -->
<app-footer *ngIf="!(_router.url === '/pages-register' || _router.url === '/reset-password' || _router.url === '/pages-login' || _router.url === '/pages-error404' || _router.url === '/welcome')" ></app-footer>

