<div mat-dialog-title class="ct-dialog-header">
    <span class="header-large" >{{ 'users.add' | translate }}</span>

    <button class="ct-button ct-button--tertiary" (click)="onClose()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>
</div>

<form [formGroup]="userForm">
    <mat-dialog-content class="ct-dialog-content">
        <section class="section">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'users.nickName' | translate }}<span class="obligatorio"></span></mat-label>
                            <input formControlName="username" matInput type="text" required>
                            <mat-error *ngIf="userForm.get('username')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                            <mat-error *ngIf="userForm.get('username')?.hasError('maxLength')">{{ 'error.maxLength' | translate }}</mat-error>
                            <mat-error *ngIf="userForm.get('username')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
                        
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'users.name' | translate }}<span class="obligatorio"></span></mat-label>
                            <input formControlName="name" matInput type="text" required>
                            <mat-error *ngIf="userForm.get('name')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                            <mat-error *ngIf="userForm.get('name')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
                            <mat-error *ngIf="userForm.get('name')?.hasError('maxLength')">{{ 'error.maxLength' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'users.email' | translate }}<span class="obligatorio"></span></mat-label>
                            <input formControlName="email" matInput type="text" required>
                            <mat-error *ngIf="userForm.get('email')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                            <mat-error *ngIf="userForm.get('email')?.hasError('maxLength')">{{ 'error.maxLength' | translate }}</mat-error>
                            <mat-error *ngIf="userForm.get('email')?.hasError('pattern')">{{ 'error.email' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_3)">
                    <div class="form-group">
                        <filterable-select #hierarchyLevelSelect formControlName="hierarchyLevel" [options]="hierarchyOptions" 
                        [title]="'users.hierarchy' | translate" [required]="true"></filterable-select> 
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
                    <div class="form-group">
                        <filterable-select #operatorSelect formControlName="idOperator" [options]="listaOpe" 
                        [title]="'users.operator' | translate" displayField="name" valueField="id" [required]="true"></filterable-select> 
                    </div>
                </div>
                <div class="col" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_2)">
                    <div class="form-group">
                        <filterable-select #clientSelect formControlName="idClient" [options]="clientsList" 
                        [title]="'users.client' | jerarquiaLevelTranslate: hierarchy.level.LEVEL_2" displayField="name" valueField="id" [required]="true"></filterable-select>
                    </div>
                </div>
                <div class="col" *ngIf="hierarchy.configureUsersByLevel(hierarchy.level.LEVEL_3)">
                    <div class="form-group">
                        <filterable-select #subClientSelect formControlName="idSubClient" [options]="subClientsList" 
                        [title]="'users.subClient' | jerarquiaLevelTranslate: hierarchy.level.LEVEL_3" displayField="name" valueField="id" [required]="true"></filterable-select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <filterable-select #rolSelect formControlName="idRol" [options]="listaRol" 
                        [title]="'users.rol' | translate" displayField="name" valueField="id" [required]="true"></filterable-select> 
                    </div>
                </div>
            </div>
        </section>
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="botonera">
        <button class="ct-button ct-button--secondary" (click)="onClose()">{{ 'common.cancel' | translate }}</button>
        <ng-container *ngIf=" rol.canByInput(rol.functIds.edit_users)">
            <button class="ct-button ct-button--primary" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
        </ng-container>
    </mat-dialog-actions>
</form>