import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class AlertsService {

  constructor(public merchantRest : RestService,public authService:AuthService) {}
  
  async find(_body: StadisticBean ){

    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.idOperator=userSession.operator?.id
      if (userSession.client){
        _body.idClient=userSession.client?.id
      }
    }   
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListAlert/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
  
    async findFileToExport(_body: StadisticBean){
  
      
    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.idOperator=userSession.operator?.id
      if (userSession.client){
        _body.idClient=userSession.client?.id
      }
    }   
    
          let value = await this.merchantRest.commonRestCallFile(_body,'getFileListAlert',true,true )   
        .catch(err => {
          return null
        })
        
      return value;   
    }

  


  async countTotal(_body: StadisticBean){

    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.idOperator=userSession.operator?.id
      if (userSession.client){
        _body.idClient=userSession.client?.id
      }
    }   
    _body.filterAndPagBean= new FilterAndPagBean(_body.filterAndPagBean?.orderSentido,_body.filterAndPagBean?.order,_body.filterAndPagBean?.filterGeneric,null,null,null);

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListAlertCount/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
 


}