<main id="main" class="main">
    <div class="my-2 d-flex justify-content-between">
        <span class="header-large">{{ 'historyUser.title' | translate }}</span>
        
        <button class="ct-button ct-button--secondary" (click)="refrescar()">
            <mat-icon class="ct-icon" svgIcon="refresh-ccw-01"></mat-icon> 
            <span class="hide-on-mobile">{{'common.refresh' | translate }}</span>
        </button>
    </div>

    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

    <div id="tableDescription" hidden>{{ 'historyUser.tableDescription' | translate }}</div>

    <div class="mat-elevation-z2 tablaPersonal">
        <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort
            (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
                <td mat-cell *matCellDef="let element" class="button" style="width: 60px;">
                    <ng-container *ngIf="!isOnTerminalContext && element.vm?.id">
                        <button *ngIf="rol.canByInput(rol.functIds.view_terminals)" matTooltip="{{ 'tooltips.viewTerminal' | translate }}" (click)="onView(element)" mat-icon-button class="iconoTabla">
                            <mat-icon>phone_android</mat-icon>
                        </button> 
                    </ng-container>
                </td>
            </ng-container>

            <!-- dateCreate Column -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'historyUser.dateCreate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
            </ng-container>

            <!-- idVm Column -->
            <ng-container matColumnDef="vm.id">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'historyUser.terminal' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.vm?.serialNumber }} </td>
            </ng-container>

            <!-- operator Column -->
            <ng-container matColumnDef="idOperator">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'historyUser.operator' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.idOperator | levelName:'LEVEL_1' }} </td>
            </ng-container>
            

            <!-- idUser Column -->
            <ng-container matColumnDef="user.name">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'historyUser.user' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.user?.name}} </td>
            </ng-container>

            <!-- operation Column -->
            <ng-container matColumnDef="operation">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'historyUser.operation' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element">  {{element.operation | operationPipe}} </td>
            </ng-container>

            <!-- stringLog Column -->
            <ng-container matColumnDef="stringLog">
                <th mat-header-cell *matHeaderCellDef style="max-width: 250px;">
                    <div mat-sort-header >{{ 'historyUser.stringLog' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" style="max-width: 250px;" >
                    <span title="{{element.stringLog}}" [innerHTML]="element.stringLog | historicotestPipe : element.operation"></span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Empty state -->
        <ng-container *ngIf="!dataSource.data.length">
            <app-table-empty-state></app-table-empty-state>
        </ng-container>

        <button class="ct-button ct-button--icon" (click)="exportTable()">
            <mat-icon svgIcon="file-download-03"></mat-icon>
          </button> 

        <mat-paginator [length]="pageLength" 
            [pageSize]="pageSize" 
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" 
            showFirstLastButtons 
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</main>