
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions } from 'src/app/util/constants';
import { Incidence } from 'src/app/models/incidence.model';
import { IncidenceService } from '../incidence.service';
import { UtilService } from 'src/app/services/util.service';
import { Operator } from 'src/app/models/operator.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { MonitoringService } from '../../../monitoring/monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { NotifierService } from 'angular-notifier';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { FilterableSelectComponent } from 'src/app/components/filterableSelect/filterableSelect.component';


@Component({
  standalone:true,
  imports:[
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyDialogModule,
    MatIconModule,
    FilterableSelectComponent
  ],
  selector: 'app-form-new-incidence',
  templateUrl: './form-new-incidence.component.html',
  styleUrls: ['./form-new-incidence.component.css']
})
export class FormNewIncidenceComponent implements OnInit {

  isAdmin: boolean = true;
  registerForm!: FormGroup;
  disabledTerminal:boolean = false;
  isCloseIncidence:boolean = false;
  operatorList:Operator[] = [];
  terminals: VendingMachineAtento[] = [];
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  public incidenceTypes: { value:string; label:string }[] = [
    {value:"0" , label: this.translate.instant('incidences.types.other') },
    {value:"1" , label: this.translate.instant('incidences.types.suggestion') },
    {value:"3" , label: this.translate.instant('incidences.types.terminalBreakdown') },
    {value:"4" , label: this.translate.instant('incidences.types.nonActiveTerminal') },
  ]

  constructor(@Inject(MAT_DIALOG_DATA) public data: {incidencia:Incidence, vm:VendingMachineAtento, close:true },
    private formBuilder: FormBuilder,
    private service: IncidenceService,
    private utilService : UtilService,
    private dialogRef: MatDialogRef<FormNewIncidenceComponent>,
    private translate:TranslateService,
    private dialog: MatDialog,
    private monitoring:MonitoringService,
    private notifier:NotifierService
  ) {}
  
  async ngOnInit(): Promise<void> {
    this.isCloseIncidence = !!this.data.close;
    if (this.data.vm) this.disabledTerminal = true;

    const isAdmin = this.utilService.isAdmin();

    this.registerForm = this.formBuilder.group({
      type: [{ value: '', disabled: this.isCloseIncidence }, Validators.required],
      numTer: [{ value: '', disabled: this.disabledTerminal }, Validators.required], 
      commet: [{ value: '', disabled: this.isCloseIncidence }, Validators.compose([Validators.required, Validators.maxLength(400)])],
      operator: [{ value: '', disabled: (!isAdmin || this.disabledTerminal || this.isCloseIncidence) }, Validators.required],
    });
    // Si se quiere cerrar la incidencia hay que escribir un comentario
    if(this.data.close) {
      this.registerForm.get('resolution')?.addValidators(Validators.required);  
    }

    this.registerForm.get('operator')?.valueChanges.subscribe((operatorId)=>{
      if(operatorId) this.loadTerminals(operatorId);
    })

    let terminaID, type, commet, operator;
    operator = this.utilService.getOperatorId();
    
    if(this.data.vm){
        terminaID = this.data.vm.id;
        operator = this.data.vm.idOperator;
    }

    this.registerForm.patchValue({
      type: type,
      numTer: terminaID, 
      commet: commet,
      operator: operator ,  // Desactivar si hay una máquina
      resolution: '',
    });

    this.loadOperators();
  }


  private loadOperators(){
    // Cargar operadores
    this.utilService.findOperators().subscribe((operators: Operator[]) => {
      this.operatorList = operators;
    });
  }
  private loadTerminals(operatorId:number){
    const request = new UsersConsoleRequest;
    request.idOperator = operatorId;

    this.monitoring.find(request).then((terminals) => {
      this.terminals = terminals?.data ?? [];
    });
  }

  onFormSubmit(): void {
    if (this.registerForm.invalid){ 
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return
    }

    const data = {...this.registerForm.value};
    let objNew : Incidence = new Incidence();
    objNew.idOperator = data['operator']

    objNew.description=data['commet']  
    objNew.idVM=this.registerForm.controls['numTer'].value
    objNew.type=data['type']  
    objNew.serialNumber = this.terminals.find((item)=> item.id === objNew.idVM)?.serialNumber;

    const dialogData = new ConfirmDialogData(); 
    dialogData.action = ElementAction.create_femenino;
    dialogData.element = Element.incidence;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          let val:any;

          if(this.isCloseIncidence){
            val = await this.service.closeIncidence(objNew)
          }else{
            objNew.idOperator = this.registerForm.controls['operator'].value
            val = await this.service.addUpdate(objNew)
          }

          if(val?.status>=0){
            this.dialogRef.close(FormCreateActions.SAVED);
          }

      }
    });

  }

  onClose(): void {
    this.dialogRef.close(FormCreateActions.EXIT);
  }
}
