import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectChange, MatLegacySelectModule } from '@angular/material/legacy-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApexOptions, NgApexchartsModule } from 'ng-apexcharts';
import { AuthService } from 'src/app/auth/auth.service';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { ChartType, StadisticDetail } from 'src/app/models/StadisticDetails.model';
import { Users } from 'src/app/models/users.model';
import { VariablesReport } from 'src/app/models/variablesReport.model';
import { chartUtilConstructor, validateJsonAttributes } from 'src/app/util/chartsutils';

@Component({
  standalone: true,
  imports: [CommonModule, NgApexchartsModule, TranslateModule, MatFormFieldModule, MatInputModule, MatLegacySelectModule,TableEmptyStateComponent],
  selector: 'app-stadistic-details-item',
  templateUrl: './stadistic-details-item.component.html',
  styleUrls: ['./stadistic-details-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})

export class StadisticDetailsItemComponent {
  @Input() packageName!: string;
  @Input() data!: StadisticDetail[];
  @Input() variablesReport!: VariablesReport[];
  timeUnits = [
    { value: 'Millisecond', label: 'stadisticsDetails.millisecond' },
    { value: 'Second', label: 'stadisticsDetails.second' },
    { value: 'Minute', label: 'stadisticsDetails.minute' },
    { value: 'Hour', label: 'stadisticsDetails.hour' },
    { value: 'Day', label: 'stadisticsDetails.day' }
  ];
  
  openSelectUnit: boolean = false;
  groupedDataByPackageAndType: Record<
    string,
    Record<string, { data: StadisticDetail[]; chartOptions: ApexOptions;   isValid?: boolean;  errorMenssaje?: string;  }>
  > = {};
  chartOptions: Record<string, Record<string, ApexOptions>> = {};

  userSession?: Users;
  selectedUnit: string = 'Minute'; // Establecer valor por defecto


  constructor(private readonly translate: TranslateService,
        private readonly authService:AuthService
  ) { }



  ngOnInit(): void {
    this.processData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.processData();
    }
  }

  processData(): void {
    this.groupedDataByPackageAndType = this.data.reduce(
      (acc: Record<string, Record<string, { data: StadisticDetail[]; chartOptions: ApexOptions }>>, item: StadisticDetail) => {
        const packageName = this.packageName;
        if (!acc[packageName]) {
          acc[packageName] = {};
        }
        const type = item.type;
        if (!acc[packageName][type]) {
          acc[packageName][type] = { data: [], chartOptions: {} };
        }
        acc[packageName][type].data.push(item);
        return acc;
      },
      {}
    );
    this.parseGroupedData();
  }

  onSelectChange(event: MatLegacySelectChange, packageName: string, type: string): void {
    this.userSession = this.authService.currentUserValue();
    let lang = this.userSession.lang?.toUpperCase();
    const options = this.createChartOptions(this.groupedDataByPackageAndType[packageName][type].data,
      this.groupedDataByPackageAndType[packageName][type].data[0].type, 
      this.groupedDataByPackageAndType[packageName][type]?.data[0]?.chartType || ChartType.LINE, 
      lang, this.groupedDataByPackageAndType[packageName][type]?.data[0]?.unit, event.value);
      this.groupedDataByPackageAndType[packageName][type].chartOptions = options;
  }
  


  parseGroupedData(): void {

    this.userSession = this.authService.currentUserValue();
    let lang = this.userSession.lang?.toUpperCase();
    Object.keys(this.groupedDataByPackageAndType).forEach((packageName) => {
      Object.keys(this.groupedDataByPackageAndType[packageName]).forEach((type) => {
        const parsedItems = this.groupedDataByPackageAndType[packageName][type].data.map((item) => {
          return {
            ...item,
            parsedData: JSON.parse(item.data) ,
            data: JSON.parse(item.data) 
            // ver aqui data, la idea es persitir nuestro objeto para poner hacer un cambio por select
          };
        });
        // validacion del json (dataItems a solo numericos).
        if(validateJsonAttributes(parsedItems).isValid){
          this.openSelectUnit = !!parsedItems[0]?.unit;
          const options = this.createChartOptions(parsedItems,parsedItems[0].type, this.groupedDataByPackageAndType[packageName][type]?.data[0]?.chartType || ChartType.LINE, lang);
          this.groupedDataByPackageAndType[packageName][type].data = parsedItems;
          this.groupedDataByPackageAndType[packageName][type].chartOptions = options;
          this.groupedDataByPackageAndType[packageName][type].isValid = true;
        }else{
          this.groupedDataByPackageAndType[packageName][type].isValid = false;
          this.groupedDataByPackageAndType[packageName][type].errorMenssaje = this.translate.instant(validateJsonAttributes(parsedItems).error!);
        }
        
      });
    });
  }



  createChartOptions(dataItems: any[], title: string, type: string, lang:string | undefined,unitTimeFrom?: string, unitTimeTo?: string): ApexOptions {

    let options = {
      chart: {
        type: (type === 'bar-horizontal' || type === 'bar-vertical') ? 'bar' : type.toLowerCase(),
        height: 350,
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.translate.instant(`stadisticsDetails.date`)
        },
      },
      yaxis: {
        title: {
          text: this.translate.instant(`stadisticsDetails.quantity`)
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
        },
      },
      stroke: {
        curve: 'smooth',
      },
      
      legend: {
        position: 'top',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      fill: {
        type: 'solid'
      },
    } as ApexOptions;


    if (type.toLowerCase() === ChartType.CM_BATTERY) title = this.translate.instant(`stadisticsDetails.battery`);
    return chartUtilConstructor(type.toLowerCase(), dataItems, title,options, lang, this.variablesReport, unitTimeFrom, unitTimeTo);
  }

  redimensionar(id: string) {
    let big = document.getElementById(id);
    if (big) {
      let hasClase2 = big.classList.contains('graficaBig');

      if (hasClase2) {// si esta expandido lo contraemos

        for (let el of document.querySelectorAll<HTMLElement>('.grafica')) {
          el.style.display = 'block';
          el.classList.remove("graficaBig");
        }

        for (let el of document.querySelectorAll<HTMLElement>('.expand')) {
          el.style.display = 'block';
        }
        for (let el of document.querySelectorAll<HTMLElement>('.contract')) {
          el.style.display = 'none';
        }

      } else {
        //agrandamos la grafica
        for (let el of document.querySelectorAll<HTMLElement>('.grafica')) {
          el.style.display = 'none';
          el.classList.remove("graficaBig");
        }
        for (let el of document.querySelectorAll<HTMLElement>('.expand')) {
          el.style.display = 'none';
        }
        for (let el of document.querySelectorAll<HTMLElement>('.contract')) {
          el.style.display = 'block';
        }

        big.style.display = 'block';
        big.classList.add("graficaBig");
      }
    }

    this.parseGroupedData();
  }



  getKeys(obj: Record<string, any>): string[] {
    return Object.keys(obj);
  }



}
