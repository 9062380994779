import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions } from 'src/app/util/constants';
import { NotifierService } from 'angular-notifier';
import { Client } from 'src/app/models/client.model';
import { Operator } from 'src/app/models/operator.model';
import { OperatorService } from '../../operator/operator.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-form-new-operator',
  templateUrl: './form-new-operator.component.html',
  styleUrls: ['./form-new-operator.component.css']
})
export class FormNewOperatorComponent implements OnInit {
  registerForm!: FormGroup;
  listCommerce: string[] = [];

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  private operatorBBDD?: Operator;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { operatorId:number },
    private formBuilder: FormBuilder, 
    private notifier: NotifierService,
    private service: OperatorService,
    public dialogRef: MatDialogRef<FormNewOperatorComponent>,
    public dialog: MatDialog,
    private translate: TranslateService,
    private utils:UtilService
  ) {}

  async ngOnInit(): Promise<void> {
    this.registerForm = this.formBuilder.group({
      nombre: [null, Validators.required],
      cod: [],
    });

    if(this.data) this.cargar()
  }

  async cargar(): Promise<void> {
    this.operatorBBDD = await this.service.getItem(this.data.operatorId);
    if (this.operatorBBDD) {
      this.registerForm.patchValue({
        nombre: this.operatorBBDD.name,
        cod: this.operatorBBDD.cod,
      });
    }
  }

  onFormSubmit(): void {
    if (this.registerForm.invalid){
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    const data = this.applyFormats(this.registerForm);
    let objNew: Operator = new Operator();
    objNew.name = data['nombre'];
    objNew.cod = data['cod'];
    if (this.operatorBBDD) objNew.id = this.operatorBBDD.id as number;
  
    const dialogData = new ConfirmDialogData();

    dialogData.element = Element.operator; 
    dialogData.action = ElementAction.create_femenino;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) this.saveOperator(objNew)
    });
  }

  async saveOperator(objNew:Operator){
    const val = await this.service.addUpdate(objNew);
    if (val!.status >= 0) {
      this.notifier.notify('success', this.translate.instant('success.addOperator'));
      this.utils.getRolsAndOperators();
      this.dialogRef.close(FormCreateActions.SAVED);
    }
  }

  onClose(): void {
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body: any = {};
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}
