import { CommonModule } from '@angular/common';
import { NgApexchartsModule, ChartComponent } from 'ng-apexcharts';
import { Component,ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Input, SimpleChanges } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { StadisticService } from '../stadistic.service';
import moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue } from 'src/app/models/FilterAndPagBean';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { StadisticDetailsItemComponent } from './components/stadistic-details-item/stadistic-details-item.component';
import { StadisticDetail } from 'src/app/models/StadisticDetails.model';
import { StadisticBeanVM } from 'src/app/models/stadisticBean.model';
import { Operator } from 'src/app/models/operator.model';
import { TxtGraficaService } from '../../personalizacion/texto-graficas/txtGrafica.service';
import { VariablesReport } from 'src/app/models/variablesReport.model';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';



@Component({
  standalone: true,
  imports: [CommonModule, NgApexchartsModule,TranslateModule,FilterComponent,StadisticDetailsItemComponent,TableEmptyStateComponent],
  selector: 'app-stadistic-details',
  templateUrl: './stadistic-details.component.html',
  styleUrls: ['./stadistic-details.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush

})
export class StadisticDetailsComponent {
    @Input() data!: FilterableValue[];
    canOpenModal = true;

  hasData: boolean = false;

  variablesReport: VariablesReport[] = [];

  groupedDataByPackageName: Record<string, StadisticDetail[]> = {}; 

  isDisabled = true;
  filterColumns: FilterField[] = []
  filterList: FilterableValue[] = [];
  filterListDate: FilterableValue[] = [];

  isAdmin:boolean = true;
  hashLecturas = new Map()

  constructor(private utilServicios: UtilService,  
    private terminalService: MonitoringService, 
    private authService:AuthService,
    private stadisticService :StadisticService,
    private translate:TranslateService,
    public servicios:TxtGraficaService,
    private hierarchy:HierarchyHandlerService,private cdr: ChangeDetectorRef  ) {}

  ngAfterViewInit(): void {
    this.lanzarLLamada();
  }
  async ngOnInit(): Promise<void> {
    //Terminal
    this.canOpenModal = !this.data || Object.keys(this.data).length === 0;
    let vmsList = await this.terminalService.find(new UsersConsoleRequest);
    const vmOptions = vmsList?.data?.map((vm:VendingMachineAtento)=>{ return { label:vm.serialNumber ?? '', value:vm.id }});
    this.filterColumns.push(new FilterField('idVM', 'stadisticsFunctionally.idVendingMachine', 'number', vmOptions));
    this.hierarchy.getFilterFieldsAsync(this.filterColumns);
    this.isAdmin = await this.utilServicios.isAdmin();
    let variables= await this.servicios.find(undefined);
    this.variablesReport = variables?.data;
    if(this.isAdmin) await this.cargar();
  }


ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.filterList = this.data;
      this.lanzarLLamada();
    }
  }

  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada(){
    const request = new StadisticBeanVM;
    
    const selectedOpe = this.getFilterableValue('idOperator');
    if (selectedOpe) request.idOperator = selectedOpe.value as number;
    const selectedTerminal = this.getFilterableValue('idVM');
    if (selectedTerminal) request.idVM = selectedTerminal.value as number;

    // no filtrar la primera carga
    if(!this.filterList.length) {
      this.isDisabled = true;
      this.hasData = false;
      this.groupedDataByPackageName = {};
      return;
    }
    this.isDisabled = false;
    request.typeReport=50//valor fijo para graficas del TMS
    let userSession = this.authService.currentUserValue();
    request.idEntorno = userSession?.entorno?.id;

    this.setDateFilter(request);

    this.stadisticService.findDetails(request).subscribe({
      next: (response) => {
        if (response?.data) {
          this.processData(response.data);
        }
      },
      error: (err) => {
        console.error('Error fetching details:', err);
      }
    });
    
  }
  processData(dataItems: StadisticDetail[]): void {
    // Transformar fechas al formato timestamp
    const transformedItems = dataItems.map((item) => ({
      ...item,
      period: this.toISODate(item.period),
    }));
  
    // Agrupaciones por "packageName"
    this.groupedDataByPackageName = transformedItems.reduce(
      (acc: Record<string, StadisticDetail[]>, item: StadisticDetail) => {
        const type = item.packageName;
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(item);
        return acc;
      },
      {}
    );
    this.hasData = dataItems.length > 0;

    this.cdr.detectChanges();
    console.log("Grouped Data by PackageName with Transformed Timestamps:", this.groupedDataByPackageName);
  }
  
  toISODate(dateString: string): string {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-');
    return `${year}-${month}-${day}T${timePart}Z`; // Formato ISO
  }
  
  setDateFilter(request:StadisticBeanVM){
    this.filterListDate.forEach((filter)=>{
      if(filter.column === 'date'){
        if(filter.operation === 'LESS_THAN_OR_EQUAL') request.dateEnd = new Date(filter.value as number);
        if(filter.operation === 'GREATER_THAN_OR_EQUAL') request.dateIni = new Date(filter.value as number);
      }
    });
    request.dateIni = request.dateIni ?? (request.dateEnd ? moment(request.dateEnd).startOf("month") : moment(new Date()).date(1));
    request.dateEnd = request.dateEnd ?? moment(request.dateIni).endOf("month").endOf("day").toDate();
  }

  


  public filteredList1:any


 
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.lanzarLLamada();
  }
 

  onFilterApplyDate(appliedFilters:AppliedFilter[]){
    this.filterListDate = [];
    this.filterListDate = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      // seteamos la colunma a buscar
      return new FilterableValue("date", filter.value, filter.type, filter.operation);
    });
    this.lanzarLLamada();
  }

  getKeys(data: Record<string, StadisticDetail[]>): string[] {
    return Object.keys(data);
  }
  
  get defaultLocale(){
    return 'es'
  }

  get chartLocale(){
    return  {
        name: 'es',
        options: {
          toolbar: {
            donwload:this.translate.instant('common.downloadSVG'),
            exportToSVG: this.translate.instant('common.downloadSVG'),
            exportToPNG: this.translate.instant('common.downloadPNG'),
            exportToCSV: this.translate.instant('common.downloadCSV'),
          }
        }
    };
  }

  async cargar(){   
    //operadores
    this.utilServicios.findOperators().subscribe((operators:Operator[]) => {
      const operationsOptions = operators.map((op:Operator)=>{ return { label:op.name, value:(op.id as number) }});
      this.filterColumns.push(new FilterField('idOperator', 'stadisticsFunctionally.idOperator', 'number', operationsOptions));
    });
  }
}
