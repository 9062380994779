<section class="form-container">
    <div class="form-header">
        <h2>{{ 'filter.addDate' | translate }}</h2>
    </div>
    
    <div class="form-body">
        <form [formGroup]="filterForm">
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label> {{ 'filter.field' | translate }}</mat-label>
                    <mat-select formControlName="field" required>
                        <mat-option *ngFor="let field of fields" [value]="field">{{ field.label| translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="filterForm.get('field')?.hasError('required')">
                    {{ 'error.required.field' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            
     

            <div class="form-group">
                <ng-container *ngIf="hasOptions">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'filter.compareValue' | translate }}</mat-label>
                        <div class="date-range-container">
                            <mat-date-range-input [formGroup]="filterForm" [rangePicker]="picker">
                                <input matStartDate formControlName="startDate" 
                                    [placeholder]="'filter.placeholder.startDate' | translate">
                                <input matEndDate formControlName="endDate" 
                                    [placeholder]="'filter.placeholder.endDate' | translate">
                            </mat-date-range-input>
                    
                            <!-- Ícono del selector de fecha -->
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        </div>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        <mat-error *ngIf="filterForm.get('startDate')?.hasError('required') || filterForm.get('endDate')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </ng-container>
            </div>
            

             <!-- Botón de Enviar -->
            <div class="botonera">
                <button class="ct-button ct-button--secondary" (click)="dialog.close()">{{ 'common.cancel' | translate }}</button>
                <button class="ct-button ct-button--primary" (click)="onFormSubmit()" [disabled]="filterForm.invalid">{{ 'common.search' | translate }}</button>
            </div>
        </form>
    </div>
</section>