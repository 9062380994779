<div mat-dialog-title class="ct-dialog-header">
    <span class="header-large">{{ 'newAlert.title' | translate }}</span>
    
    <button class="ct-button ct-button--tertiary" (click)="onClose()">
        <mat-icon class="ct-icon" svgIcon="x"></mat-icon>
    </button>
</div>



<form [formGroup]="registerForm">
    <mat-dialog-content class="ct-dialog-content">
        <section class="section">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <!-- Hierarchy Form-->
                            <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
                        </div>
                    </div>
                </div>

                 <!--
                 <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <filterable-select formControlName="type" [options]="listaAlertTypes" 
                                [title]="'newAlert.type' | translate" [required]="true"></filterable-select>
                        </div>
                    </div>
                </div>
                -->
               <div class="row"> 
                    <div class="col-6">
                        <filterable-select formControlName="padreForm" [title]="'newAlert.padre' | translate" 
                            [options]="listFather" valueField="padre" displayField="textoVo"  [required]="true"
                        ></filterable-select> 
                    </div>
                    <div class="col-6">
                        <filterable-select formControlName="hijoForm" [title]="'newAlert.hijo' | translate" 
                            [options]="listSons" valueField="value" displayField="textoVo" [required]="true"></filterable-select> 
                    </div>              
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'newAlert.operation' | translate }}<span class="obligatorio"></span></mat-label>
                                <mat-select formControlName="operacion">
                                    <mat-option value=">">{{ 'newAlert.operationType.greater' | translate }}</mat-option>
                                    <mat-option value="<">{{ 'newAlert.operationType.less' | translate }}</mat-option>
                                    <mat-option value="=">{{ 'newAlert.operationType.equal' | translate }}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="registerForm.get('operacion')?.hasError('required')" i18n>{{ 'error.required.field' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'newAlert.value' | translate }}</mat-label>
                                <input formControlName="value" matInput type="text">
                                <mat-error *ngIf="registerForm.get('value')?.hasError('required')" i18n>{{ 'error.required.field' | translate }}</mat-error>
                                <mat-error *ngIf="registerForm.get('value')?.hasError('pattern')" i18n>{{ 'error.pattern' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'newAlert.priority' | translate }}</mat-label>
                                <mat-select formControlName="priority">
                                    <mat-option value="3">{{ 'newAlert.priorityType.high' | translate }}</mat-option>
                                    <mat-option value="2">{{ 'newAlert.priorityType.medium' | translate }}</mat-option>
                                    <mat-option value="1">{{ 'newAlert.priorityType.low' | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
        </section>
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="botonera">
        <button class="ct-button ct-button--secondary" (click)="onClose()">{{ 'common.cancel' | translate }}</button>
        <button class="ct-button ct-button--primary" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button> 
    </mat-dialog-actions>
</form>