import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData, DialogClass, Element, ElementAction } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions } from 'src/app/util/constants';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { TxtGraficaService } from '../txtGrafica.service';
import { VariablesReport } from 'src/app/models/variablesReport.model';
import { VariablesAtributo } from 'src/app/models/variablesAtributo.model';

@Component({
  selector: 'app-form-edit-txt-graf',
  templateUrl: './form-edit-txt-graf.component.html',
  styleUrls: ['./form-edit-txt-graf.component.css']
})
export class FormEditTxtGrafComponent implements OnInit {

  registerForm!: FormGroup;
  listCommerce: string[] = [];
  readOnly: boolean = false;

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  private variablesAtributo?: VariablesAtributo;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { operatorId: number, readOnly: boolean },
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: TxtGraficaService,
    public dialogRef: MatDialogRef<FormEditTxtGrafComponent>,
    public dialog: MatDialog,
    private translate: TranslateService,
    public operator:RolHandlerService,
    private utils: UtilService
  ) { }

  async ngOnInit(): Promise<void> {
    // Obtener el rol del usuario actual
    this.readOnly = !!this.data.readOnly;
    this.registerForm = this.formBuilder.group({
      atributo: [null, Validators.required],
      traductor: [],
      ES: [],
      EN: []
    });

    if (this.data) this.cargar()
  }

  async cargar(): Promise<void> {
    let itemService = await this.service.getItem(this.data.operatorId);
    
    if(itemService){
     // Verificar si 'traductor' existe en el objeto

     try {
        // Convertir el string JSON de traductor en un objeto
        const variableAtributo = new VariablesAtributo();
        if(itemService.traductor){
        const traductorObj = JSON.parse(itemService.traductor);
        
          for (const key in traductorObj) {
            if (Object.prototype.hasOwnProperty.call(traductorObj, key)) {
              (variableAtributo as any)[key] = traductorObj[key];
            }
          }
        }
        variableAtributo.id = itemService.id;
        variableAtributo.idOperator = itemService.idOperator;
        
        // Determinar el valor de `atributo`: combinación de `padre/hijo` o solo `padre`
        variableAtributo.atributo = itemService.hijo 
          ? `${itemService.padre}/${itemService.hijo}` 
          : itemService.padre;

        this.variablesAtributo = variableAtributo;
  
      
      } catch (error) {
        console.error("Error parsing traductor JSON:", error);
      }

    }
    if (this.variablesAtributo) {
      this.registerForm.patchValue({
        atributo: this.variablesAtributo.atributo,
        ES: this.variablesAtributo.ES,
        EN: this.variablesAtributo.EN,
      });
    }
   }
  

  onFormSubmit(): void {
    if (this.registerForm.invalid) return;

    const data = this.applyFormats(this.registerForm);
    let traductorObj: any = {};

    if (data['ES']) {
      traductorObj['ES'] = data['ES'];
    }
    if (data['EN']) {
      traductorObj['EN'] = data['EN'];
    }
    let objNew: VariablesReport = new VariablesReport();
    if (data['atributo']) {
      const [padre, hijo] = data['atributo'].split('/');
    
      objNew.padre = padre;
      objNew.hijo = hijo || null;
    }
    
    objNew.traductor = JSON.stringify(traductorObj);
    if (this.variablesAtributo) objNew.id = this.variablesAtributo.id as number;
    if (this.variablesAtributo) objNew.idOperator = this.variablesAtributo.idOperator as number;

    const dialogData = new ConfirmDialogData();
    dialogData.element = Element.grafica; 
    dialogData.action = ElementAction.udpate_masculino;
    dialogData.class = DialogClass.info;
    dialogData.icon = 'save-01';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) this.saveOperator(objNew)
    });
  }

  async saveOperator(objNew: VariablesReport) {
    const val = await this.service.addUpdate(objNew);
    if (val!.status >= 0) {
      this.notifier.notify('success', this.translate.instant('success.operatorSaved'));
      this.utils.getRolsAndOperators();
      this.dialogRef.close(FormCreateActions.SAVED);
    }
  }

  formExit(): void {
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body: any = {};
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}
