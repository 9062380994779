<aside id="sidebar" class="sidebar personalMenu">
   <ul class="sidebar-nav" id="sidebar-nav">

      <ng-container *ngIf="rol.canByInput(rol.functIds.view_terminals)">
         <li class="nav-item" id="toc_li_organizations" data-level="1" (click)="marcarMenu('toc_cb_organizations');closeAllCollapse()">
            <input id="toc_cb_organizations" class="toc" type="checkbox">
            <label for="toc_cb_organizations">
               <a class="nav-link " routerLink="/monitoring">
                  <i class="bi bi-cast"></i>
                  <span >{{ 'sidebar.monitoring' | translate }}</span>
               </a>
            </label>
         </li>
      </ng-container>
      
      <li class="nav-item" *ngIf="rol.canByInputs([rol.functIds.view_functionality,rol.functIds.view_status])">
         <a class="nav-link collapsed" data-bs-target="#Stadistic-nav" data-bs-toggle="collapse" href="#">
            <i class="bi bi-graph-up"></i><span >{{ 'sidebar.statistic' | translate }}</span><i
               class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Stadistic-nav" class="nav-content collapse " >
           <!-- <li *ngIf="rol.canByInput(rol.functIds.view_functionality)" (click)="marcarMenu('toc_cb_stad_func')">
               <input id="toc_cb_stad_func" class="toc" type="checkbox">
               <label for="toc_cb_stad_func">
                  <a routerLink="/stadistic_funcional"> <i class="bi bi-bar-chart"></i> <span>
                     {{'sidebar.functionally' | translate }}</span> </a>
               </label>
            </li>
            <li *ngIf="rol.canByInput(rol.functIds.view_status)" (click)="marcarMenu('toc_cb_stad_state')">
               <input id="toc_cb_stad_state" class="toc" type="checkbox">
               <label for="toc_cb_stad_state">
                  <a routerLink="/stadistic_state"> <i class="bi bi-pie-chart"></i> <span>
                     {{ 'sidebar.state' | translate }}</span> </a>
               </label>
            </li>-->

            <li *ngIf="rol.canByInput(rol.functIds.view_status)" (click)="marcarMenu('toc_cb_stad_details')">
               <input id="toc_cb_stad_details" class="toc" type="checkbox">
               <label for="toc_cb_stad_details">
                  <a routerLink="/stadistic_details"> <i class="bi bi-pie-chart"></i> <span>
                     {{ 'sidebar.details' | translate }}</span> </a>
               </label>
            </li>
         </ul>
      </li>




      <li class="nav-item" *ngIf="rol.canByInputs([rol.functIds.view_alerts])">  
         <a class="nav-link collapsed" data-bs-target="#Support-nav" data-bs-toggle="collapse" href="#">
            <i class="bi bi-chat-square-dots"></i><span >{{ 'sidebar.support' | translate }}</span><i
               class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Support-nav" class="nav-content collapse " >
            <li *ngIf="rol.canByInput(rol.functIds.view_alerts)" (click)="marcarMenu('toc_cb_alert')">
               <input id="toc_cb_alert" class="toc" type="checkbox">
               <label for="toc_cb_alert">
                  <a routerLink="/alerts"> <i class="bi bi-bell"></i> <span >{{ 'sidebar.alerts' | translate
                        }}</span> </a>
               </label>
            </li>
            
            <!-- [FASE-2] TODO: Habilitar cuando este disponible la funcionalidad -->
            <!--li *ngIf="rol.canByInput(rol.functIds.view_incidences)" (click)="marcarMenu('toc_cb_incidences')">
               <input id="toc_cb_incidences" class="toc" type="checkbox">
               <label for="toc_cb_incidences">
                  <a routerLink="/incidenceMonitoring"> <i class="bi bi-exclamation-triangle"></i> <span >{{
                        'sidebar.incidences' | translate }}</span> </a>
               </label>
            </li!-->
         </ul>
      </li>

    

      <li class="nav-item" *ngIf="rol.canByInputs([rol.functIds.view_applications,rol.functIds.view_parameters,rol.functIds.view_profiles,rol.functIds.view_custom_alerts,rol.functIds.view_tags])">
         <a class="nav-link collapsed" id="config-nav-button" data-bs-target="#Config-nav" data-bs-toggle="collapse" href="#">
            <i class="bi bi-gear"></i><span >{{ 'sidebar.configuration' | translate }}</span><i
               class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Config-nav" class="nav-content collapse ">
            <li *ngIf="rol.canByInput(rol.functIds.view_applications)" (click)="marcarMenu('toc_cb_aplications')">
               <input id="toc_cb_aplications" class="toc" type="checkbox">
               <label for="toc_cb_aplications">
                  <a routerLink="/aplications"> <i class="bi bi-folder"></i> <span >{{ 'sidebar.apps' | translate
                        }}</span> </a>
               </label>
            </li>
            <li *ngIf="rol.canByInput(rol.functIds.view_parameters)" (click)="marcarMenu('toc_cb_param')">
               <input id="toc_cb_param" class="toc" type="checkbox">
               <label for="toc_cb_param">
                  <a routerLink="/parameters"> <i class="bi bi-sliders"></i> <span >{{ 'sidebar.parameters' |
                        translate }}</span> </a>
               </label>
            </li>

            <li *ngIf="rol.canByInput(rol.functIds.view_profiles)" (click)="marcarMenu('toc_cb_profile')">
               <input id="toc_cb_profile" class="toc" type="checkbox">
               <label for="toc_cb_profile">
                  <a routerLink="/profile"> <i class="bi bi-bar-chart-steps"></i> <span >{{ 'sidebar.profiles' |
                        translate }}</span> </a>
               </label>
            </li>
            
           
            <li *ngIf="rol.canByInput(rol.functIds.view_custom_alerts)" (click)="marcarMenu('toc_cb_customAlet')">
               <input id="toc_cb_customAlet" class="toc" type="checkbox">
               <label for="toc_cb_customAlet">
                  <a routerLink="/customAlert"> <i class="bi bi-exclamation-triangle"></i> <span >{{
                        'sidebar.customAlerts' | translate }}</span> </a>
               </label>
            </li>
            
            <li *ngIf="rol.canByInput(rol.functIds.view_tags)" (click)="marcarMenu('toc_cb_tags')">
               <input id="toc_cb_tags" class="toc" type="checkbox">
               <label for="toc_cb_tags">
                  <a routerLink="/tags"> <i class="bi bi-tags"></i> <span>{{ 'sidebar.tags' | translate }}</span> </a>
               </label>
            </li>
         </ul>
      </li>


      <li class="nav-item" *ngIf="rol.canByInputs([rol.functIds.send_file,rol.functIds.get_file,rol.functIds.commands,rol.functIds.pending_tasks,rol.functIds.history])">
         <a class="nav-link collapsed" data-bs-target="#Mantenimiento-nav" data-bs-toggle="collapse" href="#">
            <i class="bi bi-asterisk"></i><span >{{ 'sidebar.maintenance' | translate }}</span><i
               class="bi bi-chevron-down ms-auto"></i>
         </a>

         <ul id="Mantenimiento-nav" class="nav-content collapse " >
            <li *ngIf="rol.canByInput(rol.functIds.send_file)" (click)="marcarMenu('toc_cb_file')">
               <input id="toc_cb_file" class="toc" type="checkbox">
               <label for="toc_cb_file">
                  <a routerLink="/sendFile"> <i class="bi bi-file-earmark-arrow-up"></i><span >{{ 'sidebar.sendFile' | translate }}</span> </a>
               </label>
            </li>

            <li *ngIf="rol.canByInput(rol.functIds.get_file)" (click)="marcarMenu('toc_cb_getfile')">
               <input id="toc_cb_getfile" class="toc" type="checkbox">
               <label for="toc_cb_getfile">
                  <a routerLink="/getFile"> <i class="bi bi-file-earmark-arrow-down"></i> <span >{{'sidebar.getFile' | translate }}</span> </a>
               </label>
            </li>

            <li *ngIf="rol.canByInput(rol.functIds.commands)" (click)="marcarMenu('toc_cb_commands')">
               <input id="toc_cb_commands" class="toc" type="checkbox">
               <label for="toc_cb_commands">
                  <a routerLink="/commands"> <i class="bi bi-file-earmark-lock"></i> <span >{{ 'sidebar.commands' | translate }}</span> </a>
               </label>
            </li>

            <li  *ngIf="rol.canByInput(rol.functIds.pending_tasks)"(click)="marcarMenu('toc_cb_pendingt')">
               <input id="toc_cb_pendingt" class="toc" type="checkbox">
               <label for="toc_cb_pendingt">
                  <a routerLink="/pendingMonitoring"> <i class="bi bi-send"></i> <span >{{ 'sidebar.pendingTasks' | translate }}</span> </a>
               </label>
            </li>
            
            <li *ngIf="rol.canByInput(rol.functIds.history)" (click)="marcarMenu('toc_cb_history-user')">
               <input id="toc_cb_history-user" class="toc" type="checkbox">
               <label for="toc_cb_history-user">
                  <a routerLink="/history-user"> <i class="bi bi-list"></i> <span >{{ 'sidebar.historyUser' | translate }}</span> </a>
               </label>
            </li>
         </ul>
      </li>


      <li class="nav-item" *ngIf="rol.isAdmin() || rol.canByInputs([rol.functIds.view_users,rol.functIds.view_clients, rol.functIds.view_subclients,rol.functIds.view_rols])">
         <a class="nav-link collapsed" data-bs-target="#Admin-nav" data-bs-toggle="collapse" href="#">
            <i class="bi bi-shield-shaded"></i><span >{{ 'sidebar.admin' | translate }}</span><i
               class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Admin-nav" class="nav-content collapse " >

            <!-- Sección de Usuarios y Clientes visible para operadores y admins -->
            <li *ngIf="rol.canByInput(rol.functIds.view_users)" (click)="marcarMenu('toc_cb_users')">
               <input id="toc_cb_users" class="toc" type="checkbox">
               <label for="toc_cb_users">
                  <a routerLink="/users"> <i class="bi bi-person"></i> <span >{{ 'sidebar.users' | translate
                        }}</span> </a>
               </label>
            </li>

            <!-- Sección de Operadores solo visible para admin -->
            <li *ngIf="rol.isAdmin()" (click)="marcarMenu('toc_cb_operator')">
               <input id="toc_cb_operator" class="toc" type="checkbox">   
               <label for="toc_cb_operator">
                  <a routerLink="/operator"> <i class="bi bi-globe"></i> <span >{{ 'sidebar.operators' | translate
                        }}</span> </a>
               </label>
            </li>

            <!-- Sección de Operadores solo visible para admins y operadores -->
            <li *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_2) && rol.canByInput(rol.functIds.view_clients)" (click)="marcarMenu('toc_cb_client')">
               <input id="toc_cb_client" class="toc" type="checkbox">   
               <label for="toc_cb_client">
                  <a routerLink="/client"> <i class="bi bi-building"></i> <span>{{ 'sidebar.clients' | jerarquiaLevelTranslate: hierarchy.level.LEVEL_2  }}</span></a>
               </label>
            </li>


            <!-- Sección de Operadores solo visible para admins, operadores y clientes -->
            <li *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_3) && rol.canByInput(rol.functIds.view_subclients)" (click)="marcarMenu('toc_cb_subclient')">
               <input id="toc_cb_subclient" class="toc" type="checkbox">   
               <label for="toc_cb_subclient">
                  <a routerLink="/subClient"><i class="bi bi-house"></i> <span>{{ 'sidebar.subClient' | jerarquiaLevelTranslate: hierarchy.level.LEVEL_3 }}</span> </a>
               </label>
            </li>

            <!-- Sección de Roles solo visible para admins -->
            <li *ngIf="rol.canByInput(rol.functIds.view_rols)" (click)="marcarMenu('toc_cb_rol')">
               <input id="toc_cb_rol" class="toc" type="checkbox">
               <label for="toc_cb_rol">
                  <a routerLink="/rol"> <i class="bi bi-shield-lock"></i>
                     <span >{{ 'sidebar.rol' | translate}}</span> </a>
               </label>
            </li>



            <li *ngIf="isoperator">  
               <a class="nav-link collapsed" data-bs-target="#Customert-nav" data-bs-toggle="collapse" href="#">
                  <i class="bi bi-pencil"></i><span >{{ 'sidebar.customer' | translate }}</span><i
                     class="bi bi-chevron-down ms-auto"></i>
               </a>
               <ul id="Customert-nav" class="nav-content collapse ">
                  <li  (click)="marcarMenu('toc_cb_txtJerar')">
                     <input id="toc_cb_txtJerar" class="toc" type="checkbox">
                     <label for="toc_cb_txtJerar">
                        <a routerLink="/txtJerar"> <i class="bi bi-card-heading"></i> <span >{{ 'sidebar.textJerarquia' | translate
                              }}</span> </a>
                     </label>
                  </li>
      
                  <li  (click)="marcarMenu('toc_cb_txtGrafi')">
                     <input id="toc_cb_txtGrafi" class="toc" type="checkbox">
                     <label for="toc_cb_txtGrafi">
                        <a routerLink="/txtGrafi"> <i class="bi bi-clipboard-data"></i> <span >{{ 'sidebar.textGrafica' | translate
                              }}</span> </a>
                     </label>
                  </li>
                
               </ul>
            </li>
      


         </ul>
      </li>
   </ul>
</aside>
