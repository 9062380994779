import { Component, OnInit } from '@angular/core';
import packageJson from '../../../../package.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../welcome/login.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Users } from 'src/app/models/users.model';
import { LangTypes, LanguageService } from 'src/app/services/language.service';
import { UtilService } from 'src/app/services/util.service';
import { ComponentsStateService } from 'src/app/services/components-state.service';

@Component({
  selector: 'app-pages-login',
  templateUrl: './pages-login.component.html',
  styleUrls: ['./pages-login.component.css']
})
export class PagesLoginComponent implements OnInit {

  
  registerForm!: FormGroup;
  appVersion!:string;
  hidePassword:boolean = true;

  constructor(private formBuilder: FormBuilder,
    private authService:AuthService,
    private services: LoginService,
    private languageService:LanguageService,
    private componentsState:ComponentsStateService
  ) { 
    this.appVersion = packageJson.version;

  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      password: ["", Validators.required],     
      username: ["", Validators.required],
    });
  }

  async onFormSubmit(): Promise<void> {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.registerForm.value;

    let user=data['username']
    let pass=data['password']

    let tokenSession =  await this.services.login(user,pass)
    if(tokenSession?.token){
      this.authService.guardarToken(tokenSession?.token);
        
      const userSession: Users =  await this.services.getUserInfo(user)
      if(userSession){        
        this.authService.setCurrentUserValue(userSession);
        this.languageService.setLanguage(userSession.lang as LangTypes);
        this.componentsState.update();
      } 
    }
  }

  setPasswordVisibility(){
    this.hidePassword=!this.hidePassword;
  }

}
