import { Injectable } from '@angular/core';
import { ConsoleRequest } from 'src/app/models/console.request.model';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { Parameters } from 'src/app/models/parameters.model';
import { RestService } from 'src/app/services/merchant/Rest.service';

@Injectable({
  providedIn: 'root',
})
export class ParamService {

  constructor(private merchantRest: RestService) { }

  async find(filterAndPagBean: FilterAndPagBean) {
    let _body: ConsoleRequest = new ConsoleRequest;
    _body.filterAndPagBean = filterAndPagBean;

    let value =  await this.merchantRest.commonRestCallConsolaRest(_body,'getListFileIniSimple/',true,true )   
      .catch(err => {
        return null
      })
    return value;
  }

  async countTotal(filterAndPagBean: FilterAndPagBean) {
    let _body: ConsoleRequest = new ConsoleRequest;
    _body.filterAndPagBean = new FilterAndPagBean(filterAndPagBean?.orderSentido, filterAndPagBean?.order, filterAndPagBean?.filterGeneric, null, null, null);

    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'countListFileIni/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

  async addUpdate(params: Parameters) {
    let value = await this.merchantRest.commonRestCallConsolaRest(params, 'setFileIni/', true, true)
      .catch(err => {
        console.error('Error en la llamada al backend:', err);
        return null
      })

    return value;
  }
  
  async addUpdateWithFile(params: Parameters, file:File) {
    let value = await this.merchantRest.multipartRestCallConsolaRest({request: params, file:file }, 'setFileIniMultipart', true, true)
      .catch(err => {
        return null
      })

    return value;
  }
  
  async get(id: number) {
    let value = await this.merchantRest.commonRestCallConsolaRest({ id: id }, 'getFileIni/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

  async getFileIniMultipart(id: number) {
    let value = await this.merchantRest.commonRestCallFile({ id: id }, 'getFileIniMultipart/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

  async delete(id: number) {
    let ids: number[] = [];
    ids.push(id)

    let value = await this.merchantRest.commonRestCallConsolaRest({ ids }, 'deleteListFileIni/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

}