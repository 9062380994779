import { Inject, Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators'
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { ServerResponse } from './ServerResponse';
import { Feedbacks, FILE_SIZE_LIMIT_LABELS, HttpTimeouts, SERVER_ERRORS_LABELS, SERVER_STATUS_ERRORS_LABELS } from 'src/app/util/constants'
import { ApiService } from '../api.service'
import { Log } from 'src/app/util/log'
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService, toastPayload } from '../common.service';
import { IndividualConfig } from 'ngx-toastr';
import '@angular/localize/init';
import { AUTH_TMS_URL, AUTH_URL, CONSOLE_REST_URL } from 'src/app/auth/app.oauth-tokens';
import { HasFilesResponse } from 'src/app/models/Logfiles.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ConsoleRequest } from 'src/app/models/console.request.model';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class RestService {

    toast!: toastPayload;
    
    private notifier: NotifierService;
    public loadingText: string;
    
    constructor(
        public api: ApiService, notifier: NotifierService ,
        private spinnerService: NgxSpinnerService,
        private authService:AuthService,
        private cs : CommonService,
        @Inject(AUTH_URL) private authUrl: string,
        @Inject(CONSOLE_REST_URL) private consoleRestUrl: string,
        @Inject(AUTH_TMS_URL) private authTmsUrl: string,
        private translate:TranslateService
    ) { 
        this.loadingText = '';
        this.notifier = notifier;
    }

    ////////////////////////////////////////////////////////////////////
    //  Hasta aqui los servicios rest contemplados
    //  Ahora funciones de ayuda:
    ////////////////////////////////////////////////////////////////////

    async commonRestCallAuthTMS(_body: object, _path:string, showLoading: boolean, showToast: boolean){   
      
        let value = await this.commonRestCall({ service: this.authTmsUrl + _path, body: _body, showLoading, showToast })   
        .catch(err => {      
          return null
        })
        return value;   
    }

    async getCommonRestCallAuthTMS(_path:string, showLoading: boolean, showToast: boolean){   
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getAccessToken());
        
        const value = await this.getCommonRestCall({ service: this.authTmsUrl + _path, showLoading, showToast ,httpHeaders})   
        .catch(err => {      
          return null
        })

        return value;   
    }
  
    async multipartRestCallConsolaRest(_body: { request:object, file:File }, _path:string, showLoading: boolean, showToast: boolean){       
        
        let httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getAccessToken());
        
        console.log('header', JSON.stringify(httpHeaders), this.authService.getAccessToken())
        
        let consoleRequest:ConsoleRequest = new ConsoleRequest();
        
        let userSession = this.authService.currentUserValue();
        consoleRequest.userID=userSession?.id
        consoleRequest.data=_body.request

        let response = await this.formDataRestCall({ service: this.consoleRestUrl + _path, body: { request:consoleRequest, file:_body.file }, showLoading, showToast, httpHeaders })   
        .catch(err => { return null })
    
        return response;   
    }

    async commonRestCallConsolaRest(_body: object, _path:string, showLoading: boolean, showToast: boolean, contentType?:any){       
        
        let httpHeaders = new HttpHeaders(contentType).set('Authorization', 'Bearer ' + this.authService.getAccessToken());

        let consoleRequest:ConsoleRequest = new ConsoleRequest();
        
        let userSession = this.authService.currentUserValue();
        consoleRequest.userID=userSession?.id
        consoleRequest.data=_body

        let response = await this.commonRestCall({ service: this.consoleRestUrl + _path, body: consoleRequest, showLoading, showToast, httpHeaders })   
        .catch(err => { return null })
    
        return response;   
    }

    async commonRestCallFile(_body: object, _path:string, showLoading: boolean, showToast: boolean, contentType?:any){       
        
        let httpHeaders = new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}).set('Authorization', 'Bearer ' + this.authService.getAccessToken());

        let consoleRequest:ConsoleRequest = new ConsoleRequest();
        
        let userSession = this.authService.currentUserValue();
        consoleRequest.userID=userSession?.id
        consoleRequest.data=_body

        let response = await this.commonRestCallArrayBuffer({ service: this.consoleRestUrl + _path, body: consoleRequest, showLoading, showToast, httpHeaders })   
        .catch(err => { return null })
    
        return response;   
    }


    async commonRestCallAuth(_body: object, _path:string, showLoading: boolean, showToast: boolean){        
        let value = await this.commonRestCall({ service: this.authUrl + _path, body: _body, showLoading, showToast })   
        .catch(err => {      
          return null
        })
        return value;   
    }
  

  

    /**
     * Codigo tipico comun a llamadas rest que muestran un mensaje de espera en pantalla y si hay error lo indican en pantalla.
     * Observable del que al suscribirlo, la funcion next devuelve los server response validos (status >= 0).
     * En la funcion de error se devolveran ServerResponse con status < 0, por lo que hay que implementarla aunque sea vacia para que no de una excepcion
     * @param serviceName nombre del servicio rest
     * @param body multipart/form-data
     */

    private async formDataRestCall(opts: MerchantRestOptions ): Promise<ServerResponse> {

        if (opts.showLoading) this.spinnerService.show()

        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data'
            })
        }
    
        if(opts.httpHeaders){
            httpOptions.headers = opts.httpHeaders;            
        }

        const _body = new FormData();
        _body.append('input', JSON.stringify(opts.body.request));
        _body.append('file', opts.body.file, opts.body.request.fileName); //, { type: "application/octet-binary" }
           
        const timeoutDefault=HttpTimeouts.long;
        
        // Call the service
        const responsePromise = new Promise<ServerResponse>((resolve, reject) => {
            const apiCall = this.api.post(opts.service, _body, httpOptions) //.pipe(timeout(timeoutDefault))
            apiCall.subscribe((resp) => {               
                if (opts.showLoading) this.spinnerService.hide();
                let response = new ServerResponse(resp)
                if (response.status >= 0) {
                
                    resolve(response)
                
                } else {
                    // Despliego mensaje de error segundo el código recibido
                    const errorLabel = SERVER_ERRORS_LABELS[response.status.toString()] ?? SERVER_ERRORS_LABELS['default']; 
                    this.notifier.notify('error', `${this.translate.instant(errorLabel)}`);
                    reject(response)
                }

            }, (err:HttpErrorResponse) => {
                if (opts.showLoading) this.spinnerService.hide()
                this.notifier.notify('error', this.translate.instant(SERVER_STATUS_ERRORS_LABELS[err.status.toString()] ?? SERVER_STATUS_ERRORS_LABELS['default'], { fileSize: FILE_SIZE_LIMIT_LABELS.applications }));
                reject(err);
            });
        }) 
        
        return responsePromise;
    }

    // Si quisieramos retornar un new T() generico:  commonRestCall<T>(type: { new(): T ;}, serviceName: string, body: any) {
    //async commonRestCall(serviceName: string, body: any): Promise<ServerResponse> {
    private async commonRestCall(opts: MerchantRestOptions ): Promise<ServerResponse> {

        if (opts.showLoading) {
            let defaultmessage =  $localize `COMMON.server-please-wait`;
            this.loadingText = opts.textDisplay ?? defaultmessage;
            this.spinnerService.show();
        }


        let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json;charset=utf-8'
                })
            }
        
        if(opts.httpHeaders){
            httpOptions.headers = opts.httpHeaders;            
        }

       


        let timeoutDefault=HttpTimeouts.long
        if(opts.timeOut) {
            timeoutDefault=opts.timeOut
        }

        // Call the service
        let responsePromise = new Promise<ServerResponse>((resolve, reject) => {

          
            let apiCall = this.api.post(opts.service, opts.body, httpOptions).pipe(timeout(timeoutDefault))


            //##### Log.debug('commonRestCall request: ' + opts.service + " body:" + JSON.stringify(opts.body))

            apiCall.subscribe((resp) => {
               
                 //Log.debug('commonRestCall serverResp: ' + JSON.stringify(resp))

                if (opts.showLoading) {
                   this.spinnerService.hide();
                   this.loadingText = '';
                }
              
                
                let response = new ServerResponse(resp)

                if (response.status >= 0) {
                    resolve(response)
                } else {
                    // Despliego mensaje de error segundo el código recibido
                    const errorLabel = SERVER_ERRORS_LABELS[response.status.toString()] ?? SERVER_ERRORS_LABELS['default']; 
                    this.notifier.notify('error', `${this.translate.instant(errorLabel,{ errorCode: response.status })}`);
                    
                    // El servidor reporta algun error, asi lo indicamos a los subscriptores
                    reject(response)
                }

            }, (err:HttpErrorResponse) => {
                if (opts.showLoading) {
                    this.spinnerService.hide();
                    this.loadingText = '';
                }
                
                this.notifier.notify('error', this.translate.instant( SERVER_STATUS_ERRORS_LABELS[err.status.toString()] ?? SERVER_STATUS_ERRORS_LABELS['default']));
                reject(err)
            })
        }) // promise

        // devolvemos nuestra promise
        return responsePromise
    }


    private async getCommonRestCall(opts: MerchantRestOptions ): Promise<ServerResponse> {

        if (opts.showLoading) {
            let defaultmessage =  $localize `COMMON.server-please-wait`;
            this.loadingText = opts.textDisplay ?? defaultmessage;
            this.spinnerService.show();
        }


        let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json;charset=utf-8'
                })
            }
        
        if(opts.httpHeaders){
            httpOptions.headers = opts.httpHeaders;            
        }

       


        let timeoutDefault=HttpTimeouts.long
        if(opts.timeOut) {
            timeoutDefault=opts.timeOut
        }

        // Call the service
        let responsePromise = new Promise<ServerResponse>((resolve, reject) => {

            let apiCall = this.api.get(opts.service, opts.body, httpOptions).pipe(timeout(timeoutDefault))


            //##### Log.debug('commonRestCall request: ' + opts.service + " body:" + JSON.stringify(opts.body))

            apiCall.subscribe((resp) => {
               
                // Log.debug('commonRestCall serverResp: ' + JSON.stringify(resp))

                if (opts.showLoading) {
                   this.spinnerService.hide();
                   this.loadingText = '';
                }
              
                
                let sr = new ServerResponse(resp)

                if (sr) {
                    //##### Log.trace('commonRestCall isSuccessful')
                    if(sr.status){
                        if(sr.status==0){
                            resolve(sr)
                        }else{
                             // show problem to the user
                             if (opts.showToast) {
                                this.toast = {
                                    message: sr.status+ " "+ sr.errorDescription,
                                    title: 'MDM',
                                    type: 'Info',
                                    ic: {
                                        timeOut: Feedbacks.timeoutShort,
                                        closeButton: true,
                                        positionClass: Feedbacks.position
                                    } as IndividualConfig,
                                };
                                this.cs.showWarning(this.toast);
                            }
                        }
                    }else{
                        resolve(sr)
                    }

                } else {
                    //##### Log.trace('commonRestCall !isSuccessful')
                    let errorDescription =  "Error de sistema"
                    
                    // show problem to the user
                    if (opts.showToast) {
                        this.toast = {
                            message: errorDescription,
                            title: 'MDM',
                            type: 'Info',
                            ic: {
                                timeOut: Feedbacks.timeoutShort,
                                closeButton: true,
                                positionClass: Feedbacks.position
                            } as IndividualConfig,
                        };
                        this.cs.showWarning(this.toast);
                    }
                    // El servidor reporta algun error, asi lo indicamos a los subscriptores
                    reject(sr)
                }

            }, err => {
                if (opts.showLoading) {
                    this.spinnerService.hide();
                    this.loadingText = '';
                }

                let textFromError = "Excepción"             
                Log.error("commonRestCall error-> " + err.message)

                // Unable to get response
                if (opts.showToast) {
                    this.toast = {
                        message: textFromError,
                        title: 'MDM',
                        type: 'Info',
                        ic: {
                            timeOut: Feedbacks.timeoutShort,
                            closeButton: true,
                            positionClass: Feedbacks.position
                        } as IndividualConfig,
                    };
                    this.cs.showWarning(this.toast);
                }
                reject({})
            })
        }) // promise

        // devolvemos nuestra promise
        return responsePromise
    }

    private async commonRestCallArrayBuffer(opts: MerchantRestOptions ): Promise<ArrayBuffer> {

        if (opts.showLoading) {
            let defaultmessage =  $localize `COMMON.server-please-wait`;
            this.loadingText = opts.textDisplay ?? defaultmessage;
            this.spinnerService.show();
        }


        let httpOptions = {
            responseType:"arraybuffer",
            headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'})
        }
        
        if(opts.httpHeaders){
            httpOptions.headers = opts.httpHeaders;            
        }


        let timeoutDefault=HttpTimeouts.long
        if(opts.timeOut) {
            timeoutDefault=opts.timeOut
        }

        // Call the service
        const responsePromise = new Promise<ArrayBuffer>((resolve, reject) => {
            const apiCall = this.api.post(opts.service, opts.body, httpOptions).pipe(timeout(timeoutDefault))
            apiCall.subscribe((resp) => {
                
                if (opts.showLoading) {
                    this.spinnerService.hide();
                    this.loadingText = '';
                }
                

                if(resp instanceof ArrayBuffer){
                    resolve(resp)
                }

                reject(null)           

            }, err => {
                if (opts.showLoading) {
                    this.spinnerService.hide();
                    this.loadingText = '';
                }

                Log.error("commonRestCall error-> " + err.message)
                this.notifier.notify('error', this.translate.instant( SERVER_STATUS_ERRORS_LABELS[err.status.toString()] ?? SERVER_STATUS_ERRORS_LABELS['default']));
                reject(null)
            })
        }) // promise


        
        // devolvemos nuestra promise
        return responsePromise
    }


    private async commonRestCallResult(opts: MerchantRestOptions ): Promise<HasFilesResponse> {

        if (opts.showLoading) {
            let defaultmessage =  $localize `COMMON.server-please-wait`;
            this.loadingText = opts.textDisplay ?? defaultmessage;
            this.spinnerService.show();
        }


        let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json;charset=utf-8'
                })
            }
        
        if(opts.httpHeaders){
            httpOptions.headers = opts.httpHeaders;            
        }

       


        let timeoutDefault=HttpTimeouts.long
        if(opts.timeOut) {
            timeoutDefault=opts.timeOut
        }

        // Call the service
        let responsePromise = new Promise<HasFilesResponse>((resolve, reject) => {

            let apiCall = this.api.post(opts.service, opts.body, httpOptions).pipe(timeout(timeoutDefault),
                
            )



            //##### Log.debug('commonRestCall request: ' + opts.service + " body:" + JSON.stringify(opts.body))

            apiCall.subscribe((resp) => {
               
                 //Log.debug('commonRestCall serverResp: ' + JSON.stringify(resp))

                if (opts.showLoading) {
                   this.spinnerService.hide();
                   this.loadingText = '';
                }

              
                
                let sr = new HasFilesResponse(resp)

                if (sr) {
                    //##### Log.trace('commonRestCall isSuccessful')
                    resolve(sr)
                } else {
                    //##### Log.trace('commonRestCall !isSuccessful')
                    let errorDescription =  "Error de sistema"
                    
                    // show problem to the user
                    if (opts.showToast) {
                        this.toast = {
                            message: errorDescription,
                            title: 'Merchant Portal',
                            type: 'Info',
                            ic: {
                                timeOut: Feedbacks.timeoutShort,
                                closeButton: true,
                                positionClass: Feedbacks.position
                            } as IndividualConfig,
                        };
                        this.cs.showWarning(this.toast);
                    }
                    // El servidor reporta algun error, asi lo indicamos a los subscriptores
                    reject(sr)
                }

            }, err => {
                if (opts.showLoading) {
                    this.spinnerService.hide();
                    this.loadingText = '';
                }

                let textFromError = "Excepción"             
                Log.error("commonRestCall error-> " + err.message)

                // Unable to get response
                if (opts.showToast) {
                    this.toast = {
                        message: textFromError,
                        title: 'Merchant Portal',
                        type: 'Info',
                        ic: {
                            timeOut: Feedbacks.timeoutShort,
                            closeButton: true,
                            positionClass: Feedbacks.position
                        } as IndividualConfig,
                    };
                    this.cs.showWarning(this.toast);
                }
                reject({})
            })
        }) // promise

        // devolvemos nuestra promise
        return responsePromise
    }
}

export interface MerchantRestOptions {
    service: string
    body?: any
    // Show the default loading dialog
    showLoading?: boolean
    // Show de toast error when appropriated
    showToast?: boolean
	timeOut?: number
    textDisplay?: string
    httpHeaders?: HttpHeaders
}


