<main id="main" class="main">
    <div class="pagetitle">
        <span class="header-large">{{ 'textGrafica.title' | translate }}</span>
    </div>
      
    <div id="tableDescription" style="display:none;">{{ 'textGrafica.tableDescription' | translate }}</div>
    <div class="mat-elevation-z2 tablaPersonal">
      <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription"  
        matSort (matSortChange)="announceSortChange($event)"
        matTableExporter #exporter="matTableExporter" [hiddenColumns]="getNotExportColumns()">
  
        <!-- Column -->
        <ng-container matColumnDef="atributo">
          <th mat-header-cell *matHeaderCellDef><div>{{ 'textGrafica.atributo' | translate }}</div></th>
          <td mat-cell  *matCellDef="let element" > {{element.atributo}} </td>
        </ng-container>
        <!-- Column -->
        <ng-container matColumnDef="ES">
          <th mat-header-cell *matHeaderCellDef><div>{{ 'textGrafica.es' | translate }}</div></th>
          <td mat-cell *matCellDef="let element" > {{element.ES}} </td>
        </ng-container>
        <!-- Column -->
        <ng-container matColumnDef="EN">
          <th mat-header-cell *matHeaderCellDef><div>{{ 'textGrafica.en' | translate }}</div></th>
          <td mat-cell *matCellDef="let element" > {{element.EN}} </td>
        </ng-container>
 
        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
          <td mat-cell *matCellDef="let element" class="button" style="width: 60px;">
            <button matTooltip="{{ 'common.view' | translate }}" (click)="onEdit(element)" mat-icon-button class="iconoTabla">
              <mat-icon>chevron_right</mat-icon>
            </button>
          </td>
      </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      
      <!-- Empty state -->
      <ng-container *ngIf="!dataSource.data.length">
        <app-table-empty-state></app-table-empty-state>
      </ng-container>
  
      <button id="export" class="icoDownload" mat-icon-button
        (click)="exporter.exportTable('xlsx', { fileName: exportFileName })"><i class="bi bi-file-earmark-arrow-down"></i>
      </button>
  
      <mat-paginator [length]="pageLength" 
        [pageSize]="pageSize" 
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent()" 
        showFirstLastButtons 
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </main>