import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FormNewClientComponent } from './form-new-client/form-new-client.component';
import { ClientService } from './client.service';
import { Client, ClientCompleto, ClientListItem } from 'src/app/models/client.model';
import { environment } from 'src/environments/environment';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { UtilService } from 'src/app/services/util.service';
import { ConfirmDialogActions } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { NotifierService } from 'angular-notifier';
import { Operator } from 'src/app/models/operator.model';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { FormEditClientComponent } from './form-edit-client/form-edit-client.component';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';

enum Columns {
  name = 'name',
  idOperator = 'idOperator',
  cod = 'cod',
  personContact = 'personContact',
  view = 'view',
}

const FILTER_COLUMNS:{ field:string, type: FieldType }[] = [
  { field:'name', type:'string'},
  { field:'cod', type:'string' },
  { field:'personContact', type:'string' },
]

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class ClientComponent implements OnInit {
  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<ClientListItem>();
  exportFileName: string | undefined;

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  direccion: string | null = "ASC";
  pageSizeOptions = environment.paginacion;
  sortId:string | null = Columns.name;
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  // Filters config
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `clients.${column.field}`, column.type);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  operators:Operator[] = [];

  constructor(
    public client: ClientService,
    public dialog: MatDialog,
    public utilService: UtilService,
    public notifier: NotifierService,
    public translate:TranslateService,
    public rol:RolHandlerService,
    public hierarchy:HierarchyHandlerService
  ) {}

  async lanzarLLamada() {
    const paginatorState = this.getPaginatorState();
    const filterAndPagBean =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );
    
    let value = await this.client.find(filterAndPagBean);
    let pagCount = await this.client.countTotal(filterAndPagBean);
    if(value){
      this.dataSource = new MatTableDataSource<ClientListItem>(this.mapClientsWithOperators(value.data));
      if(pagCount) this.pageLength = pagCount.data?.total ?? 0;
    }
  }

  mapClientsWithOperators(clients:ClientCompleto[]):ClientListItem[]{
    return clients.map((client)=>{
      const operatorName = client.operator?.name;
      return { ...client, operatorName: operatorName } as ClientListItem;
    })
  }

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }
  
  async ngOnInit(): Promise<void> {
    this.exportFileName = this.translate.instant('export.fileClient');
    this.displayedColumns = this.hierarchy.filterColumns(Object.keys(Columns));
    this.hierarchy.hasData.subscribe(hasData=>{
      if(hasData){
        if(this.hierarchy.canByLevel(this.hierarchy.level.LEVEL_1)){
          this.filterColumns.push(this.hierarchy.hierarchyFilter('LEVEL_1', 'operator.id'))
        }
      }
    });
    // Cargar datos al iniciar
    await this.lanzarLLamada();
  }

  onNew(){
    const dialogRef = this.dialog.open(FormNewClientComponent, {
      width: '700px', panelClass: 'custom-modalbox-big',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === ConfirmDialogActions.CONFIRM) this.lanzarLLamada();
    });
  }

  onEdit(row:Client){
    const dialogRef = this.dialog.open(FormEditClientComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel' ,
      data: { clientId : row.id }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === ConfirmDialogActions.CONFIRM) this.lanzarLLamada();
    });
  }




  refrescar(){
    this.lanzarLLamada();
  }
  
  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }
}
