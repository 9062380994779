import { ANDROID_VERSIONS } from "../util/android-versions";
import { Profile } from "./profiles.model";

const ANDRIOD_NAME = 'ANDROID:';

export class VendingMachineAtento {
  id: number | undefined;
  idVM: number | undefined;
  name: string | undefined;
  idAtento: string | undefined;
  idEntornoAtento: number | undefined;
  idOperator: number | undefined;
  idClient: number | undefined;
  idSubClient: number | undefined;

  manufacID: string | undefined;
  modelNumber: string | undefined;
  swRev: string | undefined;
  serialNumber: string | undefined;

  commerce: string | undefined;
  
  timeZone: string | undefined;
  
  location: string | undefined;
  ip: string | undefined;
  imei: string | undefined;
  sim: string | undefined;
  macBT: string | undefined;
  puntoVenta: string | undefined;  
  
  nameOperator: string | undefined;
  nameClient: string | undefined;
  nameSubClient: string | undefined;

  valueVend: number | undefined;      
  lastUpdate?: Date;      
  lastConect?: Date;  
  dateCreate?: Date;
  dateInstall?: Date;   
  lastVend?: Date;     
  lastVendCard?: Date;  
  status!: Status; 
  hasPending:boolean = false;
  coverageLevel!: number; 
  coverageType!: string;
  connectionType!: string;
  battery!: number; 
  isCharging:boolean = false;

  versionKernel?:string;
	bootVersion?:string;
	osVersion?:string;
  appList?: string;
  listPerfil?:Profile[];
  latitude?:string;
  longitude?:string;
  latitudeEstacion?:string;
  longitudeEstacion?:string;

  //tiempo definido para marcar la terminal como inactiva.
  minInactivo?:number;
  isActive:boolean = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  get osVersionLabel(){
    if(!this.osVersion) return '-';
    
    const isAndriod = this.osVersion?.toUpperCase().includes(ANDRIOD_NAME);
    if(!isAndriod) return this.osVersion;
    
    const [so, version] = this.osVersion?.split(':');
    const andriodVersion =  ANDROID_VERSIONS[Number(version)] ?? '';
    return `${so} ${andriodVersion} (API level ${version})`;
  }
  

  get statusClass(): string {
    if (this.status === Status.INACTIVE || this.status === Status.OFF ) return 'status-icon red';
    if (this.status === Status.PENDING) return 'status-icon blue';
    //if (this.status === Status.OFF) return 'status-icon gray'; 
    return 'status-icon green'; // Predeterminado para "activo"
  }
  
  get statusLabel(): string {
    if (this.status === Status.INACTIVE || this.status === Status.OFF) return 'monitoring.status.inactive';
    if (this.status === Status.PENDING) return 'monitoring.status.pending';
    //if (this.status === Status.OFF) return 'monitoring.status.disconnected';
    return 'monitoring.status.active';
  }

  get batteryIcon():string{
    if(this.status === Status.INACTIVE || this.status === Status.OFF){
      return 'empty';
    }
    if (this.battery < 0) return 'off';
    if (this.isCharging) return 'charging-02';
    if (!this.battery) return 'empty';
    if (this.battery < 15 ) return 'low';
    if (this.battery < 60 ) return 'mid';
    return 'full';    
  }

  get batteryLabel():string{
    if (!this.battery || this.battery < 0 || this.status === Status.INACTIVE) return '';
    if (this.battery > 100) return '100%';
    return this.battery + '%'
  }

  get batteryStatus(){
    if (!this.battery || this.battery < 0 || this.status === Status.INACTIVE) return 'default';
    if (this.battery < 15 ) return 'low';
    if (this.battery < 60 ) return 'mid';
    return 'high';   
  }
  
}

export enum Status {
  OFF = 'OFF',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE'
}

