import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

export interface FilterableSelectOption{
  value:string;
  label:string;
}

@Component({
  selector: 'filterable-select',
  standalone: true,
  imports: [CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule
  ],
  templateUrl: './filterableSelect.component.html',
  styleUrls: ['./filterableSelect.component.css'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FilterableSelectComponent), multi: true }],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class FilterableSelectComponent implements ControlValueAccessor, OnInit{
  @Input() options: any[] = [];
  @Input() title:string = '';
  @Input() displayField:string = 'label';
  @Input() valueField:string = 'value';
  @Input() required:boolean = false;
  @Input() invalid?:boolean = false;
  
  @Output() change = new EventEmitter();
  
  filterControl = new FormControl('', Validators.maxLength(150));  
  selectControl = new FormControl('',Validators.maxLength(150));
  selectedOptionValue:( any | null ) = null;

  @ViewChild('SearchInput') searchInput?:ElementRef;
  
  constructor(){
    this.selectControl.valueChanges.subscribe((value)=>{ if(!this.disabled) this.onChange(value)})
  }

  ngOnInit(): void {
    if(this.required) this.selectControl.addValidators(Validators.required);
  }

  public updateValidity(){
    this.selectControl.markAsTouched();
    this.selectControl.markAsDirty();
    this.selectControl.updateValueAndValidity();
  }

  public get filteredOptions():any[]{
    const searchValue = this.filterControl.value;
    if(searchValue){
      return this._filter(searchValue)
    }

    return this.options;  
  }

  private _filter(filterInput: string  = ''): any[] {
    const compareValue = filterInput.toString().toLowerCase();
    return this.options.filter(option => option[this.displayField]?.toLowerCase().includes(compareValue));
  }

  onSelectorChange(){
    this.change.emit(this.selectControl.value);
  }

  onOpenChange(isOpen:boolean){
    this.filterControl.reset();
    if(isOpen) this.searchInput?.nativeElement.focus()
  }
  
  disabled:boolean = false;
  onChange = (value:(any|null))=>{};
  onTouched = ()=>{};

  writeValue(value: any): void {
    this.selectControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if(this.disabled){
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }
}
