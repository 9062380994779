import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appResizableColumn]'
})
export class ResizableColumnDirective {
  private startX!: number;
  private startWidth!: number;
  private resizer!: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    // Crear un pequeño "resizer" en el borde derecho de la columna
    this.resizer = this.renderer.createElement('span');
    this.renderer.addClass(this.resizer, 'column-resizer');
    this.renderer.appendChild(this.el.nativeElement, this.resizer);

    // Hacer que el cursor cambie al pasar por el borde
    this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (event.target !== this.resizer) return;

    this.startX = event.pageX;
    this.startWidth = this.el.nativeElement.offsetWidth;

    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  private onMouseMove = (event: MouseEvent) => {
    const newWidth = this.startWidth + (event.pageX - this.startX);
    this.renderer.setStyle(this.el.nativeElement, 'width', `${newWidth}px`);
  };

  private onMouseUp = () => {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  };
}
