import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsVendingMachine } from 'src/app/models/statisticVendingMachine.model';

@Component({
  // imports: [CommonModule,MatProgressBarModule, MatTooltipModule], // resolver e matooltip (no se ve bien cuando es standalone) con Florencia
  // standalone: true,

  selector: 'app-custom-progress-bar',
  templateUrl: './custom-progress-bar.component.html',
  styleUrls: ['./custom-progress-bar.component.css']
})
export class CustomProgressBarComponent {
  @Input() data: StatisticsVendingMachine = new StatisticsVendingMachine();
  @Input() perfil: boolean = false;

 


  segmentsStatus: any[] = [];
  segmentsProfile: any[] = [];
  
firstValidStatusIndex = 0;
firstValidProfileIndex = 0;
  constructor(private readonly translate: TranslateService){}
  
  ngOnInit() {
    this.constructorBar();

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.constructorBar();
    }
  }


  constructorBar(){
    if (this.data.total > 0) {
      this.segmentsStatus = [
        { value: this.data.active, percentage: (this.data.active / this.data.total) * 100, color: '#17B26A' , label: this.translate.instant('monitoring.status.active')},
        { value: this.data.pending, percentage: (this.data.pending / this.data.total) * 100, color: '#0BCCF9' , label: this.translate.instant('monitoring.status.pending')},
        // SUMAMOS inactivas y apagadas
        { value: this.data.inactive + this.data.off, percentage: ((this.data.inactive + this.data.off) / this.data.total) * 100, color: '#F04438' , label: this.translate.instant('monitoring.status.inactive')}, 

        // descomentar cuando haya apagadas
        // { value: this.data.inactive, percentage: (this.data.inactive / this.data.total) * 100, color: '#F04438' , label: this.translate.instant('monitoring.status.inactive')}, 
        // { value: this.data.off, percentage: (this.data.off / this.data.total) * 100, color: '#404F59' , label: this.translate.instant('monitoring.status.off')}
      ];
    
      this.segmentsProfile = [
        { value: this.data.perfil, percentage: (this.data.perfil / this.data.total) * 100, color: '#17B26A' , label: this.translate.instant('monitoring.profiles.profile')},
        { value: this.data.noPerfil, percentage: (this.data.noPerfil / this.data.total) * 100, color: '#F79009' , label: this.translate.instant('monitoring.profiles.noProfile')}
      ];
    }
    this.firstValidStatusIndex = this.getFirstValidIndex(this.segmentsStatus);
    this.firstValidProfileIndex = this.getFirstValidIndex(this.segmentsProfile);
}

getFirstValidIndex(segments: any[]): number {
  return segments.findIndex(segment => segment.value > 0);
}


}
